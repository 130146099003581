<template>
  <b-card
    :title="$t('pages.book.confirm.bookingDetails')"
    class="mb-4 "
  >
    <move-detail v-if="showMoveReservation" />

    <div v-if="showCustomer" class="mb-8">
      <div class="flex items-center justify-between mb-1">
        <h6 class="font-semibold text-xs uppercase text-secondary/60">
          {{ $t('general.customer') }}
        </h6>

        <nuxt-link :to="customerRoute" class="text-secondary/60">
          <b-icon icon="edit" base-class="h-4 w-4" />
        </nuxt-link>
      </div>

      <div>
        <div class="flex items-start justify-between text-sm text-secondary">
          <p>
            {{ selectedCustomer.displayName }} ({{ selectedCustomer.email }})
          </p>

          <div v-if="$auth.user && customerSegments" class="flex items-center justify-center space-x-2 mt-3">
            <b-tooltip
              v-for="segment in customerSegments"
              :key="segment.idSegment"
              :text="segment.segment.name"
            >
              <b-chip :colour="segment.segment.hexColour">
                {{ segment.segment.shortName || segment.segment.name }}
              </b-chip>
            </b-tooltip>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showSelectedSlot" class="mb-8">
      <div class="flex items-center justify-between mb-1">
        <h6 class="font-semibold text-xs uppercase text-secondary/60">
          {{ $t('general.services') }}
        </h6>

        <nuxt-link :to="servicesRoute" class="text-secondary/60">
          <b-icon icon="edit" base-class="h-4 w-4" />
        </nuxt-link>
      </div>
      <div>
        <div v-for="(slotService, serviceIndex) in selectedSlot.services" :key="`slotService-`+ serviceIndex" class="flex items-start justify-between text-sm text-secondary">
          <div>
            <p class="font-semibold">
              {{ slotService.service.title }}
            </p>
            <p>
              {{ $t('general.withEmployee', {employee: slotService.employee.firstName}) }}

              <span v-if="slotService.resources.length > 0" class="mt-1 opacity-80">
                (<span v-for="(resource, resourceIndex) in slotService.resources" :key="`service-${serviceIndex}-resource-${resourceIndex}`">
                  {{ resource.serviceResource.displayLabel }}
                  <template v-if="resourceIndex < slotService.resources.length - 1">,</template>
                </span>)
              </span>
            </p>
          </div>

          <div>
            <p v-if="$site.settings.bookShowServicePrices && slotService.price">
              {{ priceLabelForSlotService(slotService) }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showSelectedSlot" class="mb-6">
      <div class="flex items-center justify-between mb-1">
        <h6 class="font-semibold text-xs uppercase text-secondary/60">
          {{ $t('general.dateAndTime') }}
        </h6>

        <nuxt-link :to="availabilityRoute" class="text-secondary/60">
          <b-icon icon="edit" base-class="h-4 w-4" />
        </nuxt-link>
      </div>

      <div>
        <div class="flex items-start justify-between text-sm text-secondary">
          <p>
            {{ $format.date(selectedSlot.date) }} @ {{ selectedSlot.startTimeLabel }}
          </p>
        </div>
      </div>
    </div>

    <div v-if="showSelectedServices" class="mb-6">
      <div class="flex items-center justify-between mb-1">
        <h6 class="font-semibold text-xs uppercase text-secondary/60">
          {{ $t('general.services') }}
        </h6>

        <nuxt-link :to="servicesRoute" class="text-secondary/60">
          <b-icon icon="edit" class="h-4 w-4" />
        </nuxt-link>
      </div>

      <div>
        <div v-for="(serviceSelection, index) in selectedServices" :key="`slotService-`+ index" class="flex items-start justify-between text-sm text-secondary">
          <div>
            <p class="font-semibold">
              {{ serviceSelection.service.title }}
            </p>
            <p>
              <template v-if="serviceSelection.selectedEmployee">
                {{ $t('general.withEmployee',{employee: serviceSelection.selectedEmployee.firstName}) }}
              </template>
              <template v-else>
                {{ $t('general.withAny') }}
              </template>
            </p>
          </div>

          <div>
            <p v-if="$site.settings.bookShowServicePrices && priceLabelForServiceSelection(serviceSelection)">
              {{ priceLabelForServiceSelection(serviceSelection) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import MoveDetail from '@/components/booking/elements/MoveDetail.vue'

export const BookingStage = Object.freeze({
  SERVICES: 0,
  AVAILABILITY: 1,
  DETAIL: 2,
  CUSTOMER: 3,
  WAITLIST_CUSTOMER: 4,
  WAITLIST_AVAILABILITY: 5,
  CONFIRM: 6
})

export const createBookingRoute = (bookingUrlQuery, stage, params) => {
  const query = {}

  if (bookingUrlQuery.move) { query.move = bookingUrlQuery.move }
  if (bookingUrlQuery.rebook) { query.rebook = bookingUrlQuery.rebook }
  if (bookingUrlQuery.date) { query.date = bookingUrlQuery.date }
  if (bookingUrlQuery.voucher) { query.voucher = bookingUrlQuery.voucher }
  if (bookingUrlQuery.pass) { query.pass = bookingUrlQuery.pass }
  if (bookingUrlQuery.giftcard) { query.giftcard = bookingUrlQuery.giftcard }
  if (bookingUrlQuery.waitlist) { query.waitlist = bookingUrlQuery.waitlist }

  if (bookingUrlQuery.customer) { query.customer = bookingUrlQuery.customer }

  if (bookingUrlQuery.mode && bookingUrlQuery.mode !== 'standard') { query.mode = bookingUrlQuery.mode }

  if (params) {
    for (const key in params) {
      query[key] = params[key]
    }
  }

  for (const [key, value] of Object.entries(bookingUrlQuery)) {
    if (key.startsWith('f.')) {
      query[key] = value
    }
  }

  if (stage === BookingStage.SERVICES) {
    query.services = bookingUrlQuery.services

    if (!query.mode || query.mode === 'standard') {
      return {
        path: '/book-now',
        query
      }
    } else if (query.mode === 'timetable') {
      return {
        path: '/timetable',
        query: {
          date: query.date
        }
      }
    } else if (query.mode === 'lastminute') {
      return {
        path: '/last-minute'
      }
    }
  } else if (stage === BookingStage.AVAILABILITY) {
    query.services = bookingUrlQuery.services

    if (!query.mode || query.mode === 'standard') {
      return {
        path: '/book-now/availability',
        query
      }
    } else if (query.mode === 'timetable') {
      return {
        path: '/timetable',
        query: {
          date: query.date
        }
      }
    } else if (query.mode === 'lastminute') {
      return {
        path: '/last-minute'
      }
    }
  } else if (stage === BookingStage.DETAIL) {
    query.slot = bookingUrlQuery.slot

    return {
      path: '/book-now/detail',
      query
    }
  } else if (stage === BookingStage.CUSTOMER) {
    query.slot = bookingUrlQuery.slot

    return {
      path: '/book-now/customer',
      query
    }
  } else if (stage === BookingStage.WAITLIST_CUSTOMER) {
    query.services = bookingUrlQuery.services

    return {
      path: '/book-now/waitlist/customer',
      query
    }
  } else if (stage === BookingStage.WAITLIST_AVAILABILITY) {
    query.services = bookingUrlQuery.services
    query.customer = bookingUrlQuery.customer

    return {
      path: '/book-now/waitlist/availability',
      query
    }
  } else if (stage === BookingStage.CONFIRM) {
    query.slot = bookingUrlQuery.slot
    query.customer = bookingUrlQuery.customer

    return {
      path: '/book-now/confirm',
      query
    }
  }
}

export default {
  components: {
    MoveDetail
  },
  props: {
    stage: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      selectedServices: null,
      selectedSlot: null,
      selectedCustomer: null,
      moveReservation: null
    }
  },
  computed: {
    showMoveReservation () {
      return this.moveReservation
    },
    showCustomer () {
      return [BookingStage.CONFIRM, BookingStage.WAITLIST_AVAILABILITY, BookingStage.WAITLIST_CONFIRM].includes(this.stage) && this.selectedCustomer
    },
    showSelectedServices () {
      return [BookingStage.AVAILABILITY, BookingStage.WAITLIST_CUSTOMER, BookingStage.WAITLIST_AVAILABILITY, BookingStage.WAITLIST_CONFIRM].includes(this.stage) && this.selectedServices
    },
    showSelectedSlot () {
      return [BookingStage.DETAIL, BookingStage.CUSTOMER, BookingStage.CONFIRM].includes(this.stage) && this.selectedSlot
    },
    servicesRoute () {
      const bookingUrlQuery = this.$store.getters['booking/urlQuery']

      return createBookingRoute(bookingUrlQuery, BookingStage.SERVICES)
    },
    availabilityRoute () {
      const bookingUrlQuery = this.$store.getters['booking/urlQuery']

      return createBookingRoute(bookingUrlQuery, BookingStage.AVAILABILITY)
    },
    customerRoute () {
      const bookingUrlQuery = this.$store.getters['booking/urlQuery']

      if ([BookingStage.WAITLIST_AVAILABILITY, BookingStage.WAITLIST_CONFIRM].includes(this.stage)) {
        return createBookingRoute(bookingUrlQuery, BookingStage.WAITLIST_CUSTOMER, { override: true })
      } else {
        return createBookingRoute(bookingUrlQuery, BookingStage.CUSTOMER, { override: true })
      }
    },
    customerSegments () {
      const selectedCustomer = this.selectedCustomer
      const authenticatedCustomer = this.$auth.user

      if (selectedCustomer?.id === authenticatedCustomer?.id) {
        return authenticatedCustomer.customerSegments
      }
    }
  },
  created () {
    this.selectedServices = this.$store.getters['booking/selectedServices']
    this.selectedSlot = this.$store.getters['booking/selectedSlot']
    this.selectedCustomer = this.$store.getters['booking/selectedCustomer']
    this.moveReservation = this.$store.getters['booking/moveReservation']
  },
  methods: {
    priceLabelForSlotService (slotService) {
      return (slotService.service.priceFrom ? (this.$t('general.from') + ' ') : '') + slotService.priceLabel
    },
    priceLabelForServiceSelection (serviceSelection) {
      if (serviceSelection.selectedEmployee && serviceSelection.service && serviceSelection.service.employeeServices) {
        for (let i = 0; i < serviceSelection.service.employeeServices.length; ++i) {
          if (serviceSelection.service.employeeServices[i].employee.id === serviceSelection.selectedEmployee.id) {
            return (serviceSelection.service.priceFrom ? (this.$t('general.from') + ' ') : '') + serviceSelection.service.employeeServices[i].priceLabel
          }
        }
        return ''
      } else {
        return (serviceSelection.service.priceFrom ? (this.$t('general.from') + ' ') : '') + serviceSelection.service.priceLabel
      }
    }
  }
}
</script>

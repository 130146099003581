<template>
  <div class="bg-secondary flex flex-col">
    <div id="main" class="relative flex-grow flex-shrink">
      <slot />
    </div>

    <portal-target name="layout" multiple />

    <client-only>
      <Cookie v-if="$site.settings.showCookieConsent" layout="site" />
    </client-only>
  </div>
</template>

<script>
import Vue from 'vue'
import ResizeObserver from 'resize-observer-polyfill'

import Cookie from './elements/Cookie'

import Utils from '@/utils'

export default {
  components: {
    Cookie
  },
  head: {
    htmlAttrs: {
      class: 'layout-widget'
    }
  },
  data () {
    return {
      listeners: {
        resize: null
      }
    }
  },
  created () {
    this.$router.afterEach((to, from) => {
      if (Utils.browser.inIframe() && window.parent) {
        const PostRobot = require('post-robot')
        PostRobot.send(window.parent, 'bookapp.event.url-changed', to.fullPath)
        if (to && from && to.path !== from.path) {
          PostRobot.send(window.parent, 'bookapp.event.page-changed', to.fullPath)
        }
      }
    })
  },
  mounted () {
    const PostRobot = require('post-robot')

    if (this.$site.isEmbed && window.parent) {
      PostRobot.send(window.parent, 'bookapp.widget.event.ready')

      // if (!this.listeners.resize) {
        this.listeners.resize = PostRobot.on('bookapp.widget.resize', { window: window.parent }, function (event) {
          PostRobot.send(window.parent, 'bookapp.widget.event.resize', document.body.scrollHeight)
        })
      // }

      window.addEventListener('resize', function () {
        PostRobot.send(window.parent, 'bookapp.widget.event.resize', document.body.scrollHeight)
      })

      if (!this.$ro) {
        Vue.prototype.$ro = new ResizeObserver((entries, observer) => {
          const PostRobot = require('post-robot')
          PostRobot.send(window.parent, 'bookapp.widget.event.resize', document.body.scrollHeight)
        })
        this.$ro.observe(document.body)
      }

      const path = this.$route.path
      this.$store.dispatch('setHomeRoute', { path })
    } else {
      const route = this.$route
      const query = { ...route.query }
      delete query.layout
      this.$router.replace({ query })

      this.$store.dispatch('setLayout', 'site')
    }
  }
}
</script>

<style>
html.layout-widget #__layout {
  overflow-x: hidden;
}

html.layout-widget body {
  background-color: var(--bookapp-content-tint2-color) !important;
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

html.layout-widget #content-wrapper {
  margin-bottom: 40px;
}
</style>

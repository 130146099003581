<template>
  <div class="text-secondary mb-4">
    <div class="flex items-center justify-center">
      <h2 class="text-primary text-center text-2xl font-bold">
        {{ title }}
      </h2>

      <b-chip v-if="loading || alert" class="ml-3">
        <o-spinner v-if="loading" />
        <span v-else>{{ alert }}</span>
      </b-chip>
    </div>
    <p v-if="subTitle" class="text-center mt-2 text-secondary/80 text-sm max-w-3xl mx-auto">
      {{ subTitle }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'BPageTitle',
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: false,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    },
    alert: {
      type: [String, Number],
      default: null
    }
  }
}
</script>

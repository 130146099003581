<template>
  <div>
    <div
      class="sc-launcher"
      :class="{
        opened: isOpen,
        left: (placement == `left`),
        right: (placement == `right`),
        'top-left': (placement == `top-left`),
        'top-right': (placement == `top-right`)
      }"
      :style="{ backgroundColor: colors.action.background }"
      @click.prevent="isOpen ? close() : open()"
    >
      <div class="sc-icon-container">
        <b-icon icon="x" class="text-action sc-icon sc-icon-open" :class="{ active: isOpen }" />
      </div>
      <div class="sc-icon-container">
        <b-icon icon="calendar" class="text-action sc-icon sc-icon-closed" :class="{ active: !isOpen }" :style="{color: colors.action.text }" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    open: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    title: {
      type: String,
      default: () => ''
    },
    titleImageUrl: {
      type: String,
      default: () => ''
    },
    placeholder: {
      type: String,
      default: 'Write a reply'
    },
    placement: {
      type: String,
      default: 'right'
    },
    colors: {
      type: Object,
      required: false,
      default () {
        return {
          header: {
            bg: '#4e8cff',
            text: '#ffffff'
          },
          launcher: {
            bg: '#4e8cff'
          },
          messageList: {
            bg: '#ffffff'
          },
          sentMessage: {
            bg: '#4e8cff',
            text: '#ffffff'
          },
          receivedMessage: {
            bg: '#f4f7f9',
            text: '#ffffff'
          },
          userInput: {
            bg: '#f4f7f9',
            text: '#565867'
          }
        }
      }
    }
  },
  computed: {
    chatWindowTitle () {
      if (this.title !== '') {
        return this.title
      }
      return ''
    }
  }
}
</script>
<style scoped>
.sc-launcher {
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  right: 25px;
  border-radius: 50%;
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
  cursor: pointer;
}

.sc-launcher.left {
  left: 25px !important;
  right: auto !important;
  bottom: 25px;
}

.sc-launcher.right {
  right: 25px !important;
  left: auto !important;
  bottom: 25px;
}

.sc-launcher.top-left {
  left: 25px !important;
  right: auto !important;
  top: 25px;
}

.sc-launcher.top-right {
  right: 25px !important;
  left: auto !important;
  top: 25px;
}

.sc-launcher:before {
  content: '';
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: box-shadow 0.2s ease-in-out;
}

.sc-launcher.opened:before {
  box-shadow: 0px 0px 400px 250px rgba(148, 149, 150, 0.2);
}

.sc-launcher:hover {
  box-shadow: 0 0px 27px 1.5px rgba(0,0,0,0.2);
}

.sc-icon-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-icon {
  width: 24px;
  height: 24px;
  transition: all 100ms ease-in-out;
}

.sc-icon-open {
  transform: rotate(-90deg);
  opacity: 0;
}

.sc-icon-open.active {
  transform: rotate(0deg);
  opacity: 100;
}

.sc-icon-closed {
  transform: rotate(90deg);
  opacity: 0;
}

.sc-icon-closed.active {
  transform: rotate(0deg);
  opacity: 100;
}

.sc-new-messsages-count {
  position: absolute;
  top: -3px;
  left: 41px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background: #ff4646;
  color: white;
  text-align: center;
  margin: auto;
  font-size: 12px;
  font-weight: 500;
}
</style>

export const state = () => ({
  layout: null,
  botPlacement: null,
  widgetID: null,
  homeRoute: null,
  prefill: null,
  notice: null,
  menu: false,
  maintenance: null
})

export const mutations = {
  setLayout (state, layout) {
    state.layout = layout
  },
  setWidgetID (state, widgetID) {
    state.widgetID = widgetID
  },
  setBotPlacement (state, palcement) {
    state.botPlacement = palcement
  },
  setHomeRoute (state, homeRoute) {
    state.homeRoute = homeRoute
  },
  setPrefill (state, prefill) {
    state.prefill = prefill
  },
  setNotice (state, notice) {
    state.notice = notice
  },
  setMenu (state, menu) {
    state.menu = menu
  },
  setMaintenance(state, maintenance) {
    state.maintenance = maintenance
  }
}

export const actions = {
  async nuxtServerInit({ commit }, { req, app, route, redirect }) {
    let info = null

    try {
      info = await app.$api.location.info()
    } catch (e) {
      if (e.response && e.response.status < 500) {
        app.$auth.logout()

        try {
          info = await app.$api.location.info()
        } catch (e) {
          console.log(e)
        }
      }
    }
    // check if custom domain is active
    const isProduction = (process.env.NODE_ENV === 'production') // || true

    // console.log(this.$cookies.get('bookapp-layout'))
    const cookies = req.headers?.cookie 
      ? req.headers.cookie.split(';').reduce(
        (res, c) => {
          const [key, val] = c.trim().split('=').map(decodeURIComponent)
          try {
            return Object.assign(res, { [key]: JSON.parse(val) })
          } catch (e) {
            return Object.assign(res, { [key]: val })
          }
        },
        {}
      )
      : {}

    // check if site is being shown as widget, bot or bookapp
    const isEmbedded = ['widget', 'bot', 'facebook', 'app'].includes(route.query.layout) || cookies['bookapp-layout'] === 'app'
    
    if (isProduction && !isEmbedded && info?.location?.customDomainStatus === 3 && info?.location?.customDomain) {
      if (req.headers.host !== info.location.customDomain) {
        // redirect to custom domain
        redirect(301, 'https://' + info.location.customDomain + req.url)
      }
    }

    const domain = info?.domain
    
    if (isProduction && domain?.type === 'bookapp') {
      
      const current_slug = app.$site.slug
      const location_slug = info.domain?.slug

      if (current_slug !== location_slug) {
        redirect(301, 'https://' + location_slug + '.book.app' + req.url)
      }
    }

    if (info) {
      commit('location/setLocationInfo', {
        location: info.location,
        settings: info.settings,
        images: info.images,
        integrations: info.integrations,
        tabs: info.tabs,
        colours: info.colours,
        menu: info.menu,
        customPages: info.customPages,
        domain: info.domain
      })
    }

    if (route.query.layout || cookies['bookapp-layout']) {
      commit('setLayout', route.query.layout || cookies['bookapp-layout'])
    }
    if (route.query.placement) {
      commit('setBotPlacement', route.query.placement)
    }
    if (route.query.widgetID) {
      commit('setWidgetID', route.query.widgetID)
    }
    if (route.query.prefill) {
      commit('setPrefill', JSON.parse(route.query.prefill))
    }
    if (route.query.notice) {
      commit('setNotice', route.query.notice)
    }

    // Store Google Maps "Book Now" token and merchant ID in cookies
    if (route.query.rwg_token) {
      app.$cookies.set('_rwg_token', route.query.rwg_token, { path: '/', maxAge: 2592000 })
    }

    if (route.query.merchant_id) {
      app.$cookies.set('_merchant_id', route.query.merchant_id, { path: '/', maxAge: 2592000 })
    }
  },
  setLayout (context, layout) {
    context.commit('setLayout', layout)
  },
  setBotPlacement (context, placement) {
    context.commit('setBotPlacement', placement)
  },
  setWidgetID (context, widgetID) {
    context.commit('setWidgetID', widgetID)
  },
  setHomeRoute (context, homeRoute) {
    context.commit('setHomeRoute', homeRoute)
  }
}

export const getters = {
  layout (state) {
    return state.layout
  },
  botPlacement (state) {
    return state.botPlacement
  },
  widgetID (state) {
    return state.widgetID
  },
  homeRoute (state) {
    return state.homeRoute
  },
  prefill (state) {
    return state.prefill
  },
  menu (state) {
    return state.menu
  },
  maintenance (state) {
    return state.maintenance
  }
}

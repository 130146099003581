<template>
  <ol class="inline-flex items-center mb-2">
    <li v-for="(breadcrumb, index) in breadcrumbs" :key="index">
      <div class="flex items-center">
        <component
          :is="breadcrumb.to ? 'nuxt-link' : 'p'"
          :to="breadcrumb.to"
          :disabled="breadcrumb.disabled"
          class="ml-1 text-xs sm:text-sm"
          :class="[ breadcrumb.active ? 'text-primary font-semibold' : 'text-secondary/40' ]"
        >
          {{ breadcrumb.text }}
        </component>

        <span v-if="index < breadcrumbs.length - 1" class="px-3 text-secondary/30">/</span>
      </div>
    </li>
  </ol>
</template>

<script>
export default {
  name: 'BBreadcrumbs',
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  }
}
</script>

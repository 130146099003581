<template>
  <div v-html="parsed" class="b-html" />
</template>

<script>
import Utils from '@/utils'

export default {
  name: 'BHtml',
  props: {
    html :{
      type: String,
      default: ''
    }
  },
  computed: {
    parsed () {
      return Utils.text.formatHtml(this.html)
    }
  }
}
</script>

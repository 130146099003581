export default ({ axios, get, post, $get, $post }) => {
  return {
    login(location, email, password) {
      const request = {
        location: { id: location.id },
        email,
        password
      }

      return $post('location/auth/login', JSON.stringify(request))
    },
    loginEmailAuth(location, email, code, auth, expires) {
      const request = {
        location: { id: location.id },
        email,
        code,
        auth,
        expires
      }

      return $post('location/auth/loginEmailAuth', JSON.stringify(request))
    },
    loginCodeRequest(location, customer, email) {
      const request = {
        location: { id: location.id },
        customer: { id: customer.id },
        email
      }

      return $post('location/auth/loginCodeRequest', JSON.stringify(request))
    },
    loginCodeAuth(location, customer, email, token, code) {
      const request = {
        location: { id: location.id },
        customer: { id: customer.id },
        email,
        token,
        code
      }

      return $post('location/auth/loginCodeAuth', JSON.stringify(request))
    },
    connectLogin(email, password) {
      const request = {
        email,
        password
      }

      return $post('location/auth/connectLogin', JSON.stringify(request))
    },
    user() {
      return $get('location/auth/user')
    },
    connectedAccounts() {
      return $get('location/auth/connectedAccounts')
    },
    sendRecoveryEmail(location, email) {
      const request = {
        location: { id: location.id },
        email
      }

      return $post('location/auth/sendRecoveryEmail', JSON.stringify(request))
    },
    resetPassword(location, code, password) {
      const request = {
        location: { id: location.id },
        code,
        password
      }

      return $post('location/auth/resetPassword', JSON.stringify(request))
    },
    changePassword(currentPassword, newPassword) {
      const request = {
        currentPassword,
        newPassword
      }

      return $post('location/auth/changePassword', JSON.stringify(request))
    },
    resendLoginEmail(location, email, code, auth, expires, redirect) {
      const request = {
        location: { id: location.id },
        email,
        code,
        auth,
        expires,
        redirect
      }

      return $post('location/auth/resendLoginEmail', JSON.stringify(request))
    }
  }
}

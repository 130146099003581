<template>
  <div>
    <template v-if="images">
      <img :src="images.square" class="rounded-full" :class="size">
    </template>

    <template v-else>
      <div class="border bg-action text-action rounded-full flex items-center justify-center opacity-75" :class="size">
        <span>{{ initials }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BAvatar',
  props: {
    images: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'w-12 h-12'
    },
    single: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    initials () {
      if (this.single) {
        return this.title
          .charAt(0)
          .toUpperCase()
      }

      return this.title
        .match(/(^\S\S?|\s\S)?/g)
        .map(v => v.trim())
        .join('')
        .match(/(^\S|\S$)?/g)
        .join('')
        .toLocaleUpperCase()
    }
  }
}
</script>
<template>
  <div>
    <template v-if="businessHours">
      <a class="" @click="active = !active">
        <span class="w-full flex items-start text-primary cursor-pointer space-x-4 pb-2">
          <span v-if="showIcon" class="w-6 flex-shrink-0 flex justify-center">
            <BIcon icon="clock" />
          </span>

          <span
            class="flex-grow"
          >
            <span class="flex items-center justify-between flex-grow">
              <span>
                <span class="text-primary">
                  <div v-if="isOpen" class="text-left">
                    <div class="flex items-center space-x-2">
                      <span class="h-2 w-2 rounded-full bg-green-500 block"></span>
                      <p class="text-primary font-semibold">
                        {{ $t('components.businessHours.openNow') }}
                      </p>
                    </div>
                    <p class="text-sm opacity-80">
                      {{ $t('components.businessHours.closesAtTime', { time: $format.minuteTime(currentRange.end) }) }}<span v-if="nextRangeToday"> ⋅ {{ $t('components.businessHours.reopensAtTime', { time: $format.minuteTime(nextRangeToday.start) }) }}</span>
                    </p>
                  </div>

                  <div v-if="!isOpen" class="text-left">
                    <div class="flex items-center space-x-2">
                      <span class="h-2 w-2 rounded-full bg-red-500 block"></span>
                      <p class="text-primary font-semibold">
                        {{ $t('components.businessHours.closedNow') }}
                      </p>
                    </div>

                    <p v-if="todayHours && todayHours.label" class="text-sm opacity-80">
                      {{ todayHours.label }}
                    </p>

                    <template v-else>
                      <p v-if="nextRangeToday" class="text-sm opacity-80">
                        {{ $t('components.businessHours.opensAtTime', { time: $format.minuteTime(nextRangeToday.start) }) }}
                      </p>
                      <p v-else-if="nextDay && nextDay.ranges" class="text-sm opacity-80">
                        {{ $t('components.businessHours.opensAtTime', { time: `${ $format.day($format.parse(nextDay.date)) } ${ $format.minuteTime(nextDay.ranges[0].start) }` }) }}
                      </p>
                    </template>
                  </div>
                </span>
              </span>

              <span v-if="!expanded">
                <BIcon icon="chevronDown" class="transform transition-all" :class="active ? 'rotate-180' : 'rotate-0'" />
              </span>
            </span>

            <div v-if="active || expanded" class="flex flex-col pt-4 text-sm"> 
              <template v-for="day in businessHours">
                <div class="flex items-start justify-between hover:bg-primary/60 p-2 -mx-2">
                  <div class="w-1/2 mt-1">
                    <div class="font-medium leading-none">
                      <span>{{ $format.day($format.parse(day.date)) }}</span>
                      <span v-if="day.date === todayDate">({{ $t('datetime.labels.today') }})</span>
                    </div>
                    <div v-if="day.label" class="opacity-70 text-xs">
                      {{ day.label }}
                    </div>
                  </div>
                  <div class="opacity-80 text-right w-1/2">
                    <template v-if="day.ranges.length">
                      <div v-for="range in day.ranges">
                        {{$format.minuteTime(range.start)}} <span class="opacity-60">-</span> {{ $format.minuteTime(range.end) }}
                      </div>
                    </template>

                    <p v-else class="opacity-70">
                      {{ $t('components.businessHours.closed') }}
                    </p>
                  </div>
                </div>
              </template>
            </div>
          </span>
        </span>
      </a>

      
    </template>

    <template v-else>
      <div class="w-full flex items-start cursor-pointer space-x-4 pb-2">
        <div v-if="showIcon" class="w-6 flex-shrink-0 flex justify-center text-primary">
          <BIcon icon="clock" />
        </div>

        <div class="flex flex-col space-y-2 text-sm flex-grow pt-1 text-secondary">
          <template v-for="day in days">
            <div v-if="$site.tabs.contact.hours[day]" class="flex items-start justify-between">
              <div class="font-semibold">
                {{ $t(`datetime.days.${day}`) }}
              </div>
              <div class="opacity-80 text-right">
                {{ $site.tabs.contact.hours[day] }}
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    showIcon: {
      type: Boolean,
      default: true
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      active: false,
      currentTime: null,
      interval: null,
      days: [
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday',
        'sunday'
      ]
    }
  },
  computed: {
    businessHours () {
      const hours = this.$site.tabs.contact.businessHours

      if (hours?.length) {
        return hours.filter(day => day.date >= this.todayDate).slice(0, 7)
      }
       
      return null

    },
    todayDate () {
      const date = this.$moment().format('YYYYMMDD')

      return Number(date)
    },
    todayHours () {
      const businessHours = this.businessHours

      if (businessHours) {
        const todayDate = this.todayDate
        const day = businessHours.find(day => day.date === todayDate)

        return day
      }

      return null
    },
    currentRange () {
      const minuteTime = this.currentTime

      if (this.todayHours) {
        const ranges = this.todayHours.ranges

        for (const range of ranges) {
          const start = range.start
          const end = range.end

          if (minuteTime >= start && minuteTime <= end) {
            return range
          }
        }
      }
    },
    nextRangeToday () {
      const minuteTime = this.currentTime

      if (this.todayHours) {
        const ranges = this.todayHours.ranges

        for (const range of ranges) {
          const start = range.start

          if (minuteTime < start) {
            return range
          }
        }
      }
    },
    nextDay () {
      for (const day of this.businessHours) {
        if (day.date > this.todayDate && day.ranges.length) {
          return day
        }
      }
    },
    isOpen () {
      const minuteTime = this.currentTime
      
      if (this.todayHours) {
        const ranges = this.todayHours.ranges

        for (const range of ranges) {
          const start = range.start
          const end = range.end

          if (minuteTime >= start && minuteTime <= end) {
            return true
          }
        }
      }
    }
  },
  mounted () {
    this.setTime()
    
    this.interval = setInterval(() => {
      this.setTime()
    }, 30000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  methods: {
    setTime () {
      console.log('set time')

      const offset = this.$site.location.timezoneOffset || 0
      const date = new Date(new Date().toUTCString().slice(0, -4))
      const time = new Date(date.setSeconds(date.getSeconds() + offset))

      const hours = time.getHours()
      const minutes = time.getMinutes()

      this.currentTime = hours * 60 + minutes
    }
  }
}
</script>
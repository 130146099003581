<template>
  <div>
    <component
      :is="collapsible ? 'button' : 'div'"
      :class="headerClass"
      :aria-expanded="visible"
      @click="handleClick"
    >
      <div class="flex items-center">
        <div v-if="$slots.image" class="flex-shrink-0">
          <slot name="image" />
        </div>

        <div>
          <h4 v-if="title" class="text-primary font-semibold mb-1">{{ title }}</h4>
          <p v-if="subtitle || $slots.subtitle" class="text-secondary text-[0.85rem]">
            <slot name="subtitle">{{ subtitle }}</slot>
          </p>
        </div>
      </div>

      <slot name="control" />
    </component>

    <div v-if="visible" :class="contentClass">
      <div>
        <slot />
      </div>

      <div>
        <slot name="action" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BCard',
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    headerClass: {
      type: [String, Array],
      default: 'mb-3 flex items-center justify-between w-full text-left'
    },
    contentClass: {
      type: [String, Array],
      default: 'w-full flex flex-col justify-between p-4 rounded border bg-primary'
    },
    visible: {
      type: Boolean,
      default: true
    },
    collapsible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick ($event) {
      this.$emit('click', $event)
    }
  }
}
</script>
<template>
  <button class="flex items-center justify-between space-x-4 rtl:space-x-reverse py-3 px-3 bg-secondary text-secondary border border-primary rounded text-sm cursor-pointer hover:bg-primary transition-all" @click.stop="handleClick">
    <div>
      <slot>
        <p v-if="placeholder" class="text-secondary/80">
          {{ placeholder }}
        </p>
      </slot>
    </div>

    <div>
      <b-icon icon="chevronDown" />
    </div>
  </button>
</template>

<script>
export default {
  name: 'BDropdownField',
  props: {
    placeholder: {
      type: String,
      default: null
    }
  },
  methods: {
    handleClick ($event) {
      this.$emit('click', $event)
    }
  }
}
</script>
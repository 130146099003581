import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'

import {
  faTwitter,
  faFacebook,
  faPinterest,
  faInstagram,
  faTiktok,
  faWhatsapp,
  faYoutube,
  faLinkedin,
  faReddit,
  faThreads
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

config.autoAddCss = false

library.add(faTwitter)
library.add(faFacebook)
library.add(faPinterest)
library.add(faInstagram)
library.add(faTiktok)
library.add(faWhatsapp)
library.add(faYoutube)
library.add(faLinkedin)
library.add(faReddit)
library.add(faThreads)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('transition',{attrs:{"name":"fade"}},[(_vm.active)?_c('div',{staticClass:"fixed h-screen w-screen inset-0 z-[3000] text-secondary"},[_c('div',{staticClass:"fixed h-full w-full bg-primary bg-opacity-50 backdrop-blur-sm inset-0",on:{"click":function($event){_vm.active = false}}})]):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"slide"}},[(_vm.active)?_c('div',{staticClass:"fixed h-screen w-screen inset-0 z-[3000] text-secondary"},[_c('div',{staticClass:"fixed h-full w-ful inset-0",on:{"click":function($event){_vm.active = false}}}),_vm._v(" "),_c('div',{staticClass:"flex flex-col fixed right-0 top-0 h-full w-3/4 sm:max-w-[400px] bg-secondary shadow"},[_c('div',{staticClass:"flex items-center justify-between border-b p-4"},[_c('h3',{staticClass:"font-semibold text-center"},[_vm._v("\n            "+_vm._s(_vm.businessName)+"\n          ")]),_vm._v(" "),_c('button',{staticClass:"bg-transparent",on:{"click":function($event){_vm.active = false}}},[_c('b-icon',{attrs:{"icon":"x"}})],1)]),_vm._v(" "),_c('div',{staticClass:"overflow-y-auto scroll flex flex-col justify-between flex-grow h-10"},[_c('ul',{staticClass:"flex flex-col px-2 py-3"},[(_vm.menu)?_vm._l((_vm.menu),function(item,key){return _c('li',{key:`${key}-${item.type}`},[(item.type === 'group')?[_c('menu-group',{attrs:{"item":item}})]:[_c('menu-item',{attrs:{"item":item}})]],2)}):[_vm._l((_vm.tabs),function(tab){return _c('li',{key:tab},[(_vm.$site.tabs[tab])?_c('menu-item',{attrs:{"item":{
                    id: tab,
                    name: _vm.$site.tabs.getName(tab, tab),
                    information: _vm.$site.tabs.getInfo(tab)
                  }}}):_vm._e()],1)}),_vm._v(" "),(_vm.$site.customPages)?_vm._l((_vm.$site.customPages),function(page){return _c('li',{key:page.id},[_c('menu-item',{attrs:{"item":{
                      id: page.id,
                      name: page.menuTitle,
                      information: page.info,
                      type: 'custom',
                      icon: page.icon,
                      slug: page.slug
                    }}})],1)}):_vm._e()]],2),_vm._v(" "),(_vm.$site.tabs.user && (!_vm.isAppLayout || _vm.isLoggedIn))?_c('ul',{staticClass:"flex flex-col border-t px-4 py-3"},[_c('li',[_c('menu-item',{attrs:{"item":{ id: 'user', name: _vm.userTabName }}})],1)]):_vm._e()])])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
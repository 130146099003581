<template>
  <div>
    <div v-if="!show && type !== 'html'">
      <span :class="textClass">
        {{ truncate(text) }}
      </span>
      <a
        v-if="text.length >= length"
        :class="actionClass"
        @click.stop="toggle()"
      >{{ clamp }}</a>
    </div>

    <div v-else-if="!show && type === 'html'">
      <span
        :class="textClass"
        v-html="truncate(text)"
      />
      <a
        v-if="text.length >= length"
        :class="actionClass"
        @click.stop="toggle()"
      >{{ clamp }}</a>
    </div>

    <div v-if="show && type !== 'html'">
      <span>{{ text }}</span>
      <a
        v-if="text.length >= length"
        :class="actionClass"
        @click.stop="toggle()"
      >{{ less }}</a>
    </div>

    <div v-else-if="show && type === 'html'">
      <div
        v-if="text.length >= length"
        v-html="text"
      />
      <a
        v-if="text.length >= length"
        :class="actionClass"
        @click.stop="toggle()"
      >{{ less }}</a>
      <p v-else>
        {{ h2p(text) }}
      </p>
    </div>
  </div>
</template>

<script>
const h2p = require('html-truncate')

export default {
  name: 'BTruncate',
  props: {
    collapsedTextClass: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      required: true
    },
    clamp: {
      type: String,
      default () {
        return this.$t('general.readMore')
      }
    },
    length: {
      type: Number,
      default: 100
    },
    less: {
      type: String,
      default () {
        return this.$t('general.showLess')
      }
    },
    type: {
      type: String,
      default: 'text'
    },
    actionClass: {
      type: String,
      default: 'block mt-2'
    }
  },
  data () {
    return {
      show: false,
      counter: this.length
    }
  },
  computed: {
    textClass () {
      return (this.text.length > this.length && this.collapsedTextClass) ? this.collapsedTextClass : ''
    }
  },
  methods: {
    truncate (string) {
      const length = this.length
      const type = this.type

      if (string) {
        if (type === 'html') { return h2p(string, length) }

        const text = string.toString()

        if (text.length > length) {
          return string.substring(0, length) + '...'
        }

        return text
      }
      return ''
    },
    toggle () {
      const toggled = !this.show
      this.show = toggled
      this.$emit('toggle', toggled)
    },
    h2p (text) {
      return h2p(text)
    }
  }
}
</script>

<style lang="css" scoped>
  a {
    cursor: pointer;
  }
</style>

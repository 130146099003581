import Axios from 'axios'
import axiosRetry from 'axios-retry'

import createLocationApi from './location'
import createBookingApi from './booking'
import createCustomerApi from './customer'
import createPassApi from './pass'
import createGiftcardApi from './giftcard'
import createAuthApi from './auth'
import createMarketingApi from './marketing'

export class ApiResponse {
  constructor(response) {
    for (const key in response) {
      this[key] = response[key]
    }

    if (this.errors && !Array.isArray(this.errors)) {
      const errors = []
      for (const attribute in this.errors) {
        errors.push(...this.errors[attribute])
      }
      this.errors = errors
    }
  }
}

export const createApi = ({ baseUrl, location, headers: { host, cookie, client }, authorization, onError }) => {
  if (client === undefined) {
    client = 'bookapp'
  }

  const axios = Axios.create({
    baseURL: baseUrl
  })

  axios.interceptors.response.use(function (response) {
    return response
  }, function (error) {
    if (onError && onError(error)) { 
      return
    }

    return Promise.reject(error)
  })

  const axiosExtra = {}

  for (const method of ['request', 'delete', 'get', 'head', 'options', 'post', 'put', 'patch']) {
    axiosExtra['$' + method] = function () { return this[method].apply(this, arguments).then(res => res && res.data) }
  }

  const extendAxiosInstance = (axios) => {
    for (const key in axiosExtra) {
      axios[key] = axiosExtra[key].bind(axios)
    }
  }

  extendAxiosInstance(axios)

  axiosRetry(axios, {
    retries: 3,
    retryCondition: error => error.code !== 'ECONNABORTED' && !error.response
  })

  const connectClient = client

  const get = async (url, config) => {
    const cfg = config || {}
    cfg.headers = cfg.headers || {}
    if (!process.client) cfg.headers.host = host
    if (cookie) cfg.headers.cookie = cookie
    cfg.headers['x-connect-client'] = connectClient
    cfg.headers['accept'] = 'application/json'

    if (authorization) {
      authorization(cfg, location)
    }

    return new ApiResponse(await axios.get(url, cfg))
  }

  const post = async (url, body, config) => {
    const cfg = config || {}
    cfg.headers = cfg.headers || {}
    if (!process.client) cfg.headers.host = host
    if (cookie) cfg.headers.cookie = cookie
    cfg.headers['x-connect-client'] = connectClient
    cfg.headers['accept'] = 'application/json'

    if (authorization) {
      authorization(cfg, location)
    }

    return new ApiResponse(await axios.post(url, body, cfg))
  }

  const $get = async (url, config) => {
    const cfg = config || {}
    cfg.headers = cfg.headers || {}
    if (!process.client) cfg.headers.host = host
    if (cookie) cfg.headers.cookie = cookie
    cfg.headers['x-connect-client'] = connectClient
    cfg.headers['accept'] = 'application/json'

    if (authorization) {
      authorization(cfg, location)
    }

    return new ApiResponse(await axios.$get(url, cfg))
  }

  const $post = async (url, body, config) => {
    const cfg = config || {}
    cfg.headers = cfg.headers || {}
    if (!process.client) cfg.headers.host = host
    if (cookie) cfg.headers.cookie = cookie
    cfg.headers['x-connect-client'] = connectClient
    cfg.headers['accept'] = 'application/json'

    if (authorization) {
      authorization(cfg, location)
    }

    return new ApiResponse(await axios.$post(url, body, cfg))
  }

  return {
    auth: createAuthApi({ axios, get, post, $get, $post }),
    location: createLocationApi({ axios, get, post, $get, $post }),
    booking: createBookingApi({ axios, get, post, $get, $post }),
    customer: createCustomerApi({ axios, get, post, $get, $post }),
    pass: createPassApi({ axios, get, post, $get, $post }),
    giftcard: createGiftcardApi({ axios, get, post, $get, $post }),
    marketing: createMarketingApi({ axios, get, post, $get, $post }),
    ACTION_INFO: 'info',
    ACTION_VALIDATE: 'validate',
    ACTION_INTEGRATION: 'integration',
    ACTION_CONFIRM: 'confirm'
  }
}

export const Action = {
  Info: 'info',
  Validate: 'validate',
  Integration: 'integration',
  Confirm: 'confirm'
}

export const CustomFieldDataType = {
  Text: 0,
  LongText: 1,
  Multiple: 2,
  Bool: 3,
  Number: 4,
  Date: 5
}

export const createServerAuthorizationToken = (req, location) => {
  const payload = {
    location
  }

  const cookie = require('cookie')
  const jwt = require('jsonwebtoken')

  if (req.headers.cookie) {
    const cookies = cookie.parse(req.headers.cookie)

    if (cookies && cookies['auth._token.bookapp'] && cookies['auth._token.bookapp'] !== 'false') {
      payload.connect = cookies['auth._token.bookapp']
    }
    if (cookies && cookies['auth._token.location'] && cookies['auth._token.location'] !== 'false') {
      payload.customer = cookies['auth._token.location']
    }
  }

  return jwt.sign(payload, 'client', { noTimestamp: true })
}

export const createAuthorizationToken = (location, connectToken, customerToken) => {
  const payload = {
    location
  }

  const jwt = require('jsonwebtoken')

  if (connectToken) {
    payload.connect = connectToken
  }

  if (customerToken) {
    payload.customer = customerToken
  }

  return jwt.sign(payload, 'client', { noTimestamp: true })
}

export const createServerApi = (req, config) => {
  const connectClient = 'bookapp:site'

  const defaultPort = process.env.PORT || 3000
  const defaultHost = '127.0.0.1'
  const prefix = '/api'

  const baseUrl = (process.browser) ? prefix : `http://${defaultHost}:${defaultPort}${prefix}`

  const host = req.headers.host
  const cookie = (req.headers.cookie) ? req.headers.cookie : null

  const authorization = (config, location) => {
    const token = createServerAuthorizationToken(req, location)

    config.headers.Authorization = 'Bearer ' + token
  }

  return createApi({ baseUrl, location: locationFromRequest(host, config), headers: { host, cookie, client: connectClient }, authorization })
}

export const locationFromRequest = (host, config) => {
  let locationID = null
  let slug = null
  const domain = config.domain

  host = host.split(':')[0]

  if (domain && host.endsWith(domain)) {
    const domainParts = host.split('.')

    if (domainParts.length > 2) {
      slug = domainParts[0]
    }
  }

  if (!isNaN(slug)) {
    locationID = slug
  }

  if (config.forceLocation) {
    locationID = config.forceLocation
  }

  const location = {}

  if (locationID) {
    location.id = locationID
  } else if (slug) {
    location.slug = slug
    location.domain = domain
  } else {
    location.host = host
  }

  return location
}

export const slugFromLocation = (location) => {
  return (location.slug) ? location.slug : (location.id) ? location.id : (location.host) ? location.host.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase() : null
}

export default createApi

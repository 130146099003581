<template>
  <div>
    <template v-if="isBot">
      <bot-content :colors="colors">
        <bot-inner-header :colors="colors" :title="headerTitle" :sub-title="headerSubTitle" :back-route="headerBackRoute" :show-back="headerShowBack" />

        <slot />
      </bot-content>
    </template>

    <template v-else-if="isWidget || isFacebook">
      <div>
        <b-container v-if="$site.settings.notice.enabled && $store.state.notice" class="mb-8">
          <b-alert :icon="false">
            <span v-html="formatHtml($site.settings.notice.content)" />
          </b-alert>
        </b-container>

        <b-container v-if="breadcrumbs" class="mb-8">
          <b-breadcrumbs :breadcrumbs="breadcrumbs" />
        </b-container>

        <slot />
      </div>
    </template>

    <template v-else>
      <div class="py-6 sm:py-12">
        <b-container v-if="!isApp && $site.settings.notice.enabled && notice" class="mb-8">
          <b-alert :icon="false">
            <span v-html="formatHtml($site.settings.notice.content)" />
          </b-alert>
        </b-container>

        <b-container class="mb-8">
          <b-page-title :title="headerTitle" :sub-title="headerSubTitle" :loading="loading" :alert="alert" />
        </b-container>

        <b-container v-if="breadcrumbs" class="mb-8">
          <b-breadcrumbs :breadcrumbs="breadcrumbs" />
        </b-container>

        <div id="content">
          <slot />
        </div>
      </div>
    </template>
  </div>
</template>


<script>
import Utils from '@/utils'

import BotContent from '@/components/bot/Content.vue'
import BotInnerHeader from '@/components/bot/InnerHeader.vue'

export default {
  name: 'BLayout',
  components: {
    BotContent,
    BotInnerHeader
  },
  props: {
    colors: {
      type: Object,
      required: true
    },
    headerTitle: {
      type: String,
      default: ''
    },
    headerSubTitle: {
      type: String,
      default: ''
    },
    headerShowBack: {
      type: Boolean,
      default: true
    },
    headerBackRoute: {
      type: Object,
      default: null
    },
    breadcrumbs: {
      type: Array,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    alert: {
      type: [String, Number],
      default: null
    },
    notice: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    layout () {
      return this.$store.getters.layout
    },
    isBot () {
      return this.layout === 'bot'
    },
    isWidget () {
      return this.layout === 'widget'
    },
    isFacebook () {
      return this.layout === 'facebook'
    },
    isApp () {
      return this.layout === 'app'
    }
  },
  methods: {
    formatHtml (text) {
      return Utils.text.formatHtml(text)
    }
  }
}
</script>

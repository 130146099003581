import Tool, { ToolSetting } from './Tool'

export default class Eraser extends Tool {
  config = {
    size: 72,
    stamps: [
      '𝟭',
      '𝟮',
      '𝟯'
    ]
  }

  constructor (data, config) {
    super(data, config)

    this.icon = '<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="-0.75 -0.75 24 24"><defs></defs><title>design-tool-stamp</title><path d="M20.325 20.390625a1.40625 1.40625 0 0 1 -1.39875 1.40625H3.5437499999999997a1.40625 1.40625 0 0 1 -1.39875 -1.40625v-1.40625H20.325Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="M18.226875 13.359375h-2.7965625000000003c-1.8243749999999999 0 -2.3709375 -1.1925000000000001 -2.3709375 -2.5678125 0 -2.2931250000000003 2.28 -3.6825 2.28 -5.9615625a4.104375 4.104375 0 1 0 -8.20875 0c0 2.2790625 2.28 3.6684375 2.28 5.9615625 0 1.3753125000000002 -0.5465625 2.5678125 -2.3709375 2.5678125H3.5437499999999997c-3.375 0 -3.3815625000000002 5.625 -1.8853125000000002 5.625H20.8125c1.455 0 1.81125 -5.625 -2.585625 -5.625Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path></svg>'

    this.state = {
      drawing: false,
      snapshot: null
    }

    if (config) {
      this.config = {
        ...this.config,
        ...config
      }
    }

    this.settings = {
      size: this.config.size || 72,
      stamp: new ToolSetting({
        value: this.config.stamps[0],
        options: {
          stamps: this.config.stamps,
          images: this.config.images
        },
        template: `<div class="flex items-center space-x-2">
          <a
            v-for="option in options.stamps"
            :key="option"
            class="flex items-center justify-center h-9 w-9 rounded-full hover:outline outline-blue-500 outline-offset-1 cursor-pointer text-lg"
            :class="[
              value == option ? 'outline' : 'not-selected'
            ]"
            :style="{
              backgroundColor: value == option ? 'white' : '#ffffff38'
            }"
            @click="set(option)"
          >
            <span class="leading-none">
              <img :src="options.images[option]" class="h-6 w-6" />
            </span>
          </a>
        </div>`
      })
    }
  }

  get showSettings () {
    return this.config.stamps.length > 1
  }

  pointerDown ({ x, y } = {}) {
    const canvas = this.canvas
    const ctx = canvas.getContext('2d')

    this.state.drawing = true
    this.mouse.x = x
    this.mouse.y = y

    const size = 72

    const stamp = this.settings?.stamp?.value
    const url = this.settings?.stamp?.options?.images[stamp]

    const image = new Image()
    image.src = url

    ctx.globalCompositeOperation = 'source-over'

    const coords = {
      x: this.mouse.x - size / 2,
      y: this.mouse.y - size / 2
    }

    ctx.drawImage(image, coords.x, coords.y, size, size)

    this.state.snapshot = ctx.getImageData(0, 0, canvas.width, canvas.height)

    this.state.drawing = true

    this.context.layers = [
      ...this.context.layers,
      {
        type: 'stamp',
        stamp: this.settings.stamp.value,
        points: [
          [this.mouse.x, this.mouse.y]
        ]
      }
    ]
  }

  redraw (layer) {
    const canvas = this.canvas
    const ctx = canvas.getContext('2d')

    ctx.beginPath()

    const size = 72
    const stamp = layer.stamp

    for (const point of layer.points) {
      const [xCoord, yCoord] = point
      const canvas = this.canvas
      const ctx = canvas.getContext('2d')
      const url = this.settings?.stamp?.options?.images[stamp]

      const image = new Image()
      image.src = url

      ctx.globalCompositeOperation = 'source-over'

      const x = xCoord - size / 2
      const y = yCoord - size / 2

      ctx.drawImage(image, x, y, size, size)
    }
  }

  drawCursor () {
    this.cursor.lastX = this.lerp(this.cursor.lastX, this.mouse.x, 0.5)
    this.cursor.lastY = this.lerp(this.cursor.lastY, this.mouse.y, 0.5)

    const canvas = this.canvas_cursor
    const ctx = canvas.getContext('2d')

    const size = 72

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    const stamp = this.settings?.stamp?.value
    const url = this.settings?.stamp?.options?.images[stamp]
    const image = new Image()
    image.src = url

    const x = this.cursor.lastX - size / 2
    const y = this.cursor.lastY - size / 2

    ctx.drawImage(image, x, y, size, size)
  }
}

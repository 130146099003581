<template>
  <div>
    <div>
      <b-dropdown
        ref="dropdown"
        class="w-full"
        :arrow="false"
        placement="bottom-start"
        :auto-size="true"
        :disabled="disabled"
        :triggers="[]"
        :auto-hide="false"
        :allow-disable-positioning="false"
      >
        <b-text-input
          v-model="_value.address"
          leading="address"
          autocomplete="new-password"
          :loading="loading"
          flat
          @input="fetchAddresses"
          @blur="handleInputBlur"
          @focus="handleInputFocus"
          @keyup.up="handleInputKeyUp(-1)"
          @keyup.down="handleInputKeyUp(1)"
          @keyup.enter="handleInputEnter"
        >
          <template #leading>
            <span class="pr-3 text-secondary">
              <b-spinner v-if="loading" />
              <b-icon v-else icon="address" />
            </span>
          </template>
        </b-text-input>



        <template #content>
          <div class="text-sm flex flex-col">
            <div v-if="result && result.addresses && result.addresses.length">
              <ul
                v-for="(address, index) in result.addresses"
                :key="index"
              >
                <li
                  :class="[
                    'cursor-pointer select-none relative py-2 px-3  transition-colors border-b last:border-none',
                    focused === index ? 'bg-action/10 text-primary' : 'text-secondary'
                  ]"
                  role="option"
                  @click="setAddress(address)"
                  @mouseenter="focused = index"
                >
                  {{ address.formattedAddress }}
                </li>
              </ul>
            </div>

            <p v-else class="text-center px-4 py-2 text-secondary/80">
              No matching address found...
            </p>
          </div>
        </template>
      </b-dropdown>
    </div>

    <div id="address-autocomplete-tag" />
  </div>
</template>

<script>
import Radar from 'radar-sdk-js';
import debounce from 'lodash/debounce'

import {
  TextField
} from '@/components/fields'

const radar_test_key = 'prj_test_pk_7d0b8d3107d27df9fb890c5141faeb27f218a146'
const radar_live_key = 'prj_live_pk_352457a5eebd2c0766f55e0d63071d96c36a082e'

export default {
  name: 'BAddress',
  components: {
    TextField
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      result: null,
      sessionToken: null,
      focused: -1
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    isDev () {
      return process.env.NODE_ENV === 'development'
    }
  },
  mounted () {
    Radar.initialize(this.isDev ? radar_test_key : radar_live_key);
  },
  methods: {
    fetchAddresses: debounce(async function () {
      this.loading = true

      try {
        const result = await Radar.autocomplete({
          query: this._value.address,
          limit: 5
        })

        this.result = result
        this.loading = false

        this.$refs.dropdown.open()
      } catch (e) {
        console.log(e)
      }
    }, 500),
    setAddress (result) {
      if (result) {
        this._value.address = result.formattedAddress
        this._value.address2 = ''
        this._value.suburb = result.borough || ''
        this._value.city = result.city
        this._value.region = result.state
        this._value.postcode = result.postalCode
        this._value.country = result.country
      }
    },
    handlePredictionClick (prediction) {
      this.fetchPlace(prediction)
      this.$refs.dropdown?.close()
    },
    handleInputBlur () {
      setTimeout(() => {
        this.$refs.dropdown?.close()
      }, 200)
    },
    handleInputFocus () {
      if (this.result?.predictions) {
        this.$refs.dropdown?.open()
      }
    },
    handleInputKeyUp (val) {
      const addresses = this.result?.addresses

      if (addresses) {
        const focused = this.focused
        const next = focused + val

        if (next > -1 && next < addresses.length) {
          this.focused = next
        }
      }
    },
    handleInputEnter () {
      const addresses = this.result?.addresses

      if (addresses) {
        const focused = this.focused
        const address = addresses[focused]
        this.setAddress(address)
        this.$refs.dropdown.close()
      }
    }
  }
}
</script>

<template>
  <div class="bg-primary rounded border overflow-hidden">
    <button
      class="w-full flex items-center justify-between p-4 bg-primary hover:bg-secondary/30 cursor-pointer transition-all border-b border-secondary"
      :aria-expanded="visible"
      @click="handleToggle"
    >
      <div class="flex items-center space-x-3  rtl:space-x-reverse">
        <div>
          <h4 v-if="title" class="text-primary font-semibold">{{ title }}</h4>
          <p v-if="subtitle || $slots.subtitle" class="text-secondary text-xs mt-1">
            <slot name="subtitle">{{ subtitle }}</slot>
          </p>
        </div>

        <b-chip v-if="badge">
          {{ badge }}
        </b-chip>

        <b-spinner v-if="loading" />
      </div>


      <b-icon icon="collapse" class="ml-6 h-6 w-6 text-secondary/60 flex-shrink-0" />
    </button>

    <slot name="subheading" />

    <div v-if="visible" class="w-full flex flex-col justify-between p-4 rounded-b-md bg-primary">
      <div>
        <slot />
      </div>

      <div>
        <slot name="action" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BCollapse',
  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    default: {
      type: Boolean,
      default: false
    },
    badge: {
      type: [String, Number],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      visible: false
    }
  },
  mounted () {
    this.visible = this.default
  },
  methods: {
    handleToggle () {
      this.visible = !this.visible
    }
  }
}
</script>

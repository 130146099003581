<template>
  <div
    class="bg-cover bg-center relative bg-gray-200 p-4 overflow-hidden -mx-8 py-8 text-center text-white first:-mt-8 mb-12"
    :style="{ backgroundImage: 'url(' + image + ')' }"
  >
    <h1 class="relative z-10">{{ title }}</h1>

    <p class="relative z-10">{{ info }}</p>

    <div class="inset-0 bg-gray-900/50 h-full w-full absolute" />
  </div>
</template>

<script>
export default {
  name: 'HeroBlock',
  props: {
    title: {
      type: String,
      default: 'Title'
    },
    info: {
      type: String,
      default: 'Info'
    },
    image: {
      type: String,
      default: 'https://source.unsplash.com/random/1920x1080'
    }
  }
}
</script>

<template>
  <component :is="layout">
    <nuxt />
  </component>
</template>

<script>
import Layouts from '@/components/layouts'

export default {
  computed: {
    layout () {
      switch (this.$store.getters.layout) {
        case 'widget':
          return Layouts.Widget
        case 'facebook':
          return Layouts.Facebook
        case 'bot':
          return Layouts.Bot
        case 'app':
          return Layouts.App
        default:
          return Layouts.Default
      }
    }
  }
}
</script>

export default ({ axios, get, post, $get, $post }) => {
  return {
    find(email) {
      return $post('location/customer/find', JSON.stringify({ email }))
    },
    info() {
      return $get('location/info')
    },
    upcomingReservations(page) {
      return $post('location/customer/upcomingReservations', JSON.stringify({ page }))
    },
    pastReservations(page) {
      return $post('location/customer/pastReservations', JSON.stringify({ page }))
    },
    reservation(id, date, customer, signature) {
      const request = {
        id,
        date
      }
      if (customer) request.customer = customer.id
      if (signature) request.signature = signature

      return $post('location/customer/reservation', JSON.stringify(request))
    },
    cancelReservation(id, date, type, note) {
      return $post('location/customer/reservationCancel', JSON.stringify({ id, date, type: type.id, note }))
    },
    confirmAttendingReservation(id, date) {
      return $post('location/customer/reservationConfirmAttending', JSON.stringify({ id, date }))
    },
    reviewReservation(id, date, comment, rating, displayName) {
      return $post('location/customer/reservationReview', JSON.stringify({ id, date, comment, rating, displayName }))
    },
    orders(page) {
      return $post('location/customer/orders', JSON.stringify({ page }))
    },
    order(id) {
      return $post('location/customer/order', JSON.stringify({ id }))
    },
    orderPayment(id, action, amount, paymentData, paymentParams) {
      const payment = {}
      if (paymentData) {
        payment.data = paymentData
      }
      if (paymentParams) {
        payment.params = paymentParams
      }

      return $post('location/customer/orderPayment', JSON.stringify({ id, action, amount, payment }))
    },
    giftCards(page) {
      return $post('location/customer/giftCards', JSON.stringify({ page }))
    },
    passes(page) {
      return $post('location/customer/passes', JSON.stringify({ page }))
    },
    waitlists(page) {
      return $post('location/customer/waitlists', JSON.stringify({ page }))
    },
    waitlist(id, signature) {
      const request = {
        id
      }

      if (signature) request.signature = signature

      return $post('location/customer/waitlist', JSON.stringify(request))
    },
    waitlistAvailability(id) {
      return new Promise((resolve, reject) => {
        $post('location/customer/waitlistAvailability', JSON.stringify({ id })).then((object) => {
          const services = {}
          const employees = {}

          for (let i = 0; i < object.services.length; i++) {
            const service = object.services[i]
            services[service.id] = service
          }

          for (let i = 0; i < object.employees.length; i++) {
            const employee = object.employees[i]
            employees[employee.id] = employee
          }

          for (let i = 0; i < object.dates.length; i++) {
            const date = object.dates[i]

            for (let j = 0; j < date.slots.length; j++) {
              const slot = date.slots[j]

              for (let k = 0; k < slot.services.length; k++) {
                const slotService = slot.services[k]

                slotService.service = services[slotService.service.id]
                slotService.employee = employees[slotService.employee.id]
              }
            }
          }

          resolve(object)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    waitlistCancel(id) {
      return $post('location/customer/waitlistCancel', JSON.stringify({ id }))
    },
    waitlistUpdate(id, dates) {
      return $post('location/customer/waitlistUpdate', JSON.stringify({ id, dates }))
    },
    formRequests(page) {
      return $post('location/customer/formRequests', JSON.stringify({ page }))
    },
    form(id) {
      return $post('location/customer/form', JSON.stringify({ id }))
    },
    submitForm(id, values, consentAccepted, sendEmail) {
      return $post('location/customer/submitForm', JSON.stringify({ id, values, consentAccepted, sendEmail }))
    },
    updateDetails(attributes, customFields) {
      return $post('location/customer/updateDetails', JSON.stringify({ attributes, customFields }))
    },
    photos(page, limit) {
      return $post('location/customer/photos', JSON.stringify({ page, limit }))
    }
  }
}

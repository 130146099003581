<template>
  <div class="h-full w-screen bg-secondary flex flex-col">
    <a href="#content" class="fixed -left-full z-[5000] p-2 m-6 rounded-md border-white bg-black text-white focus:left-1/2 focus:-translate-x-1/2">Skip to main content</a>

    <div id="main" class="relative overflow-y-auto overflow-x-hidden flex-grow flex-shrink">
      <!--<Navigation />-->

      <slot />

      <Footer/>
    </div>

    <portal-target name="layout" multiple>

    </portal-target>

    <Mobile />

    <Menu />
  </div>
</template>

<script>
import Navigation from './elements/Navigation'
import Mobile from './elements/Mobile'
import Menu from './elements/Menu'
import Footer from './elements/Footer'


export default {
  components: {
    Navigation,
    Mobile,
    Menu,
    Footer
  },
  head () {
    return {
      htmlAttrs: {
        class: 'layout-mobile'
      }
    }
  },
  watch: {
    $route (to, from) {
      const el = document.getElementById('main')
      el?.scrollTo({ top: 0 })
    }
  },
  mounted () {
    this.handleFlutterEvent('routeTo', (event) => {
      const route = event?.detail?.route

      if (route) {
        this.$router.push(route)
      }
    })

    this.handleFlutterEvent('toggleMenu', () => {
      const active = this.$store.getters.menu
      this.$store.commit('setMenu', !active)
    })

    this.handleFlutterEvent('refresh', () => {
      window?.location?.reload()
    })
  },
  methods: {
    emitFlutterEvent (name, args = {}) {
      return window?.flutter_inappwebview?.callHandler(name, event)
    },
    handleFlutterEvent (name, callback) {
      return window?.addEventListener(`bookapp-${name}`, callback, false)
    }
  }
}
</script>

<style>
html.layout-mobile {
  height: -webkit-fill-available;
  height: 100%
}

.layout-mobile body {
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
  height: 100%
}

.layout-mobile #__nuxt {
  height: 100%
}

.layout-mobile #__layout {
  height: 100%
}

@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}
</style>

<template>
  <v-dropdown :triggers="['click']" :positioning-disabled="true">
    <a class="flex flex-col items-center text-secondary opacity-75">
      <icon :name="item.icon || icons[item.id]" class="mb-1" />
      <span class="text-center text-[0.7rem] line-clamp-1 text-clip px-px">{{ item.name || item.icon }}</span>
    </a>

    <template #popper>
      <div class="border-b">
        <p class="text-secondary opacity-80 text-xs line-clamp-1 text-clip p-4">{{ item.name || item.icon }}</p>
      </div>
      <ul class="p-2 space-y-2 text-sm">
        <li v-for="(child, key) in item.children" :key="`${key}-${child.type}`">
          <menu-item :item="child" icon info />
        </li>
      </ul>
    </template>
  </v-dropdown>
</template>

<script>
import MenuItem from './MenuItem'
import Icon from './Icon'

export default {
  components: {
    MenuItem,
    Icon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

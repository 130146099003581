<template>
  <div id="bot-content-container" class="sc-content">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    colors: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.sc-content {
  height: 100%;
  overflow-y: auto;
  background-size: 100%;
  /*padding: 40px 0px;*/
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position:relative;
  padding-bottom: 25px;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"sc-launcher",class:{
      opened: _vm.isOpen,
      left: (_vm.placement == `left`),
      right: (_vm.placement == `right`),
      'top-left': (_vm.placement == `top-left`),
      'top-right': (_vm.placement == `top-right`)
    },style:({ backgroundColor: _vm.colors.action.background }),on:{"click":function($event){$event.preventDefault();_vm.isOpen ? _vm.close() : _vm.open()}}},[_c('div',{staticClass:"sc-icon-container"},[_c('b-icon',{staticClass:"text-action sc-icon sc-icon-open",class:{ active: _vm.isOpen },attrs:{"icon":"x"}})],1),_vm._v(" "),_c('div',{staticClass:"sc-icon-container"},[_c('b-icon',{staticClass:"text-action sc-icon sc-icon-closed",class:{ active: !_vm.isOpen },style:({color: _vm.colors.action.text }),attrs:{"icon":"calendar"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }
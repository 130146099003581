import createApi, { locationFromRequest, createAuthorizationToken } from '~/api/'

export default (context, inject) => {
  // inject the repository in the context (ctx.app.$repository)
  // And in the Vue instances (this.$repository in your components)

  const req = context.req
  const layout = context.app.$site.layout
  const connectClient = 'bookapp:' + layout

  const defaultPort = process.env.PORT || 3000
  const defaultHost = '127.0.0.1'
  const prefix = '/api'

  const baseUrl = (process.browser) ? prefix : `http://${defaultHost}:${defaultPort}${prefix}`

  const host = (process.client) ? null : context.app.$site.host
  const cookie = (req && req.headers.cookie) ? req.headers.cookie : null

  const authorization = (config, location) => {
    const connect = context.app.$auth.getToken('bookapp')
    const customer = context.app.$auth.getToken('location')

    const token = createAuthorizationToken(location, connect, customer)

    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
  }
  const location = locationFromRequest(context.app.$site.host, context.app.$config)

  const onError = (error) => {
    if (error.response && error.response.status == 503 && error.response.data && error.response.data.reason === 'maintenance') {
      context.store.commit('setMaintenance', { title: error.response.data.title, content: error.response.data.content })

      context.redirect('/maintenance')

      return true
    }

    return false;
  }

  const apiWithAxios = createApi({ baseUrl, location, headers: { host, cookie, client: connectClient }, authorization, onError })
  inject('api', apiWithAxios)
}

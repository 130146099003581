export default ({ axios, get, post, $get, $post }) => {
  return {
    giftcard(id, signature) {
      return $post('location/giftcard/giftcard', JSON.stringify({ id, signature }))
    },
    claim(id, signature, customer, claim) {
      const requestCustomer = {}

      if (customer.id) {
        requestCustomer.id = customer.id
      } else {
        requestCustomer.attributes = customer.fields
        requestCustomer.customFields = customer.customFields
        requestCustomer.acceptConsent = customer.privacyConsentAccepted
        requestCustomer.acceptMarketingConsent = customer.marketingConsentAccepted
      }

      return $post('location/giftcard/claim', JSON.stringify({ id, signature, customer: requestCustomer, claim }))
    },
    purchase(action, customer, card, to, from, message, deliveryDate, termsAccepted, paymentData, paymentParams) {
      const requestCustomer = {}

      if (customer.id) {
        requestCustomer.id = customer.id
      } else {
        requestCustomer.attributes = customer.fields
        requestCustomer.customFields = customer.customFields
        requestCustomer.acceptConsent = customer.privacyConsentAccepted
        requestCustomer.acceptMarketingConsent = customer.marketingConsentAccepted
      }

      const request = {
        action,
        customer: requestCustomer,
        card: { amount: card.amount, image: card.image },
        to: { name: to.name, email: to.email },
        from: { name: from.name },
        message,
        deliveryDate: (deliveryDate) ? Math.floor(deliveryDate.getTime() / 1000) : null,
        termsAccepted,
        payment: {}
      }

      if (paymentData) {
        request.payment.data = paymentData
      }

      if (paymentParams) {
        request.payment.params = paymentParams
      }

      return $post('location/giftcard/purchase', JSON.stringify(request))
    }
  }
}

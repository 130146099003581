import moment from 'moment'

export default ({ axios, get, post, $get, $post, $moment }) => {
  return {
    services () {
      return $get('location/booking/services')
    },
    timetableMonth (month, voucher, pass) {
      const monthStamp = parseInt((month.getTime() / 1000) - (month.getTimezoneOffset() * 60))

      return new Promise((resolve, reject) => {
        const data = {
          month: monthStamp,
          voucher: (voucher) ? voucher.code : null,
          pass: (pass) ? pass.id : null
        }

        $post('location/booking/timetableMonth', JSON.stringify(data)).then((object) => {
          if (object.dates) {
            for (let i = 0; i < object.dates.length; i++) {
              const dateInfo = object.dates[i]
              dateInfo.date = new Date(dateInfo.date * 1000)
            }
          }
          resolve(object)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    timetableWeek (date, voucher, pass) {
      const dateStamp = parseInt((date.getTime() / 1000) - (date.getTimezoneOffset() * 60))

      return new Promise((resolve, reject) => {
        const data = {
          date: dateStamp,
          voucher: (voucher) ? voucher.code : null,
          pass: (pass) ? pass.id : null
        }
        $post('location/booking/timetableWeek', JSON.stringify(data)).then((object) => {
          const services = {}
          const employees = {}

          for (let i = 0; i < object.services.length; i++) {
            const service = object.services[i]
            services[service.id] = service
          }

          for (let i = 0; i < object.employees.length; i++) {
            const employee = object.employees[i]
            employees[employee.id] = employee
          }

          for (let i = 0; i < object.dates.length; i++) {
            const date = object.dates[i]

            for (let j = 0; j < date.slots.length; j++) {
              const slot = date.slots[j]

              for (let k = 0; k < slot.services.length; k++) {
                const slotService = slot.services[k]

                slotService.service = services[slotService.service.id]
                slotService.employee = employees[slotService.employee.id]
              }
            }
          }
          resolve(object)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    timetableDay (date, voucher, pass) {
      const dateStamp = parseInt((date.getTime() / 1000) - (date.getTimezoneOffset() * 60))

      return new Promise((resolve, reject) => {
        const data = {
          date: dateStamp,
          voucher: (voucher) ? voucher.code : null,
          pass: (pass) ? pass.id : null
        }
        $post('location/booking/timetableDay', JSON.stringify(data)).then((object) => {
          const services = {}
          const employees = {}

          for (let i = 0; i < object.services.length; i++) {
            const service = object.services[i]
            services[service.id] = service
          }

          for (let i = 0; i < object.employees.length; i++) {
            const employee = object.employees[i]
            employees[employee.id] = employee
          }

          for (let j = 0; j < object.slots.length; j++) {
            const slot = object.slots[j]

            for (let k = 0; k < slot.services.length; k++) {
              const slotService = slot.services[k]

              slotService.service = services[slotService.service.id]
              slotService.employee = employees[slotService.employee.id]
            }
          }

          resolve(object)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    lastMinute (voucher, pass) {
      const data = {
        voucher: (voucher) ? voucher.code : null,
        pass: (pass) ? pass.id : null
      }
      return $post('location/booking/lastMinute', JSON.stringify(data))
    },
    lastMinuteAvailability (date, from, to, service, voucher, pass) {
      return new Promise((resolve, reject) => {
        const data = {
          date,
          from,
          to,
          service: service.id,
          voucher: (voucher) ? voucher.code : null,
          pass: (pass) ? pass.id : null
        }
        $post('location/booking/lastMinuteAvailability', JSON.stringify(data)).then((object) => {
          const services = {}
          const employees = {}

          for (let i = 0; i < object.services.length; i++) {
            const service = object.services[i]
            services[service.id] = service
          }

          for (let i = 0; i < object.employees.length; i++) {
            const employee = object.employees[i]
            employees[employee.id] = employee
          }

          for (let i = 0; i < object.slots.length; i++) {
            const slot = object.slots[i]

            for (let j = 0; j < slot.services.length; j++) {
              const slotService = slot.services[j]

              slotService.service = services[slotService.service.id]
              slotService.employee = employees[slotService.employee.id]
            }
          }

          resolve(object)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    monthAvailability (month, serviceSelections, moveReservation, voucher, pass) {
      const services = []
      for (let i = 0; i < serviceSelections.length; i++) {
        const serviceSelection = serviceSelections[i]
        const serviceInfo = {
          service: serviceSelection.service.id
        }
        if (serviceSelection.selectedEmployee) {
          serviceInfo.employee = serviceSelection.selectedEmployee.id
        }
        services.push(serviceInfo)
      }

      // const monthStamp = parseInt((month.getTime() / 1000) - (month.getTimezoneOffset() * 60))

      const start = moment(month).startOf('month').toDate()
      const end = moment(month).add(1, 'months').endOf('month').add(1, 'days').toDate()

      const startDate = parseInt((start.getTime() / 1000) - (start.getTimezoneOffset() * 60))
      const endDate = parseInt((end.getTime() / 1000) - (end.getTimezoneOffset() * 60))

      const request = {
        // month: monthStamp,
        startDate,
        endDate,
        services,
        voucher: (voucher) ? voucher.code : null,
        pass: (pass) ? pass.id : null
      }

      if (moveReservation) {
        if (moveReservation.idReservationCustomer) {
          request.moveReservationCustomer = moveReservation.idReservationCustomer
        } else {
          request.moveReservation = moveReservation.id
        }
      }

      return new Promise((resolve, reject) => {
        $post('location/booking/monthAvailability', JSON.stringify(request)).then((object) => {
          if (object.dates) {
            for (let i = 0; i < object.dates.length; i++) {
              const dateInfo = object.dates[i]
              dateInfo.date = new Date(dateInfo.date * 1000)
            }
          }
          resolve(object)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    dayAvailability (date, serviceSelections, moveReservation, voucher, pass) {
      const services = []
      for (let i = 0; i < serviceSelections.length; i++) {
        const serviceSelection = serviceSelections[i]
        const serviceInfo = {
          service: serviceSelection.service.id
        }
        if (serviceSelection.selectedEmployee) {
          serviceInfo.employee = serviceSelection.selectedEmployee.id
        }
        services.push(serviceInfo)
      }

      const dateStamp = parseInt((date.getTime() / 1000) - (date.getTimezoneOffset() * 60))

      const request = {
        date: dateStamp,
        services,
        voucher: (voucher) ? voucher.code : null,
        pass: (pass) ? pass.id : null
      }

      if (moveReservation) {
        if (moveReservation.idReservationCustomer) {
          request.moveReservationCustomer = moveReservation.idReservationCustomer
        } else {
          request.moveReservation = moveReservation.id
        }
      }

      return new Promise((resolve, reject) => {
        $post('location/booking/dayAvailability', JSON.stringify(request)).then((object) => {
          const services = {}
          const employees = {}

          if (object.services) {
            for (let i = 0; i < object.services.length; i++) {
              const service = object.services[i]
              services[service.id] = service
            }
          }

          if (object.employees) {
            for (let i = 0; i < object.employees.length; i++) {
              const employee = object.employees[i]
              employees[employee.id] = employee
            }
          }

          if (object.slots) {
            for (let j = 0; j < object.slots.length; j++) {
              const slot = object.slots[j]

              for (let k = 0; k < slot.services.length; k++) {
                const slotService = slot.services[k]

                slotService.service = services[slotService.service.id]
                slotService.employee = employees[slotService.employee.id]
              }
            }
          }

          resolve(object)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    waitlistAvailability (month, serviceSelections) {
      const services = []
      for (let i = 0; i < serviceSelections.length; i++) {
        const serviceSelection = serviceSelections[i]
        const serviceInfo = {
          service: serviceSelection.service.id
        }
        if (serviceSelection.selectedEmployee) {
          serviceInfo.employee = serviceSelection.selectedEmployee.id
        }
        services.push(serviceInfo)
      }

      const start = moment(month).startOf('month').toDate()
      const end = moment(month).add(1, 'months').endOf('month').add(1, 'days').toDate()

      const startDate = parseInt((start.getTime() / 1000) - (start.getTimezoneOffset() * 60))
      const endDate = parseInt((end.getTime() / 1000) - (end.getTimezoneOffset() * 60))


      // const monthStamp = parseInt((month.getTime() / 1000) - (month.getTimezoneOffset() * 60))

      const request = {
        // month: monthStamp,
        startDate,
        endDate,
        services
      }

      return new Promise((resolve, reject) => {
        $post('location/booking/waitlistAvailability', JSON.stringify(request)).then((object) => {
          if (object.dates) {
            for (let i = 0; i < object.dates.length; i++) {
              const dateInfo = object.dates[i]
              dateInfo.date = new Date(dateInfo.date * 1000)
            }
          }

          resolve(object)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    waitlist (customer, serviceSelections, dates, moveReservation) {
      const requestCustomer = {}

      if (customer.id) {
        requestCustomer.id = customer.id
      } else {
        requestCustomer.attributes = customer.fields
        requestCustomer.customFields = customer.customFields
        requestCustomer.acceptConsent = customer.privacyConsentAccepted
        requestCustomer.acceptMarketingConsent = customer.marketingConsentAccepted
      }

      const services = []
      for (let i = 0; i < serviceSelections.length; i++) {
        const serviceSelection = serviceSelections[i]
        const serviceInfo = {
          service: serviceSelection.service.id
        }
        if (serviceSelection.selectedEmployee) {
          serviceInfo.employee = serviceSelection.selectedEmployee.id
        }
        services.push(serviceInfo)
      }

      const request = {
        customer: requestCustomer,
        services,
        dates
      }

      if (moveReservation) {
        if (moveReservation.idReservationCustomer) {
          request.moveReservationCustomer = moveReservation.idReservationCustomer
        } else {
          request.moveReservation = moveReservation.id
        }
      }

      return $post('location/booking/waitlist', JSON.stringify(request))
    },
    customer (email) {
      return $post('location/booking/customer', { email })
    },
    slot (data) {
      return new Promise((resolve, reject) => {
        $post('location/booking/slot', { data }).then((object) => {
          const services = {}
          const employees = {}

          if (object.services) {
            for (let i = 0; i < object.services.length; i++) {
              const service = object.services[i]
              services[service.id] = service
            }
          }

          if (object.employees) {
            for (let i = 0; i < object.employees.length; i++) {
              const employee = object.employees[i]
              employees[employee.id] = employee
            }
          }

          if (object.slot) {
            for (let i = 0; i < object.slot.services.length; i++) {
              const slotService = object.slot.services[i]

              slotService.service = services[slotService.service.id]
              slotService.employee = employees[slotService.employee.id]
            }
          }

          resolve(object)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    newCustomer (fields, customFields, acceptedPrivacyConsent, acceptedMarketingConsent) {
      const request = {
        customer: fields,
        customFields,
        acceptConsent: acceptedPrivacyConsent,
        acceptMarketingConsent: acceptedMarketingConsent
      }

      return $post('location/booking/newCustomer', JSON.stringify(request))
    },
    
    validateCustomer (fields, customFields, privacyConsentAccepted, marketingConsentAccepted) {
      const request = {
        customer: fields,
        customFields,
        acceptConsent: privacyConsentAccepted,
        acceptMarketingConsent: marketingConsentAccepted,
        validateOnly: true
      }

      return $post('location/booking/newCustomer', JSON.stringify(request))
    },
    confirm (action, customer, slot, { fields, customFields, termsAccepted, giftCardVoucherCode, voucher, giftCards, paymentData, paymentParams, moveReservation, rebookReservation, waitlist, rwg_token }) {
      const requestCustomer = {}

      if (customer.id) {
        requestCustomer.id = customer.id
      } else {
        requestCustomer.attributes = customer.fields
        requestCustomer.customFields = customer.customFields
        requestCustomer.acceptConsent = customer.privacyConsentAccepted
        requestCustomer.acceptMarketingConsent = customer.marketingConsentAccepted
      }

      const request = {
        action,
        customer: requestCustomer,
        slot: { data: slot.data },
        fields,
        customFields,
        termsAccepted,
        payment: {}
      }

      if (giftCardVoucherCode) {
        request.giftCardVoucherRequest = giftCardVoucherCode
      }

      if (voucher) {
        request.voucher = { code: voucher.code }
      }

      if (giftCards) {
        request.payment.giftCards = giftCards.map((giftCard) => {
          return giftCard.number
        })
      }

      if (paymentData) {
        request.payment.data = paymentData
      }

      if (paymentParams) {
        request.payment.params = paymentParams
      }

      if (moveReservation) {
        if (moveReservation.idReservationCustomer) {
          request.moveReservationCustomer = moveReservation.idReservationCustomer
        } else {
          request.moveReservation = moveReservation.id
        }
      }

      if (rebookReservation) {
        if (rebookReservation.idReservationCustomer) {
          request.rebookReservationCustomer = rebookReservation.idReservationCustomer
        } else {
          request.rebookReservation = rebookReservation.id
        }
      }

      if (waitlist) {
        request.waitlist = waitlist
      }

      if (rwg_token) {
        request.rwg_token = rwg_token
      }

      return $post('location/booking/confirm', JSON.stringify(request))
    },
    reservation (id, date, customer, signature) {
      const request = {
        id,
        date,
        customer: customer.id,
        signature
      }

      return $post('location/booking/reservation', JSON.stringify(request))
    },
    voucher (code) {
      const request = {
        code
      }

      return $post('location/booking/voucher', JSON.stringify(request))
    },
    customerPass (id, signature) {
      const request = {
        id,
        signature
      }

      return $post('location/booking/customerPass', JSON.stringify(request))
    }
  }
}

import Vue from 'vue'
import VueGtag from "vue-gtag";

export default (context, inject) => {
  if (process.env.NODE_ENV !== 'production') {
    // return
  }

  let dimension = 'site'

  if (context.app.$site.layout === 'bot') {
    dimension = 'bot'
  } else if (context.app.$site.layout === 'widget') {
    dimension = 'widget'
  } else if (context.app.$site.layout === 'facebook') {
    dimension = 'facebook'
  }

  const includes = []

  // Default Ovatu Tracking
  includes.push({
    id: 'G-B4H1X35WN0',
    params: {
      dimension1: dimension
    }
  })


  // Google analytics integration
  if (context.$site.integrations) {
    const integration = context.$site.integrations['1d8fbf495ebabf8c5cad1a8c2bf97662']
    const trackingID = integration && integration.data && integration.enabled ? integration.data.trackingID : null

    if (trackingID) {
      includes.push({
        id: trackingID,
        params: {
          dimension1: dimension
        }
      })
    }
  }

  const options = {
    config: {
      id: 'G-B4H1X35WN0',
    },
    includes
  }

  Vue.use(VueGtag, options, context.app.router)

  context.$gtag = Vue.$gtag
  inject('gtag', Vue.$gtag)
}

<template>
  <nav :style="{ backgroundImage: showHeaderBackground ? hero : null }" class="hidden sm:block w-full z-20 absolute top-0 bg-center bg-cover">
    <div :class="{ 'bg-header/[var(--bookapp-opacity-hero)]' : showHeaderBackground }" class="h-full w-full px-2 sm:px-4 py-2.5">
      <div class="container items-center max-w-6xl mx-auto grid grid-cols-7">
        <div>
          <nuxt-link v-if="!isHome" to="/" class="">
            <div
              v-if="$site.images.logo"
              class="mr-3 h-6 sm:h-12 bg-contain bg-left bg-no-repeat"
              :style="{
                backgroundImage: `url(${ $site.images.logo.medium })`
              }"
            />
            <span v-else class="self-center text-base font-semibold whitespace-nowrap text-secondary">{{ businessName }}</span>
          </nuxt-link>
        </div>

        <div class="hidden sm:block w-full col-span-5">
          <div class="flex-grow flex justify-center p-4 flex-row space-x-3 rtl:space-x-reverse mt-0 text-sm font-medium border-0">
            <!-- Has Custom Menu -->
            <template v-if="menu">
              <div v-for="(item, key) in menu" :key="`${key}-${item.type}`">
                <template v-if="item.type === 'group' && item.children && item.children.length">
                  <navigation-group :item="item" />
                </template>

                <template v-else-if="item.type === 'tab' || item.type === 'custom'">
                  <navigation-item :item="item" />
                </template>
              </div>
            </template>

            <!-- Has Default (Auto) Menu -->
            <template v-else>
              <template v-for="tab in tabs">
                <div
                  v-if="$site.tabs[tab]"
                  :key="tab"
                >
                  <navigation-item
                    :item="{ id: tab }"
                  />
                </div>
              </template>
            </template>

            <div>
              <navigation-item
                v-if="$site.tabs.user"
                :item="{ id: 'user', name: userTabName }"
              />
            </div>
          </div>
        </div>

        <div class="flex items-center justify-end">
          <button v-if="showMenuToggle" class="bg-transparent text-[var(--bookapp-hero-text-color)] text-xl" @click="toggleMenu">
            <b-icon icon="menu" />
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import NavigationItem from './NavigationItem'
import NavigationGroup from './NavigationGroup'

export default {
  components: {
    NavigationItem,
    NavigationGroup
  },
  data () {
    return {
      tabs: [
        'book',
        'timetable',
        'lastMinute',
        'giftCard',
        'pass',
        'gallery',
        'review'
      ]
    }
  },
  computed: {
    menu () {
      if (this.$site.menu?.primary) {
        return this.$site.menu.primary || []
      }

      return false
    },
    active: {
      get () {
        return this.$store.getters.menu
      },
      set (val) {
        this.$store.commit('setMenu', val)
      }
    },
    isHome () {
      return this.$route.path === '/'
    },
    isLoggedIn () {
      return this.$auth.loggedIn
    },
    isAppLayout () {
      return this.$store.getters.layout === 'app'
    },
    isCustomPage () {
      return this.$route.path.includes('/page/')
    },
    userTabName () {
      if (this.$auth.loggedIn) {
        return this.$auth.user.displayName
      } else {
        return this.$site.tabs.getName('user', 'Customer')
      }
    },
    showHeaderBackground () {
      if (this.isHome) {
        return false
      }

      if (this.isCustomPage) {
        const customPages = this.$site.customPages || {}
        const customPage = Object.values(customPages).find(page => page.slug === this.$route.params.slug)

        if (customPage) {
          return !customPage.hero
        }
      }

      return true
    },
    businessName () {
      if (this.$site.tabs.contact && this.$site.tabs.contact.business) {
        return this.$site.tabs.contact.business
      } else {
        return this.$site.location.title
      }
    },
    hero () {
      return this.$site.images.heroImageCssUrl('large')
    },
    showMenuToggle () {
      return this.$site.menu?.primary || this.$site.tabs.user || this.$site.customPages?.length > 0
    }
  },
  methods: {
    toggleMenu () {
      this.active = !this.active
    }
  }
}
</script>

import areIntlLocalesSupported from 'intl-locales-supported'
import locales from '@/lang'

export default ({ app, store }) => {
  if (process.client) {
    const supported = locales.map(locale => locale.code)

    if (global.Intl) {
      // Determine if the built-in `Intl` has the locale data we need.
      if (!areIntlLocalesSupported(supported)) {
        // `Intl` exists, but it doesn't have the data we need, so load the
        // polyfill and patch the constructors we need with the polyfill's.
        const IntlPolyfill = require('intl')
        Intl.NumberFormat = IntlPolyfill.NumberFormat
        Intl.DateTimeFormat = IntlPolyfill.DateTimeFormat

        for (const locale of locales) {
          require(`intl/locale-data/jsonp/${locale.intl || locale.file}`)
        }
      }
    } else {
      // No `Intl`, so use and load the polyfill.
      global.Intl = require('intl')
    }
  }
}

<template>
  <div class="flex items-center space-x-4 rtl:space-x-reverse">
    <span v-for="(link, index) in $site.settings.socialLinks" :key="index">
      <a v-if="link.type === 'tiktok' || link.type === 'youtube'" class="text-primary text-xl" target="_blank" :href="`https://www.${link.type}.com/@${link.id}`"><font-awesome-icon :icon="['fab', link.type]" /></a>
      <a v-else-if="link.type === 'whatsapp'" class="text-primary text-xl" target="_blank" :href="'https://www.whatsapp.com/channel/' + link.id"><font-awesome-icon :icon="['fab', 'whatsapp']" /></a>
      <a v-else-if="link.type === 'linkedin'" class="text-primary text-xl" target="_blank" :href="'https://www.linkedin.com/company/' + link.id"><font-awesome-icon :icon="['fab', 'linkedin']" /></a>
      <a v-else-if="link.type === 'reddit'" class="text-primary text-xl" target="_blank" :href="'https://www.reddit.com/user/' + link.id"><font-awesome-icon :icon="['fab', 'reddit']" /></a>
      <a v-else-if="link.type === 'threads'" class="text-primary text-xl" target="_blank" :href="'https://www.threads.net/@' + link.id"><font-awesome-icon :icon="['fab', 'threads']" /></a>
      <a v-else-if="link.type === 'linktree'" class="text-primary text-xl" target="_blank" :href="'https://www.linktr.ee/' + link.id">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m13.736 5.853l4.005-4.117l2.325 2.38l-4.2 4.005h5.908v3.305h-5.937l4.229 4.108l-2.325 2.334l-5.74-5.769l-5.741 5.769l-2.325-2.325l4.229-4.108H2.226V8.121h5.909l-4.2-4.004l2.324-2.381l4.005 4.117V0h3.472zm-3.472 10.306h3.472V24h-3.472z" /></svg>
      </a> <!-- no fa linktree icon -->
      <a v-else class="text-primary text-xl" target="_blank" :href="`https://www.${link.type}.com/${link.id}`"><font-awesome-icon :icon="['fab', link.type]" /></a>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    socialLinks: {
      type: Array,
      default: () => []
    }
  }
}
</script>

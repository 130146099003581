import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5cc263fc = () => interopDefault(import('../pages/book-now/index.vue' /* webpackChunkName: "pages/book-now/index" */))
const _52f32953 = () => interopDefault(import('../pages/bot/index.vue' /* webpackChunkName: "pages/bot/index" */))
const _68dea230 = () => interopDefault(import('../pages/customer/index.vue' /* webpackChunkName: "pages/customer/index" */))
const _17252e1e = () => interopDefault(import('../pages/dev.vue' /* webpackChunkName: "pages/dev" */))
const _9bec1ec4 = () => interopDefault(import('../pages/gallery/index.vue' /* webpackChunkName: "pages/gallery/index" */))
const _1313a93a = () => interopDefault(import('../pages/gift/index.vue' /* webpackChunkName: "pages/gift/index" */))
const _dc10f292 = () => interopDefault(import('../pages/last-minute/index.vue' /* webpackChunkName: "pages/last-minute/index" */))
const _660b25c8 = () => interopDefault(import('../pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _5ee83edb = () => interopDefault(import('../pages/pass/index.vue' /* webpackChunkName: "pages/pass/index" */))
const _7dd71bd1 = () => interopDefault(import('../pages/preview.vue' /* webpackChunkName: "pages/preview" */))
const _35b2c627 = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _4a2e9259 = () => interopDefault(import('../pages/test.vue' /* webpackChunkName: "pages/test" */))
const _1557fb6d = () => interopDefault(import('../pages/timetable/index.vue' /* webpackChunkName: "pages/timetable/index" */))
const _f6445ce4 = () => interopDefault(import('../pages/widget/index.vue' /* webpackChunkName: "pages/widget/index" */))
const _275c4ebe = () => interopDefault(import('../pages/book-now/availability.vue' /* webpackChunkName: "pages/book-now/availability" */))
const _58381c7f = () => interopDefault(import('../pages/book-now/complete.vue' /* webpackChunkName: "pages/book-now/complete" */))
const _39215e6a = () => interopDefault(import('../pages/book-now/confirm.vue' /* webpackChunkName: "pages/book-now/confirm" */))
const _b35adeb8 = () => interopDefault(import('../pages/book-now/customer.vue' /* webpackChunkName: "pages/book-now/customer" */))
const _5845a9d2 = () => interopDefault(import('../pages/book-now/detail.vue' /* webpackChunkName: "pages/book-now/detail" */))
const _a6bad2a2 = () => interopDefault(import('../pages/bookapp/bookapplogin.vue' /* webpackChunkName: "pages/bookapp/bookapplogin" */))
const _c049ce50 = () => interopDefault(import('../pages/customer/details.vue' /* webpackChunkName: "pages/customer/details" */))
const _9ca5f6c6 = () => interopDefault(import('../pages/customer/giftcard/index.vue' /* webpackChunkName: "pages/customer/giftcard/index" */))
const _69191d42 = () => interopDefault(import('../pages/customer/login/index.vue' /* webpackChunkName: "pages/customer/login/index" */))
const _5ee2f664 = () => interopDefault(import('../pages/customer/logout.vue' /* webpackChunkName: "pages/customer/logout" */))
const _55d287a4 = () => interopDefault(import('../pages/customer/pass/index.vue' /* webpackChunkName: "pages/customer/pass/index" */))
const _fee8cb22 = () => interopDefault(import('../pages/customer/recovery.vue' /* webpackChunkName: "pages/customer/recovery" */))
const _78810740 = () => interopDefault(import('../pages/customer/register/index.vue' /* webpackChunkName: "pages/customer/register/index" */))
const _fb98c936 = () => interopDefault(import('../pages/customer/reservation/index.vue' /* webpackChunkName: "pages/customer/reservation/index" */))
const _74531ce5 = () => interopDefault(import('../pages/customer/reset.vue' /* webpackChunkName: "pages/customer/reset" */))
const _2052cce4 = () => interopDefault(import('../pages/customer/sale/index.vue' /* webpackChunkName: "pages/customer/sale/index" */))
const _8e892926 = () => interopDefault(import('../pages/customer/waitlist.vue' /* webpackChunkName: "pages/customer/waitlist" */))
const _ae2fcdc0 = () => interopDefault(import('../pages/emails/unsubscribe.vue' /* webpackChunkName: "pages/emails/unsubscribe" */))
const _461bed4a = () => interopDefault(import('../pages/gift/card/index.vue' /* webpackChunkName: "pages/gift/card/index" */))
const _164d651c = () => interopDefault(import('../pages/gift/pass/index.vue' /* webpackChunkName: "pages/gift/pass/index" */))
const _4b716140 = () => interopDefault(import('../pages/pass/complete.vue' /* webpackChunkName: "pages/pass/complete" */))
const _493bfd89 = () => interopDefault(import('../pages/pass/confirm.vue' /* webpackChunkName: "pages/pass/confirm" */))
const _cce85536 = () => interopDefault(import('../pages/pass/customer.vue' /* webpackChunkName: "pages/pass/customer" */))
const _bee0f116 = () => interopDefault(import('../pages/payment/callback.vue' /* webpackChunkName: "pages/payment/callback" */))
const _f41ec520 = () => interopDefault(import('../pages/widget/disabled.vue' /* webpackChunkName: "pages/widget/disabled" */))
const _abfb09fe = () => interopDefault(import('../pages/book-now/waitlist/availability.vue' /* webpackChunkName: "pages/book-now/waitlist/availability" */))
const _1158eedf = () => interopDefault(import('../pages/book-now/waitlist/complete.vue' /* webpackChunkName: "pages/book-now/waitlist/complete" */))
const _5f736304 = () => interopDefault(import('../pages/book-now/waitlist/customer.vue' /* webpackChunkName: "pages/book-now/waitlist/customer" */))
const _3a1e824e = () => interopDefault(import('../pages/customer/form/submit.vue' /* webpackChunkName: "pages/customer/form/submit" */))
const _2021350a = () => interopDefault(import('../pages/customer/form/thanks.vue' /* webpackChunkName: "pages/customer/form/thanks" */))
const _7e8fec2d = () => interopDefault(import('../pages/customer/login/code.vue' /* webpackChunkName: "pages/customer/login/code" */))
const _179dcb0c = () => interopDefault(import('../pages/customer/login/email.vue' /* webpackChunkName: "pages/customer/login/email" */))
const _70a3e415 = () => interopDefault(import('../pages/customer/login/expired.vue' /* webpackChunkName: "pages/customer/login/expired" */))
const _00b77f1e = () => interopDefault(import('../pages/customer/register/success.vue' /* webpackChunkName: "pages/customer/register/success" */))
const _71bb40da = () => interopDefault(import('../pages/customer/reservation/confirm.vue' /* webpackChunkName: "pages/customer/reservation/confirm" */))
const _2952e455 = () => interopDefault(import('../pages/customer/reservation/review.vue' /* webpackChunkName: "pages/customer/reservation/review" */))
const _2c3934d0 = () => interopDefault(import('../pages/customer/sale/payment.vue' /* webpackChunkName: "pages/customer/sale/payment" */))
const _09ca7876 = () => interopDefault(import('../pages/gift/card/claim.vue' /* webpackChunkName: "pages/gift/card/claim" */))
const _fe54f280 = () => interopDefault(import('../pages/gift/card/complete.vue' /* webpackChunkName: "pages/gift/card/complete" */))
const _6bfd2809 = () => interopDefault(import('../pages/gift/card/confirm.vue' /* webpackChunkName: "pages/gift/card/confirm" */))
const _62200a36 = () => interopDefault(import('../pages/gift/card/customer.vue' /* webpackChunkName: "pages/gift/card/customer" */))
const _0a7f6111 = () => interopDefault(import('../pages/gift/card/preview.vue' /* webpackChunkName: "pages/gift/card/preview" */))
const _34761f86 = () => interopDefault(import('../pages/gift/pass/claim.vue' /* webpackChunkName: "pages/gift/pass/claim" */))
const _2f50075f = () => interopDefault(import('../pages/gift/pass/complete.vue' /* webpackChunkName: "pages/gift/pass/complete" */))
const _881ed0ec = () => interopDefault(import('../pages/gift/pass/confirm.vue' /* webpackChunkName: "pages/gift/pass/confirm" */))
const _7d6a7b84 = () => interopDefault(import('../pages/gift/pass/customer.vue' /* webpackChunkName: "pages/gift/pass/customer" */))
const _1479741b = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _0997ed22 = () => interopDefault(import('../pages/book-now/category/_id.vue' /* webpackChunkName: "pages/book-now/category/_id" */))
const _d8cafbec = () => interopDefault(import('../pages/book-now/service/_id.vue' /* webpackChunkName: "pages/book-now/service/_id" */))
const _0c1a39cb = () => interopDefault(import('../pages/message/_slug.vue' /* webpackChunkName: "pages/message/_slug" */))
const _4747d2de = () => interopDefault(import('../pages/page/_slug.vue' /* webpackChunkName: "pages/page/_slug" */))
const _31a6bee9 = () => interopDefault(import('../pages/service/_id.vue' /* webpackChunkName: "pages/service/_id" */))
const _3df784b0 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/book-now",
    component: _5cc263fc,
    name: "book-now"
  }, {
    path: "/bot",
    component: _52f32953,
    name: "bot"
  }, {
    path: "/customer",
    component: _68dea230,
    name: "customer"
  }, {
    path: "/dev",
    component: _17252e1e,
    name: "dev"
  }, {
    path: "/gallery",
    component: _9bec1ec4,
    name: "gallery"
  }, {
    path: "/gift",
    component: _1313a93a,
    name: "gift"
  }, {
    path: "/last-minute",
    component: _dc10f292,
    name: "last-minute"
  }, {
    path: "/maintenance",
    component: _660b25c8,
    name: "maintenance"
  }, {
    path: "/pass",
    component: _5ee83edb,
    name: "pass"
  }, {
    path: "/preview",
    component: _7dd71bd1,
    name: "preview"
  }, {
    path: "/reviews",
    component: _35b2c627,
    name: "reviews"
  }, {
    path: "/test",
    component: _4a2e9259,
    name: "test"
  }, {
    path: "/timetable",
    component: _1557fb6d,
    name: "timetable"
  }, {
    path: "/widget",
    component: _f6445ce4,
    name: "widget"
  }, {
    path: "/book-now/availability",
    component: _275c4ebe,
    name: "book-now-availability"
  }, {
    path: "/book-now/complete",
    component: _58381c7f,
    name: "book-now-complete"
  }, {
    path: "/book-now/confirm",
    component: _39215e6a,
    name: "book-now-confirm"
  }, {
    path: "/book-now/customer",
    component: _b35adeb8,
    name: "book-now-customer"
  }, {
    path: "/book-now/detail",
    component: _5845a9d2,
    name: "book-now-detail"
  }, {
    path: "/bookapp/bookapplogin",
    component: _a6bad2a2,
    name: "bookapp-bookapplogin"
  }, {
    path: "/customer/details",
    component: _c049ce50,
    name: "customer-details"
  }, {
    path: "/customer/giftcard",
    component: _9ca5f6c6,
    name: "customer-giftcard"
  }, {
    path: "/customer/login",
    component: _69191d42,
    name: "customer-login"
  }, {
    path: "/customer/logout",
    component: _5ee2f664,
    name: "customer-logout"
  }, {
    path: "/customer/pass",
    component: _55d287a4,
    name: "customer-pass"
  }, {
    path: "/customer/recovery",
    component: _fee8cb22,
    name: "customer-recovery"
  }, {
    path: "/customer/register",
    component: _78810740,
    name: "customer-register"
  }, {
    path: "/customer/reservation",
    component: _fb98c936,
    name: "customer-reservation"
  }, {
    path: "/customer/reset",
    component: _74531ce5,
    name: "customer-reset"
  }, {
    path: "/customer/sale",
    component: _2052cce4,
    name: "customer-sale"
  }, {
    path: "/customer/waitlist",
    component: _8e892926,
    name: "customer-waitlist"
  }, {
    path: "/emails/unsubscribe",
    component: _ae2fcdc0,
    name: "emails-unsubscribe"
  }, {
    path: "/gift/card",
    component: _461bed4a,
    name: "gift-card"
  }, {
    path: "/gift/pass",
    component: _164d651c,
    name: "gift-pass"
  }, {
    path: "/pass/complete",
    component: _4b716140,
    name: "pass-complete"
  }, {
    path: "/pass/confirm",
    component: _493bfd89,
    name: "pass-confirm"
  }, {
    path: "/pass/customer",
    component: _cce85536,
    name: "pass-customer"
  }, {
    path: "/payment/callback",
    component: _bee0f116,
    name: "payment-callback"
  }, {
    path: "/widget/disabled",
    component: _f41ec520,
    name: "widget-disabled"
  }, {
    path: "/book-now/waitlist/availability",
    component: _abfb09fe,
    name: "book-now-waitlist-availability"
  }, {
    path: "/book-now/waitlist/complete",
    component: _1158eedf,
    name: "book-now-waitlist-complete"
  }, {
    path: "/book-now/waitlist/customer",
    component: _5f736304,
    name: "book-now-waitlist-customer"
  }, {
    path: "/customer/form/submit",
    component: _3a1e824e,
    name: "customer-form-submit"
  }, {
    path: "/customer/form/thanks",
    component: _2021350a,
    name: "customer-form-thanks"
  }, {
    path: "/customer/login/code",
    component: _7e8fec2d,
    name: "customer-login-code"
  }, {
    path: "/customer/login/email",
    component: _179dcb0c,
    name: "customer-login-email"
  }, {
    path: "/customer/login/expired",
    component: _70a3e415,
    name: "customer-login-expired"
  }, {
    path: "/customer/register/success",
    component: _00b77f1e,
    name: "customer-register-success"
  }, {
    path: "/customer/reservation/confirm",
    component: _71bb40da,
    name: "customer-reservation-confirm"
  }, {
    path: "/customer/reservation/review",
    component: _2952e455,
    name: "customer-reservation-review"
  }, {
    path: "/customer/sale/payment",
    component: _2c3934d0,
    name: "customer-sale-payment"
  }, {
    path: "/gift/card/claim",
    component: _09ca7876,
    name: "gift-card-claim"
  }, {
    path: "/gift/card/complete",
    component: _fe54f280,
    name: "gift-card-complete"
  }, {
    path: "/gift/card/confirm",
    component: _6bfd2809,
    name: "gift-card-confirm"
  }, {
    path: "/gift/card/customer",
    component: _62200a36,
    name: "gift-card-customer"
  }, {
    path: "/gift/card/preview",
    component: _0a7f6111,
    name: "gift-card-preview"
  }, {
    path: "/gift/pass/claim",
    component: _34761f86,
    name: "gift-pass-claim"
  }, {
    path: "/gift/pass/complete",
    component: _2f50075f,
    name: "gift-pass-complete"
  }, {
    path: "/gift/pass/confirm",
    component: _881ed0ec,
    name: "gift-pass-confirm"
  }, {
    path: "/gift/pass/customer",
    component: _7d6a7b84,
    name: "gift-pass-customer"
  }, {
    path: "/",
    component: _1479741b,
    name: "index"
  }, {
    path: "/book-now/category/:id",
    component: _0997ed22,
    name: "book-now-category-id"
  }, {
    path: "/book-now/service/:id",
    component: _d8cafbec,
    name: "book-now-service-id"
  }, {
    path: "/message/:slug?",
    component: _0c1a39cb,
    name: "message-slug"
  }, {
    path: "/page/:slug?",
    component: _4747d2de,
    name: "page-slug"
  }, {
    path: "/service/:id?",
    component: _31a6bee9,
    name: "service-id"
  }, {
    path: "/*",
    component: _3df784b0,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

export const state = () => ({
  services: null,
  timetable: null,
  lastMinute: null,
  location: null,
  settings: null,
  images: null,
  integrations: null,
  tabs: null,
  colours: null,
  slug: null,
  menu: null,
  domain: null
})

export const mutations = {
  setServices(state, services) {
    state.services = services
  },
  setTimetable(state, timetable) {
    state.timetable = timetable
  },
  setLastMinute(state, lastMinute) {
    state.lastMinute = lastMinute
  },
  setLocationInfo(state, { location, settings, images, integrations, tabs, colours, slug, domain, menu, customPages }) {
    state.location = location
    state.settings = settings
    state.images = images
    state.integrations = integrations
    state.tabs = tabs
    state.colours = colours
    state.slug = slug
    state.menu = menu
    state.customPages = customPages
    state.domain = domain
  }
}

export const actions = {
  async getServices(context) {
    if (!context.state.services) {
      const response = await this.$api.booking.services()
      context.commit('setServices', response.services)
      return response
    }
  },
  async getTimetable(context) {
    const response = await this.$api.booking.timetable()
    context.commit('setTimetable', response)
    return response
  },
  async getLastMinute(context) {
    const response = await this.$api.booking.lastMinute()
    context.commit('setLastMinute', response)
    return response
  },
  setLocationInfo(context, { location, settings, images, integrations, tabs, colours, slug, domain, menu, customPages }) {
    context.commit('setLocationInfo', { location, settings, images, integrations, tabs, colours, slug, domain, menu, customPages })
  }
}

export const getters = {
  services(state) {
    return state.services
  },
  allServices(state) {
    const allServices = []

    if (state.services) {
      for (let i = 0; i < state.services.categories.length; ++i) {
        const category = state.services.categories[i]
        for (let j = 0; j < category.services.length; ++j) {
          allServices.push(category.services[j])
        }
      }
    }
    return allServices
  },
  getServiceById: state => (id) => {
    if (!state.services) return null
    
    for (let i = 0; i < state.services.categories.length; ++i) {
      const category = state.services.categories[i]
      for (let j = 0; j < category.services.length; ++j) {
        if (category.services[j].id == id) return category.services[j]
      }
    }
    return null
  },
  timetable(state) {
    return state.timetable
  },
  menu(state) {
    return state.menu
  },
  customPages(state) {
    return state.customPages
  },
  lastMinute(state) {
    return state.lastMinute
  },
  location(state) {
    return state.location
  },
  settings(state) {
    return state.settings
  },
  images(state) {
    return state.images
  },
  integrations(state) {
    return state.integrations
  },
  slug(state) {
    return state.slug
  },
  tabs(state) {
    return state.tabs
  },
  colours(state) {
    return state.colours
  },
  domain(state) {
    return state.domain
  }
}

<template>
  <div>
    <div class="flex items-center justify-between">
      <div class="space-x-1  rtl:space-x-reverse" role="tablist" aria-orientation="horizontal">
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          :ref="tab.hash"
          class="rounded px-5 py-2.5 text-sm font-medium leading-5 "
          :class="tab.isActive ? 'bg-action text-action shadow' : 'bg-action/20 text-primary'"
          role="tab"
          type="button"
          :tabindex="index"
          @click="selectTab(tab.hash, $event)"
        >
          {{ tab.name }}

          <span
            v-if="tab.badge"
            class="ml-3  px-2 py-1 rounded text-xs leading-none inline-block"
            :class="[ tab.isActive ? 'bg-white/40 text-primary' : 'bg-action text-action' ]"
          >
            {{ tab.badge }}
          </span>

          <b-spinner v-if="tab.loading" class="ml-3" />
        </button>
      </div>

      <slot name="action" />
    </div>
    <div class="mt-2 w-full">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BTabs',
  props: {
    navClass: {
      type: String,
      default: 'border-b border-gray-300 px-5'
    },
    containerClass: {
      type: String,
      default: 'pt-4 pb-4'
    }
  },
  data () {
    return {
      children: [],
      activeTabHash: '',
      activeTabIndex: 0,
      lastActiveTabHash: '',
      firstTabIndex: 0,
      scrollable: false
    }
  },
  computed: {
    tabs () {
      return this.getChildTabs(this.children)
    },
    underline () {
      const active = this.$refs[this.activeTabHash]

      let left = '0px'
      let width = '0px'

      if (active) {
        left = active[0].offsetLeft + 'px'
        width = active[0].getBoundingClientRect().width + 'px'
      }

      return {
        left,
        width
      }
    },
    activeTab () {
      return this.findTab(this.activeTabHash)
    }
  },
  created () {
    this.children = this.$children
  },
  mounted () {
    if (this.tabs.length) {
      this.$nextTick(() => {
        let initial = 0

        // for (const tab of this.tabs) {
        for (const index in this.tabs) {
          const tab = this.tabs[index]
          tab.showHeader = this.showHeader

          if (tab.initial) {
            initial = index
          }
        }

        this.selectTab(this.tabs[initial].hash)

        if (this.$route?.query?.tab) {
          const query = this.$route?.query?.tab
          const hash = '#' + query

          if (hash) {
            this.selectTab(hash)
          }
        }
      })
    }
  },
  methods: {
    getChildTabs (children) {
      const tabs = []

      for (const child of children) {
        if (child._isTab) {
          tabs.push(child)
        }

        tabs.push(...this.getChildTabs(child.$children))
      }

      return tabs
    },
    findTab (hash) {
      return this.tabs.find(tab => tab.hash === hash)
    },
    selectTab (selectedTabHash, event) {
      // See if we should store the hash in the url fragment.
      const selectedTab = this.findTab(selectedTabHash)
      if (!selectedTab) {
        return
      }
      if (event && selectedTab.isDisabled) {
        event.preventDefault()
        return
      }
      if (this.lastActiveTabHash === selectedTab.hash) {
        this.$emit('clicked', {
          tab: selectedTab
        })
        return
      }
      this.tabs.forEach((tab) => {
        tab.isActive = (tab.hash === selectedTab.hash)
      })
      this.$emit('changed', {
        tab: selectedTab
      })
      this.activeTabHash = selectedTab.hash
      this.activeTabIndex = this.getTabIndex(selectedTabHash)
      this.lastActiveTabHash = this.activeTabHash = selectedTab.hash

      this.$nextTick(() => {
        this.scrollable = this.scroll || selectedTab?.scroll || false
      })
    },
    selectTabByIndex (index) {
      const hash = this.getTabHash(index)

      this.selectTab(hash)
    },
    setTabVisible (hash, visible) {
      const tab = this.findTab(hash)
      if (!tab) {
        return
      }
      tab.isVisible = visible
      if (tab.isActive) {
        // If tab is active, set a different one as active.
        tab.isActive = visible
        this.tabs.every((tab, index, array) => {
          if (tab.isVisible) {
            tab.isActive = true
            return false
          }
          return true
        })
      }
    },
    getTabIndex (hash) {
      const tab = this.findTab(hash)

      return this.tabs.indexOf(tab)
    },
    getTabHash (index) {
      const tab = this.tabs.find(tab => this.tabs.indexOf(tab) === index)

      if (!tab) {
        return
      }

      return tab.hash
    },
    getActiveTab () {
      return this.findTab(this.activeTabHash)
    },
    getActiveTabIndex () {
      return this.getTabIndex(this.activeTabHash)
    },
    handleMoveScroll (position = 1) {
      const element = this.$refs.scroll
      const current = this.activeTabIndex
      const tabs = this.tabs

      const index = current + position

      if (index >= 0 && index < tabs.length) {
        const hash = tabs[index].hash
        const tab = this.$refs[hash]

        if (tab) {
          element.scroll(tab[0], 500)
          this.selectTab(hash)
          this.activeTabIndex = index
        }
      }
    }
  }
}
</script>

export default (context, inject) => {
  const i18n = context.app.i18n
  const dateFns = context.$dateFns

  const userAgent = (function () {
    if (typeof context.req !== 'undefined') {
      return context.req.headers['user-agent']
    } else if (typeof navigator !== 'undefined') {
      return navigator.userAgent
    } else {
      return false
    }
  })()

  const format = {
    parse (value) {
      const date = value.toString()

      if (date.length == 8) {
        const year = date.substring(0, 4)
        const month = date.substring(4, 6)
        const day = date.substring(6, 8)

        return new Date(year, month - 1, day, 0, 0, 0, 0)
      }

      return null
    },
    day (d, timeZone = 'local') {
      const locale = i18n.locale
      const options = { weekday: 'long' }

      if (timeZone !== 'local') { options.timeZone = timeZone }

      return new Intl.DateTimeFormat(locale, options).format(d)
    },
    date (d, timeZone = 'UTC') {
      const locale = i18n.locale
      const options = { ...i18n.i('datetime.format.date') }

      if (timeZone !== 'local') { options.timeZone = timeZone }

      return new Intl.DateTimeFormat(locale, options).format(d * 1000)
    },
    time (d, timeZone = 'UTC') {
      const locale = i18n.locale
      const options = { ...i18n.i('datetime.format.time') }

      if (timeZone !== 'local') { options.timeZone = timeZone }

      return new Intl.DateTimeFormat(locale, options).format(d * 1000)
    },
    minuteTime (t) {
      const locale = i18n.locale
      const options = { ...i18n.i('datetime.format.time') }
      options.timeZone = 'UTC'

      return new Intl.DateTimeFormat(locale, options).format(t * 60 * 1000)
    },
    link (url) {
      return !url.match(/^[a-zA-Z]+:\/\//) ? 'http://' + url : url
    },
    maps (address) {
      const isIOS = userAgent ? /iPad|iPhone|iPod/.test(userAgent.platform) : false

      if (isIOS) {
        return 'https://maps.apple.com/?address=' + encodeURIComponent(address)
      }

      return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`

    }
  }
  inject('format', format)
}

window.onNuxtReady(({ $store, $i18n }) => {
  const browser = window.navigator.language
  const location = $store.getters['location/location']

  if (!location) return

  const locale = location.locale
  const fallback = locale.substring(0, 2) + '-' + locale.substring(3, 5).toUpperCase()
  const fallbackShort = fallback.substring(0, 2)
  const available = $i18n.locales.map(({ code }) => code)

  if (!available.includes(browser) && !available.includes(browser.substring(0, 2))) {
    if (available.includes(fallback)) {
      $i18n.setLocale(fallback)
      $i18n.defaultLocale = $i18n.fallbackLocale = fallback
    } else if (available.includes(fallbackShort)) {
      $i18n.setLocale(fallbackShort)
      $i18n.defaultLocale = $i18n.fallbackLocale = fallbackShort
    }
  }
})

export default function (req, res, next) {
  const Bowser = require('bowser')
  const userAgent = req.headers['user-agent']

  if (!userAgent || userAgent.length === 0) {
    return next()
  }

  const browser = Bowser.getParser(userAgent)

  if (browser.isBrowser('internet explorer')) {
    res.writeHead(301, { Location: '/browser.html' })
    res.end()
  } else {
    next()
  }
}

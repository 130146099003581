export default ({ axios, get, post, $get, $post }) => {
  return {
    customDomain ({ domain, verify = null }) {
      const request = {
        domain,
        verify
      }

      return $post('location/customDomain', JSON.stringify(request))
    },
    info () {
      return $get('location/info')
    },
    service (id) {
      return $get('location/service?id=' + id)
    },
    reviews (page, limit) {
      return $post('location/reviews', JSON.stringify({ page, limit }))
    },
    employees (ids) {
      const response = $post('location/employees', JSON.stringify({ ids }))
      return response
    },
    instagramFeed () {
      return $get('location/instagramFeed')
    },
    getState ({ key, auth }) {
      return $get(
        `location/state?key=${encodeURIComponent(
          key
        )}&auth=${encodeURIComponent(auth)}`
      )
    },
    setState ({ data, key = null }) {
      return $post('location/state', JSON.stringify({ key, data }))
    },
    customPage({ slug }) {
      return $post('location/customPage', JSON.stringify({ slug }))
    },
    employees (ids) {
      return $post('location/employees', { ids })
    },
    services (ids) {
      return $post('location/services', { ids, format: 'list' })
    },
  }
}

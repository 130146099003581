export default ({ app, $site }, inject) => {
  const isProduction = (process.env.NODE_ENV === 'production')

  if (!isProduction || !$site.integrations) return;

  const integration = $site.integrations.b46ea636ea03fc98fe6dc4ec0567dbbe
  const pixelID = integration && integration.data && integration.enabled ? integration.data.pixelId : null

  if (pixelID) {
    const fb = {
      pixelId: pixelID,
      isEnabled: true,
      fbq: null,

      track(event, paramaters, extras) {
        this.fbq('track', event, paramaters, extras)
      }
    }

    /* eslint-disable */
    if (typeof window !== 'undefined') {
      ((f, b, e, v, n, t, s) => {
        if (f.fbq) return; n = f.fbq = function () {
          n.callMethod ?
            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        };
        if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = true;
        t.defer = true;
        t.src = v;
        s = b.getElementsByTagName('body')[0];
        s.parentNode.appendChild(t, s);

        fb.fbq = fbq;

        fbq('init', fb.pixelId)
        fbq('track', 'PageView')
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    }
    /* eslint-enable */

    if (app && app.router) {
      const router = app.router
      router.afterEach(({ path }) => {
        fb.track('PageView')
      })
    }

    inject('fb', fb)
  }
}

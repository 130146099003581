<template>
  <div
    class="group flex items-center w-full transition duration-100 ease-in-out border rounded focus-within:ring-2 focus-within:ring-blue-500 focus-within:outline-none focus-within:ring-opacity-50"
    :class="classes"
  >
    <div class="flex items-center w-full h-full">
      <textarea
        v-model="_value"
        class="appearance-none bg-transparent w-full outline-none p-3"
        :placeholder="placeholder"
        :disabled="disabled"
        rows="3"
      />
    </div>
  </div>
</template>

<script>
import Input from '@/mixins/input.js'

export default {
  name: 'BTextarea',
  mixins: [Input],
  props: {
    value: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      sizes: {
        sm: 'text-xs',
        md: 'text-sm',
        lg: 'text-sm'
      }
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  }
}
</script>

import Vue from 'vue'
import Utils from '@/utils'

export default (context, inject) => {
  const site = {
    events: new Vue(),
    get location() {
      if (!context.store.getters['location/location']) return null

      const location = { ...context.store.getters['location/location'] }

      location.mapUrl = ({ size, zoom, scale = 2 } = {}) => {
        scale = (scale > 1) ? 2 : 1

        let url = ((process.env.DEPLOY_CDN) ? 'https://' + process.env.DEPLOY_CDN : '') + '/api/location/mapTile?site.location=' + location.id

        if (size) {
          url += '&size=' + size
        }
        if (zoom) {
          url += '&zoom=' + zoom
        }
        if (scale) {
          url += '&scale=' + scale
        }
        
        if (this.colors.map.style) {
          if (this.colors.map.style === 'dark') {
            url += '&style=dark'
          } else if (this.colors.map.style === 'light') {
            url += '&style=light'
          } else {
            url += '&style=default'
          }
        }
        if (location.modified) {
          url += '&ts=' + location.modified
        }

        return url
      }

      return location
    },
    get images() {
      if (!context.store.getters['location/images']) return null

      const images = { ...context.store.getters['location/images'] }

      images.heroImageCssUrl = (size) => {
        if (images.hero) {
          return 'url(\'' + images.hero[size] + '\')'
        }
        return 'none'
      }

      images.logoImageCssUrl = (size) => {
        if (images.logo) {
          return 'url(\'' + images.logo[size] + '\')'
        }
        return 'none'
      }

      return images
    },
    get settings() {
      return context.store.getters['location/settings']
    },
    get integrations() {
      return context.store.getters['location/integrations']
    },
    get menu() {
      return context.store.getters['location/menu']
    },
    get customPages() {
      return context.store.getters['location/customPages']
    },
    get domain() {
      return context.store.getters['location/domain']
    },
    get tabs() {
      if (!context.store.getters['location/tabs']) return null

      const tabs = { ...context.store.getters['location/tabs'] }

      tabs.isEmpty = (Object.keys(tabs).length === 0)

      tabs.getName = (tab, defaultName) => {
        if (tabs[tab]) {
          return tabs[tab].name
        }

        return defaultName || null
      }

      tabs.getInfo = (tab) => {
        if (tabs[tab]) {
          return tabs[tab].information?.trim()
        }

        return null
      }

      tabs.getGiftCardImage = (image) => {
        if (tabs.giftCard && tabs.giftCard.images) {
          for (let i = 0; i < tabs.giftCard.images.length; i++) {
            const giftCardImage = tabs.giftCard.images[i]
            if (giftCardImage.id == image) {
              return giftCardImage
            }
          }
        }

        return null
      }

      return tabs
    },
    get onlineBookingEnabled() {
      return (this.tabs.book || this.tabs.timetable || this.tabs.lastMinute)
    },
    get host() {
      if (context.req) {
        return context.req.headers.host
      } else if (window.location) {
        return window.location.hostname
      }
      return null
    },
    get slug() {
      const reqDomain = this.host

      if (context.$config.domain && reqDomain.includes(context.$config.domain)) {
        const domainParts = reqDomain.split('.')

        if (domainParts.length > 2) {
          return domainParts[0]
        }
      } else {
        return reqDomain.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase()
      }
    },
    get colors() {
      const colours = context.store.getters['location/colours']

      if (colours[this.layout]) {
        return colours[this.layout]
      }

      return colours.site
    },
    get homeRoute() {
      const layout = this.layout
      if (layout === 'bot') {
        return { path: '/bot' }
      } else {
        return { path: '/' }
      }
    },
    get layout() {
      const layout = context.store.getters.layout

      if (layout === 'bot') {
        return 'bot'
      } else if (layout === 'widget') {
        return 'widget'
      } else if (layout === 'facebook') {
        return 'facebook'
      } else {
        return 'site'
      }
    },
    get isEmbed() {
      return Utils.browser.inIframe() && ['widget', 'bot'].includes(context.store.getters.layout)
    },
    get isGoogleAnalyticsEnabled() {
      return this.integrations['1d8fbf495ebabf8c5cad1a8c2bf97662']?.enabled && this.integrations['1d8fbf495ebabf8c5cad1a8c2bf97662']?.data?.trackingID
    }
  }

  if (process.client && Utils.browser.inIframe()) {
    const layout = context.store.getters.layout

    if (!layout && window.parent) {
      const PostRobot = require('post-robot')
      PostRobot.send(window.parent, 'bookapp.layout.get').then((event) => {
        if (event.data && event.data.layout) {
          context.store.dispatch('setLayout', event.data.layout)
        }
      }).catch(function (err) {
        console.log(err)
        context.store.dispatch('setLayout', 'site')
      })
    }
  }

  inject('site', site)
}

<template>
  <div
    class="relative group flex items-center w-full transition duration-100 ease-in-out border rounded focus-within:ring-2 focus-within:ring-blue-500 focus-within:outline-none focus-within:ring-opacity-50"
    :class="classes"
  >
    <div class="flex items-center w-full h-full">
      <slot name="leading" />

      <select
        v-model="_value"
        :disabled="disabled"
        class="absolute left-0 appearance-none h-full bg-transparent w-full outline-none"
        :class="classes"
      >
        <option v-for="(option, key) in options" :key="key" :value="key" class="bg-primary">
          {{ option }}
        </option>
      </select>
    </div>

    <slot name="trailing">
      <b-icon icon="chevronDown" />
    </slot>
  </div>
</template>

<script>
import Input from '@/mixins/input.js'

export default {
  name: 'BSelectInput',
  mixins: [Input],
  props: {
    value: {
      type: String,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  }
}
</script>

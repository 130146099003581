<template>
  <div class="p-4 mb-4 text-sm rounded border" :class="classes">
    <div class="flex items-start w-full">
      <b-icon v-if="icon" :icon="icon" class="flex-shrink-0 mr-3" />
      <slot name="title">
        <span v-if="$slots.default && title" class="font-medium">
          {{ title }}
        </span>
      </slot>
      <span v-if="$slots.default && !title" class="font-medium w-full">
        <slot />
      </span>
    </div>
    <div v-if="$slots.default && title" class="mt-2 opacity-80">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BAlert',
  props: {
    title: {
      type: String,
      default: null
    },
    variant: {
      type: String,
      default: 'base'
    },
    icon: {
      type: [String, Boolean],
      default: 'exclamationCircle'
    },
    truncate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      variants: {
        base: 'bg-primary text-secondary',
        success: 'text-white bg-emerald-600',
        danger: 'text-white bg-red-600 border border-red-700',
        info: 'text-white bg-blue-600 border border-blue-800'
      }
    }
  },
  computed: {
    classes () {
      return this.variants[this.variant] || this.variants.base
    }
  }
}
</script>

<template>
  <button class="text-left w-full hover:bg-primary/20 focus:bg-primary/20 block p-3 text-secondary bg-transparent hover:bg-primary transition-all cursor-pointer" @click="handleClick">
    <slot />
  </button>
</template>

<script>
export default {
  name: 'BDropdownItem',
  inject: ['dropdown'],
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick ($event) {
      if (!this.disabled) {
        this.$emit('click', $event)

        if (this.dropdown.auto) {
          this.dropdown.close()
        }
      }
    }
  }
}
</script>

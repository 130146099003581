const middleware = {}

middleware['applePay'] = require('../middleware/applePay.js')
middleware['applePay'] = middleware['applePay'].default || middleware['applePay']

middleware['custom'] = require('../middleware/custom.js')
middleware['custom'] = middleware['custom'].default || middleware['custom']

middleware['ie11'] = require('../middleware/ie11.js')
middleware['ie11'] = middleware['ie11'].default || middleware['ie11']

middleware['layout'] = require('../middleware/layout.js')
middleware['layout'] = middleware['layout'].default || middleware['layout']

middleware['location'] = require('../middleware/location.js')
middleware['location'] = middleware['location'].default || middleware['location']

middleware['redirects'] = require('../middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['robots'] = require('../middleware/robots.js')
middleware['robots'] = middleware['robots'].default || middleware['robots']

middleware['sitemap'] = require('../middleware/sitemap.js')
middleware['sitemap'] = middleware['sitemap'].default || middleware['sitemap']

export default middleware

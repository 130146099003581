<template>
  <div
    class="group flex items-center w-full transition duration-100 ease-in-out border rounded focus-within:ring-2 focus-within:ring-blue-500 focus-within:outline-none focus-within:ring-opacity-50"
    :class="classes"
  >
    <label class="flex items-center w-full h-full">
      <slot name="leading" />
      <input
        v-model="_value"
        class="appearance-none h-full bg-transparent w-full outline-none"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
        :autocomplete="autocomplete"
        v-on="handlers"
      >
    </label>

    <slot name="trailing" />
  </div>
</template>

<script>
import Input from '@/mixins/input.js'

export default {
  name: 'BTextInput',
  mixins: [Input],
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    id: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: null
    },
    autocomplete: {
      type: String,
      default: null
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  }
}
</script>

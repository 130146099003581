<template>
  <div
    class="sc-chat-window bg-secondary relative"
    :class="{opened: isOpen, closed: !isOpen, mobile: isMobile }"
    :style="{
      bottom: placement === 'left' || placement === 'right' ? '100px' : 'auto',
      top: placement === 'left' || placement === 'right' ? 'auto' : '100px'
    }"
  >
    <slot />

    <a target="_parent" class="m-3 flex items-center space-x-3  rtl:space-x-reverse text-xs text-secondary" :href="`https://www.ovatu.com?source=bookapp-bot&business=${$site.location.slug}`">
      <img src="~/assets/icons/o2v2.png" class="h-4 w-4 mr-3"> by Ovatu
    </a>
  </div>
</template>

<script>
export default {
  props: {
    onClose: {
      type: Function,
      required: true
    },
    isOpen: {
      type: Boolean,
      default: () => false
    },
    colors: {
      type: Object,
      required: true
    },
    blindHeight: {
      type: Number,
      default: 80
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'right'
    }
  }
}
</script>

<style scoped>
.sc-chat-window {
  width: 370px;
  height: calc(100% - 120px);
  /*max-height: 590px;*/
  position: fixed;
  right: 25px;
  box-sizing: border-box;
  box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  border-radius: 10px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: var(--bookapp-content-tint2-color) !important;
}

.sc-chat-window.closed {
  opacity: 0;
  visibility: hidden;
  bottom: 90px;
}

.sc-message--me {
  text-align: right;
}
.sc-message--them {
  text-align: left;
}

.sc-chat-window.mobile {
  width: 100%;
  height: 100%;
  right: 0;
  top: 0 !important;
  z-index: 1;
  border-radius: 0px;
}

.sc-chat-window.mobile {
  transition: 0.1s ease-in-out;
}

.sc-chat-window.closed.mobile {
  bottom: 0px;
}
</style>

<template>
  <div id="main">
    <section class="container">
      <BotWindow
        ref="bot"
        :is-open="visible"
        :colors="$site.colors"
        :on-close="close"
        :is-mobile="mobile"
        :placement="placement"
      >
        <div class="sc-route relative">
          <nuxt />

          <client-only>
            <cookie v-if="$site.settings.showCookieConsent" />
          </client-only>
        </div>
      </BotWindow>

      <Launcher :open="open" :close="close" :placement="placement" :is-open="visible" :colors="$site.colors" />
    </section>
  </div>
</template>

<script>
import Cookie from './elements/Cookie.vue'
import BotWindow from '@/components/bot/BotWindow.vue'
import Launcher from '@/components/bot/Launcher.vue'
import Utils from '@/utils'

export default {
  components: {
    BotWindow,
    Launcher,
    Cookie
  },
  data () {
    return {
      visible: false,
      didCloseTimer: null,
      didOpenTimer: null,
      mobile: false
    }
  },
  head: {
    htmlAttrs: {
      class: 'layout-bot'
    }
  },
  computed: {
    placement () {
      return this.$store.getters.botPlacement
    }
  },
  created () {
    this.$router.afterEach((to, from) => {
      if (Utils.browser.inIframe() && window.parent) {
        const PostRobot = require('post-robot')
        PostRobot.send(window.parent, 'bookapp.event.url-changed', to.fullPath)
        if (to && from && to.path !== from.path) {
          PostRobot.send(window.parent, 'bookapp.event.page-changed', to.fullPath)
        }
      }
    })
  },
  mounted () {
    const that = this

    this.$site.events.$on('_bookapp.bot.close', () => {
      that.close()
    })

    const PostRobot = require('post-robot')

    PostRobot.on('bookapp.bot.open', { window: window.parent }, function (event) {
      that.open()
    })

    PostRobot.on('bookapp.bot.close', { window: window.parent }, function (event) {
      that.close()
    })

    PostRobot.on('bookapp.bot.reset', { window: window.parent }, function (event) {
      that.$router.push({ path: '/bot' })
    })

    PostRobot.on('bookapp.bot.resize', { window: window.parent }, function (event) {
      if (event.data.width && event.data.width < 450) {
        that.mobile = true
      } else {
        that.mobile = false
      }

      return {
        mobile: that.mobile
      }
    })

    if (this.$site.isEmbed && window.parent) {
      PostRobot.send(window.parent, 'bookapp.bot.event.ready')
    } else {
      this.$router.replace('/')
    }
  },
  methods: {
    open () {
      clearTimeout(this.didCloseTimer)

      this.$auth.strategies.location.fetchUser()

      if (window.parent) {
        const PostRobot = require('post-robot')
        PostRobot.send(window.parent, 'bookapp.bot.event.willOpen').then((event) => {
          this.visible = true

          this.didOpenTimer = setTimeout(() => {
            PostRobot.send(window.parent, 'bookapp.bot.event.didOpen').then((event) => {

            })
          }, 300)
        })
      }
    },
    close () {
      clearTimeout(this.didOpenTimer)

      if (window.parent) {
        const PostRobot = require('post-robot')
        PostRobot.send(window.parent, 'bookapp.bot.event.willClose').then((event) => {
          this.visible = false

          this.didCloseTimer = setTimeout(() => {
            PostRobot.send(window.parent, 'bookapp.bot.event.didClose').then((event) => {

            })
          }, 300)
        })
      }
    }
  }
}
</script>

<style>
html.layout-bot #__layout {
    overflow-x: hidden;
}

.slide-left-leave-active {
  transition: 0.2s;
}
.slide-left-enter-active {
  transition: 0.3s;
}
.slide-left-enter {
  transform: translate(20%, 0);
  opacity: 0
}

.slide-left-enter, .slide-left-enter-active {
}

.slide-left-leave, .slide-left-leave-active {
}

.slide-left-leave-to {
  transform: translate(-20%, 0);
  opacity: 0
}

.slide-left-enter-to {
  opacity: 1
}

.slide-right-leave-active
{
  transition: 0.2s;
}
.slide-right-enter-active
{
  transition: 0.3s;
}
.slide-right-enter {
  transform: translate(-20%, 0);
  opacity: 0
}

.slide-right-enter, .slide-right-enter-active {
}

.slide-right-leave, .slide-right-leave-active {
}

.slide-right-leave-to {
  transform: translate(20%, 0);
  opacity: 0
}

.slide-right-enter-to {
  opacity: 1
}

html.layout-bot body {
  background-color: transparent !important;
}

.sc-route {
  overflow-x: hidden;
  height: 100%;
}
</style>

<template>
  <component
    :is="componentToRender"
    :id="id"
    class="inline-flex items-center justify-center rounded text-center font-medium cursor-pointer hover:shadow-lg focus:outline-none focus:shadow-outline transition-all"
    :href="href"
    :type="type"
    :class="classes"
    :name="name"
    :disabled="disabled"
    :to="to"
    v-on="$listeners"
  >
    <slot v-if="!loading">
      <b-icon v-if="icon" :icon="icon" class="h-4 w-4" />
    </slot>

    <b-spinner v-if="loading" />
  </component>
</template>

<script>
import componentType from '~/mixins/type'

export default {
  name: 'BButton',
  mixins: [componentType],
  props: {
    href: {
      required: false,
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'button'
    },
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    variant: {
      type: String,
      default: 'base'
    },
    size: {
      type: String,
      default: 'md'
    },
    icon: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    to: {
      type: [String, Object],
      default: undefined
    },
  },
  data () {
    return {
      variants: {
        action: {
          base: 'bg-action text-action hover:bg-action/90 focus:bg-action/90 focus:outline-2 focus:outline-offset-2 focus:outline-action',
          flat: 'bg-action/20 text-primary hover:bg-action focus:bg-action hover:text-action'
        },
        outline: 'border text-secondary',
        flat: 'bg-secondary text-secondary opacity-80',
        base: {
          base: 'bg-primary text-secondary hover:bg-action focus:bg-action hover:text-action',
          flat: 'bg-secondary text-secondary hover:bg-action focus:bg-action hover:text-action',
        },
        danger: 'bg-red-600 text-white hover:bg-red-700 border border-red-700',
        success: 'bg-green-800 text-green-400 hover:bg-green-700 border border-green-700',
        translucent: 'bg-gray-100 text-gray-600  hover:bg-gray-300'
      },
      sizes: {
        sm: 'h-8 text-xs px-2',
        md: 'h-10 text-sm px-4',
        lg: 'h-12 px-6'
      }
    }
  },
  computed: {
    classes () {
      const variant = this.variant
      const variants = this.variants

      const classes = []

      if (this.flat) {
        classes.push(variants[variant]?.flat || variants[variant] || variants.base || '')
      } else {
        classes.push(variants[variant]?.base || variants[variant] || variants.base || '')
      }

      const size = this.size
      const sizes = this.sizes

      classes.push(sizes[size] || sizes.md)

      if (this.disabled) {
        classes.push('opacity-50 cursor-not-allowed')
      }

      return classes
    }
  },
}
</script>

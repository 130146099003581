export default class BookAppScheme {
  constructor(auth, options) {
    this.$auth = auth
    this.name = options._name

    this.options = Object.assign({}, DEFAULTS, options)
  }

  mounted() {
    this.$auth.syncToken(this.name)

    return this.$auth.fetchUserOnce()
  }

  async login({ email, password }) {
    // Ditch any leftover local tokens before attempting to log in
    await this.logout()

    const result = await this.$auth.ctx.app.$api.auth.connectLogin(email, password)

    if (result.token) {
      this.$auth.setToken(this.name, result.token)
      return this.fetchUser()
    } else {
      return Promise.reject(result.errors)
    }
  }

  getUserToken() {
    return this.$auth.getToken(this.name)
  }

  async setUserToken(tokenValue) {
    // Ditch any leftover local tokens before attempting to log in
    await this.logout()

    this.$auth.setToken(this.name, tokenValue)

    return this.fetchUser()
  }

  async fetchUser() {
    // Token is required but not available
    if (!this.$auth.getToken(this.name)) {
      return
    }

    // Try to fetch user and then set
    const user = await this.$auth.ctx.app.$api.auth.user()
    this.$auth.setUser(user.customer)
  }

  logout() {
    // But logout locally regardless
    this.$auth.setToken(this.name, false)

    return this.reset()
  }

  reset () {
    this.$auth.setUser(false)
    this.$auth.setToken(this.name, false)
    return Promise.resolve()
  }
}

const DEFAULTS = {
  tokenRequired: true,
  globalToken: false
}

<template>
  <b-dropdown ref="dropdown" class="mt-2" placement="top">
    <b-dropdown-field class="w-full bg-primary">
      <div class="w-full flex items-center space-x-2 rtl:space-x-reverse">
        <div class="text-xl">
          {{ current.icon }}
        </div>
        <p>
          {{ current.name }}
        </p>
      </div>
    </b-dropdown-field>

    <template #content>
      <div class="w-full max-h-[400px] overflow-y-auto scroll">
        <b-dropdown-item v-for="(locale, key) in locales" :key="key" @click="select(locale.code)">
          <div class="w-full flex items-center space-x-2 rtl:space-x-reverse">
            <div class="text-xl">
              {{ locale.icon }}
            </div>
            <p>
              {{ locale.name }}
            </p>
          </div>
        </b-dropdown-item>
      </div>
    </template>
  </b-dropdown>
</template>

<script>
export default {
  data () {
    return {
      locales: this.$i18n.locales,
      env: process.env.NODE_ENV
    }
  },
  computed: {
    current () {
      return this.locales.find((locale) => {
        return locale.code === this.$i18n.locale
      })
    }
  },
  methods: {
    select (lang) {
      this.$i18n.setLocale(lang)

      this.$refs.dropdown?.close()
    }
  }
}

</script>

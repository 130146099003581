<template>
  <div
    class="sm:flex items-start sm:space-x-12"
    :class="[
      reverse ? 'flex-row-reverse sm:space-x-reverse' : 'rtl:space-x-reverse',
      $slots.summary ? '' : 'justify-items-center'
    ]"
  >
    <div
      class="w-full sm:w-2/3"
      :class="[
        $slots.summary ? '' : 'mx-auto'
      ]"
    >
      <slot />
    </div>
    <div v-if="$slots.summary" class="w-full sm:w-1/3 mb-6 sm:mb-0 sm:sticky top-0">
      <slot name="summary" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BSummary',
  props: {
    reverse: {
      type: Boolean,
      default: false
    }
  }
}
</script>

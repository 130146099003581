<template>
  <b-alert show variant="danger" class="w-auto" :class="{ 'inline-block' : !full }">
    <h5 class="alert-heading">
      {{ title || $t('general.errorTitle') }}
    </h5>
    <p v-if="name">
      {{ $t('general.errorMessageName', { name: name }) }}
    </p>
    <p v-else>
      {{ message || $t('general.errorMessage') }}
    </p>
    <client-only>
      <template v-if="refresh">
        <hr>
        <b-button class="mb-0" @click="$router.go(0)">
          {{ $t('general.refresh') }}
        </b-button>
      </template>
    </client-only>
  </b-alert>
</template>

<script>
export default {
  name: 'BError',
  props: {
    name: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    message: {
      type: String,
      default: null
    },
    refresh: {
      type: Boolean,
      default: true
    },
    full: {
      type: Boolean,
      default: true
    }
  }
}
</script>

export const state = () => ({
  type: null,
  toName: null,
  toEmail: null,
  fromName: null,
  message: null,
  deliveryDate: null,
  card: {
    amount: null,
    image: null
  },
  pass: null,
  customer: null
})

export const mutations = {
  setDetails(state, { type, cardAmount, cardImage, pass, toName, toEmail, fromName, message, deliveryDate }) {
    state.type = type
    state.card.amount = cardAmount
    state.card.image = cardImage
    state.pass = pass
    state.toName = toName
    state.toEmail = toEmail
    state.fromName = fromName
    state.message = message
    state.deliveryDate = deliveryDate
  },
  setCustomer(state, customer) {
    state.customer = customer
  }
}

export const actions = {
  setCardDetails(context, { amount, image, toName, toEmail, fromName, message, deliveryDate }) {
    context.commit('setDetails', { type: 'card', cardAmount: amount, cardImage: image, toName, toEmail, fromName, message, deliveryDate })
  },
  setPassDetails(context, { pass, toName, toEmail, fromName, message, deliveryDate }) {
    context.commit('setDetails', { type: 'pass', pass, toName, toEmail, fromName, message, deliveryDate })
  },
  setCustomer(context, customer) {
    context.commit('setCustomer', customer)
  },
  async setFromUrlQuery(context, query) {
    const currentQuery = context.getters.urlQuery

    if (query.gift && currentQuery.gift !== query.gift) {
      const json = Buffer.from(query.gift, 'base64').toString()
      const gift = JSON.parse(json)

      let pass = null
      if (gift.pass && gift.pass.id) {
        if (context.state.pass && context.state.pass.id === gift.pass.id) {
          pass = context.state.pass
        } else {
          const passResult = await this.$api.pass.pass(gift.pass.id)
          pass = passResult.pass
        }
      }

      context.commit('setDetails', {
        type: gift.type,
        cardAmount: (gift.card) ? gift.card.amount : null,
        cardImage: (gift.card) ? gift.card.image : null,
        pass,
        toName: gift.to.name,
        toEmail: gift.to.email,
        fromName: gift.from.name,
        message: gift.message,
        deliveryDate: (gift.deliveryDate) ? new Date(gift.deliveryDate) : null
      })
    }

    if (query.customer && currentQuery.customer !== query.customer) {
      const json = Buffer.from(query.customer, 'base64').toString()
      const customer = JSON.parse(json)

      context.commit('setCustomer', customer)
    }
  }
}

export const getters = {
  type(state) {
    return state.type
  },
  pass(state) {
    return state.pass
  },
  card(state) {
    return state.card
  },
  to(state) {
    return {
      name: state.toName,
      email: state.toEmail
    }
  },
  from(state) {
    return {
      name: state.fromName
    }
  },
  deliveryDate(state) {
    return state.deliveryDate
  },
  message(state) {
    return state.message
  },
  customer(state) {
    return state.customer
  },
  urlQuery(state) {
    const query = {}

    const gift = {
      type: state.type,
      to: { name: state.toName, email: state.toEmail },
      from: { name: state.fromName },
      message: state.message,
      deliveryDate: (state.deliveryDate) ? state.deliveryDate.getTime() : null
    }

    if (state.pass && state.pass.id) {
      gift.pass = { id: state.pass.id }
    }
    if (state.card) {
      gift.card = {}

      if (state.card.amount) {
        gift.card.amount = state.card.amount
      }
      if (state.card.image) {
        gift.card.image = state.card.image
      }
    }

    query.gift = Buffer.from(JSON.stringify(gift)).toString('base64')

    if (state.customer) {
      query.customer = Buffer.from(JSON.stringify(state.customer)).toString('base64')
    }

    return query
  }
}

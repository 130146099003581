<template>
  <div v-if="moveReservation" class="mb-6">
    <div class="flex items-center justify-between mb-1">
      <h6 class="font-semibold text-xs uppercase text-secondary/60">
        {{ $t('components.moveReservationDetail.rescheduling') }}
      </h6>

      <a class="text-secondary" @click="reset">
        <b-icon icon="x" base-class="h-4 w-4" />
      </a>
    </div>

    <div>
      <div class="flex items-start justify-between text-sm text-secondary">
        <p>
          {{ moveReservation.customerInfoLabel }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      moveReservation: null
    }
  },
  created () {
    this.moveReservation = this.$store.getters['booking/moveReservation']
  },
  methods: {
    reset () {
      this.$store.dispatch('booking/clear')

      this.$router.push({ path: '/book-now' })
    }
  }
}
</script>

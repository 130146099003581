export default {
  validation: {
    email(email) {
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    }
  },

  text: {
    formatHtml(text) {
      // eslint-disable-next-line
      if (text === undefined || text === null) {
        return ''
      }

      /* const tagsToReplace = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;'
      }

      text = text.replace(/[&<>]/g, tag => tagsToReplace[tag] || tag) */

      const breakTag = '<br>'
      return (text + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
    },

    formatTime(date, location) {
      const timeFormatOptions = {
        hour: 'numeric',
        minute: '2-digit'
      }

      const locale = location.locale.replace('_', '-')

      return date.toLocaleTimeString(locale, timeFormatOptions)
    },

    formatMinuteTime(time, location) {
      const hours = parseInt(time / 60)
      const minutes = time - (hours * 60)
      const date = new Date(Date.UTC(1970, 0, 1, hours, minutes, 0))
      const timeFormatOptions = {
        hour: 'numeric',
        minute: '2-digit',
        timeZone: 'UTC'
      }
      const locale = location.locale.replace('_', '-')
      return date.toLocaleTimeString(locale, timeFormatOptions)
    },

    stripPhoneNumber(text) {
      return text.replace(/[^\d]/g, '')
    }
  },

  date: {
    fromMinuteTime(time) {
      const hours = parseInt(time / 60)
      const minutes = time - (hours * 60)

      return new Date(2000, 0, 1, hours, minutes)
    }
  },

  browser: {
    inIframe() {
      try {
        return window.self !== window.top
      } catch (e) {
        return true
      }
    }
  }
}

<template>
  <!--<div
    class="group flex items-center w-full transition duration-100 ease-in-out border rounded focus-within:ring-2 focus-within:ring-blue-500 focus-within:outline-none focus-within:ring-opacity-50"
    :class="classes"
  >
    <div class="flex items-center w-full h-full">
      <slot name="leading" />
      <client-only>
        <v-date-picker
          :value="sanitizedValue"
          :popover="{ visibility: (!disabled) ? 'click' : 'hidden' }"
          :show-popover="!disabled"
          :theme="{ bgAccentHigh: { light: 'bookapp-vc-bg-accent-high' } }"
          :min-date="minDate"
          :input-props="{ class: 'form-control', disabled, placeholder }"
          :locale="locale"
          @input="change"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="appearance-none h-full bg-transparent w-full outline-none bg-blue-500"
              :value="inputValue"
              :disabled="disabled"
              :placeholder="placeholder"
              v-on="inputEvents"
            >
          </template>
        </v-date-picker>
      </client-only>
    </div>

    <slot name="trailing">
      <b-icon icon="calendar" />
    </slot>
  </div>-->

  <client-only>
    <v-date-picker
      :value="sanitizedValue"
      :popover="{ visibility: (!disabled) ? 'click' : 'hidden' }"
      :show-popover="!disabled"
      :theme="{ bgAccentHigh: { light: 'bookapp-vc-bg-accent-high' } }"
      :min-date="minDate"
      :input-props="{ class: 'form-control', disabled, placeholder }"
      :locale="locale"
      @input="change"
    >
      <template v-slot="{ inputValue, inputEvents }">
        <div
          class="group flex items-center w-full transition duration-100 ease-in-out border rounded focus-within:ring-2 focus-within:ring-blue-500 focus-within:outline-none focus-within:ring-opacity-50"
          :class="classes"
          @click="inputEvents.click"
        >
          <slot name="leading" />
          
          <input
            class="appearance-none h-full bg-transparent w-full outline-none"
            :value="inputValue"
            :disabled="disabled"
            :placeholder="placeholder"
            v-on="inputEvents"
          >

          <slot name="trailing">
            <b-icon icon="calendar" />
          </slot>
        </div>
      </template>
    </v-date-picker>
  </client-only>
</template>

<script>
import Input from '@/mixins/input.js'

export const augmenters = {
  YMD: {
    input: (value) => {
      if (value && !(value instanceof Date)) {
        if (value.length == 8) {
          const year = value.substring(0, 4)
          const month = value.substring(4, 6)
          const day = value.substring(6, 8)

          return new Date(year, month - 1, day, 0, 0, 0, 0)
        }
      }
      return value
    },
    output: (value) => {
      if (value && value instanceof Date) {
        const zeroPad = (num) => {
          if (num < 10) return '0' + num.toString()
          return num.toString()
        }

        return value.getFullYear().toString() + zeroPad(value.getMonth() + 1) + zeroPad(value.getDate())
      }
      return value
    }
  },
  timestamp: {
    input: (value) => {
      if (value && !(value instanceof Date)) return new Date(value * 1000)
      return value
    },
    output: (value) => {
      if (value && value instanceof Date) {
        const offset = value.getTimezoneOffset() * 60000
        const date = value.getTime() - offset
        return parseInt(date / 1000)
      }
      return value
    }
  }
}

export default {
  name: 'BDateInput',
  mixins: [Input],
  props: {
    value: {
      type: [String, Date, Number],
      default: null
    },
    minDate: {
      type: Date,
      required: false,
      default: null
    },
    augmenter: {
      type: Object,
      required: false,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    }
  },
  computed: {
    sanitizedValue() {
      if (this.value === '') return null

      if (this.augmenter && this.augmenter.input) {
        return this.augmenter.input(this.value)
      } else {
        return this.value
      }
    },
    locale() {
      const locale = this.$site.location.locale
      return locale.replace('_', '-')
    }
  },
  methods: {
    augment(value) {
      if (this.augmenter && this.augmenter.output) {
        return this.augmenter.output(value)
      } else {
        return value
      }
    },
    change(value) {
      this.$emit('input', this.augment(value))
    }
  }
}
</script>

<style>
.bookapp-vc-bg-accent-high {
  background-color: var(--bookapp-action-tint-color)
}
</style>

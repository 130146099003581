const createServerApi = require('../api/index.js').createServerApi

export const createMiddleware = (config) => {
  return async (req, res, next) => {
    const api = createServerApi(req, config)

    try {
      const parsedUrl = new URL(req.url, 'http://localhost/')
      let domain = parsedUrl.searchParams.get('domain')
      const verify = parsedUrl.searchParams.get('verify')

      if (domain === null) {
        domain = req.headers.host
      }

      const customDomain = await api.location.customDomain({ domain, verify })

      if (verify === null && customDomain.status === 'active') {
        res.writeHead(200, { 'Content-Type': 'text/plain' })
        res.end('ACTIVE')
      } else if (verify !== null && customDomain.verification != null) {
        res.writeHead(200, { 'Content-Type': 'text/plain' })
        res.end(JSON.stringify({
          domain,
          verification: customDomain.verification
        }))
      } else {
        res.writeHead(404, { 'Content-Type': 'text/plain' })
        res.end('INACTIVE')
      }
    } catch (error) {
      console.log(error)
      res.writeHead(500, { 'Content-Type': 'text/plain' })
      res.end('error')
    }
  }
}

<template>
  <div class="max-w-6xl mx-auto px-4 relative">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'BContainer'
}
</script>
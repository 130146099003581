import Vue from 'vue'

// Components
import Container from '@/components/elements/Container.vue'
import Button from '@/components/elements/Button.vue'
import Alert from '@/components/elements/Alert.vue'
import Spinner from '@/components/elements/Spinner.vue'
import Chip from '@/components/elements/Chip.vue'
import Icon from '@/components/elements/Icon.vue'
import PageTitle from '@/components/elements/PageTitle.vue'
import Breadcrumbs from '@/components/elements/Breadcrumbs.vue'
import Layout from '@/components/elements/Layout.vue'
import Summary from '@/components/elements/Summary.vue'
import Card from '@/components/elements/Card.vue'
import Collapse from '@/components/elements/Collapse.vue'
import Dropdown from '@/components/elements/Dropdown.vue'
import DropdownField from '@/components/elements/DropdownField.vue'
import DropdownItem from '@/components/elements/DropdownItem.vue'
import Avatar from '@/components/elements/Avatar.vue'
import InputGroup from '@/components/elements/InputGroup.vue'
import Tooltip from '@/components/elements/Tooltip.vue'
import Html from '@/components/elements/Html.vue'
import Errors from '@/components/elements/Errors.vue'
import Error from '@/components/elements/Error.vue'
import Tabs from '@/components/elements/tabs/Tabs.vue'
import Tab from '@/components/elements/tabs/Tab.vue'
import Pager from '@/components/elements/Pager.vue'
import Dialog from '@/components/elements/Dialog.vue'
import Truncate from '@/components/elements/Truncate.vue'
import Address from '@/components/elements/Address.vue'

// Fields
import Text from '@/components/fields/Text.vue'
import Date from '@/components/fields/Date.vue'
import Checkbox from '@/components/fields/Checkbox.vue'
import List from '@/components/fields/List.vue'
import Number from '@/components/fields/Number.vue'
import Signature from '@/components/fields/Signature.vue'
import TextArea from '@/components/fields/TextArea.vue'
import Toggle from '@/components/fields/Toggle.vue'
import Select from '@/components/fields/Select.vue'

// Blocks
import HeroBlock from '@/components/pages/blocks/Hero'
import ServicesBlock from '@/components/pages/blocks/Services'
import EmployeesBlock from '@/components/pages/blocks/Employees'

Vue.component(Container.name, Container)
Vue.component(Button.name, Button)
Vue.component(Alert.name, Alert)
Vue.component(Spinner.name, Spinner)
Vue.component(Chip.name, Chip)
Vue.component(Icon.name, Icon)
Vue.component(PageTitle.name, PageTitle)
Vue.component(Breadcrumbs.name, Breadcrumbs)
Vue.component(Layout.name, Layout)
Vue.component(Summary.name, Summary)
Vue.component(Card.name, Card)
Vue.component(Collapse.name, Collapse)
Vue.component(Dropdown.name, Dropdown)
Vue.component(DropdownField.name, DropdownField)
Vue.component(DropdownItem.name, DropdownItem)
Vue.component(Avatar.name, Avatar)
Vue.component(InputGroup.name, InputGroup)
Vue.component(Tooltip.name, Tooltip)
Vue.component(Html.name, Html)
Vue.component(Errors.name, Errors)
Vue.component(Error.name, Error)
Vue.component(Tabs.name, Tabs)
Vue.component(Tab.name, Tab)
Vue.component(Pager.name, Pager)
Vue.component(Dialog.name, Dialog)
Vue.component(Truncate.name, Truncate)
Vue.component(Address.name, Address)

Vue.component(Text.name, Text)
Vue.component(Date.name, Date)
Vue.component(Checkbox.name, Checkbox)
Vue.component(List.name, List)
Vue.component(Number.name, Number)
Vue.component(Signature.name, Signature)
Vue.component(TextArea.name, TextArea)
Vue.component(Toggle.name, Toggle)
Vue.component(Select.name, Select)

Vue.component(HeroBlock.name, HeroBlock)
Vue.component(ServicesBlock.name, ServicesBlock)
Vue.component(EmployeesBlock.name, EmployeesBlock)

const createServerApi = require('../api/index.js').createServerApi

const addUrl = (root, url, { lastmod, changefreq, priority } = {}) => {
  const item = root.ele('url')
  item.ele('loc', url)
  if (lastmod) {
    item.ele('lastmod', lastmod)
  }
  if (changefreq) {
    item.ele('changefreq', changefreq)
  }
  if (priority) {
    item.ele('priority', priority)
  }
}

export const createMiddleware = (config) => {
  return async function (req, res, next) {
    const api = createServerApi(req, config)
    const isHTTPs = require('is-https')

    try {
      const info = await api.location.info()

      const builder = require('xmlbuilder')
      const root = builder.create('urlset', { encoding: 'utf-8' }).att('xmlns', 'http://www.sitemaps.org/schemas/sitemap/0.9')

      const domain = req.headers.host
      const baseUrl = (isHTTPs(req) ? 'https' : 'http') + '://' + domain

      addUrl(root, `${baseUrl}/`, { changefreq: 'always' })
      
      if (info.success) {
        if (info.tabs.book) {
          addUrl(root, `${baseUrl}/book-now`, { changefreq: 'always', priority: '1.0' })
        }
        if (info.tabs.timetable) {
          addUrl(root, `${baseUrl}/timetable`, { changefreq: 'always', priority: '1.0' })
        }
        if (info.tabs.lastMinute) {
          addUrl(root, `${baseUrl}/last-minute`, { changefreq: 'always', priority: '1.0' })
        }
        if (info.tabs.giftCard) {
          addUrl(root, `${baseUrl}/gift`, { changefreq: 'daily', priority: '1.0' })
          addUrl(root, `${baseUrl}/gift/card`, { changefreq: 'daily', priority: '1.0' })
        }
        if (info.tabs.pass) {
          const giftablePassesResponse = await api.pass.giftable()
          for (const pass of giftablePassesResponse.passes) {
            addUrl(root, `${baseUrl}/gift/pass?id=${pass.id}`, { changefreq: 'weekly' })
          }

          addUrl(root, `${baseUrl}/pass`, { changefreq: 'daily' })
        }
        if (info.tabs.gallery) {
          addUrl(root, `${baseUrl}/gallery`, { changefreq: 'weekly', priority: '1.0' })
        }
        if (info.tabs.review) {
          addUrl(root, `${baseUrl}/reviews`, { changefreq: 'daily', priority: '1.0' })
        }
        if (info.tabs.user) {
          addUrl(root, `${baseUrl}/customer`, { priority: '1.0' })
          addUrl(root, `${baseUrl}/customer/recovery`, { priority: '1.0' })
        }

        if (info.customPages) {
          for (const page of Object.values(info.customPages || {})) {
            addUrl(root, `${baseUrl}/page/${page.slug}`, { changefreq: 'weekly' })
          }
        }
      }

      const xml = root.end({ pretty: true })
      res.end(xml)
    } catch (error) {
      res.writeHead(500, { 'Content-Type': 'text/plain' })
      res.end('Uh oh, something went wrong!')
    }
  }
}
<template>
  <div>
    <transition name="fade">
      <div v-if="active" class="fixed h-screen w-screen inset-0 z-[3000] text-secondary">
        <div class="fixed h-full w-full bg-primary bg-opacity-50 backdrop-blur-sm inset-0" @click="active = false" />
      </div>
    </transition>

    <transition name="slide">
      <div v-if="active" class="fixed h-screen w-screen inset-0 z-[3000] text-secondary">
        <div class="fixed h-full w-ful inset-0" @click="active = false" />
        <div class="flex flex-col fixed right-0 top-0 h-full w-3/4 sm:max-w-[400px] bg-secondary shadow">
          <div class="flex items-center justify-between border-b p-4">
            <h3 class="font-semibold text-center">
              {{ businessName }}
            </h3>

            <button class="bg-transparent" @click="active = false">
              <b-icon icon="x" />
            </button>
          </div>

          <div class=" overflow-y-auto scroll flex flex-col justify-between flex-grow h-10">
            <ul class="flex flex-col px-2 py-3">
              <template v-if="menu">
                <li v-for="(item, key) in menu" :key="`${key}-${item.type}`">
                  <template v-if="item.type === 'group'">
                    <menu-group :item="item" />
                  </template>

                  <template v-else>
                    <menu-item :item="item" />
                  </template>
                </li>
              </template>

              <!-- Has Default (Auto) Menu -->
              <template v-else>
                <li v-for="tab in tabs" :key="tab">
                  <menu-item
                    v-if="$site.tabs[tab]"
                    :item="{
                      id: tab,
                      name: $site.tabs.getName(tab, tab),
                      information: $site.tabs.getInfo(tab)
                    }"
                  />
                </li>

                <template v-if="$site.customPages">
                  <li v-for="page in $site.customPages" :key="page.id">
                    <menu-item
                      :item="{
                        id: page.id,
                        name: page.menuTitle,
                        information: page.info,
                        type: 'custom',
                        icon: page.icon,
                        slug: page.slug
                      }"
                    />
                  </li>
                </template>
              </template>
            </ul>
            
            <ul v-if="$site.tabs.user && (!isAppLayout || isLoggedIn)" class="flex flex-col border-t px-4 py-3">
              <li>
                <menu-item
                  
                  :item="{ id: 'user', name: userTabName }"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import MenuItem from './MenuItem'
import MenuGroup from './MenuGroup'

export default {
  components: {
    MenuItem,
    MenuGroup
  },
  data () {
    return {
      tabs: [
        'book',
        'timetable',
        'lastMinute',
        'giftCard',
        'pass',
        'gallery',
        'review'
      ]
    }
  },
  computed: {
    menu () {
      if (this.$site.menu?.primary) {
        const primary = this.$site.menu?.primary || []
        const secondary = this.$site.menu?.secondary || []

        return [
          ...primary,
          ...secondary
        ]
      }

      return false
    },
    businessName () {
      if (this.$site.tabs.contact && this.$site.tabs.contact.business) {
        return this.$site.tabs.contact.business
      } else {
        return this.$site.location.title
      }
    },
    userTabName () {
      if (this.$auth.loggedIn) {
        return this.$auth.user.displayName
      } else {
        return this.$site.tabs.getName('user', 'Customer')
      }
    },
    active: {
      get () {
        return this.$store.getters.menu
      },
      set (val) {
        this.$store.commit('setMenu', val)
      }
    },
    isLoggedIn () {
      return this.$auth.loggedIn
    },
    isAppLayout () {
      return this.$store.getters.layout === 'app'
    }
  },
  watch: {
    '$route.path' () {
      this.active = false
    }
  }
}
</script>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: all .5s;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-leave-to {
   transform: translateX(100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: all .5s;
}

.fade-enter {
  opacity: 0;
}

.fade-leave-to {
   opacity: 0;
}

</style>

<template>
  <button
    class="text-xs px-2 py-1 rounded text-center font-medium cursor-pointer shadow hover:shadow-lg focus:outline-none focus:shadow-outline"
    :class="classes"
    :style="{
      backgroundColor: `#${colour}`,
    }"
  >
    <slot>
      <b-icon v-if="icon" :icon="icon" class="h-4 w-4" />
    </slot>
  </button>
</template>

<script>
export default {
  name: 'BChip',
  props: {
    variant: {
      type: String,
      default: 'base'
    },
    size: {
      type: String,
      default: 'md'
    },
    colour: {
      type: String,
      default: null
    },
  },
  data () {
    return {
      variants: {
        base: 'bg-action text-action',
        danger: 'bg-red-600 text-white',
        success: 'bg-green-600 text-white',
        warning: 'bg-yellow-600 text-white'
      }
    }
  },
  computed: {
    classes () {
      if (!this.colour) {
        const variant = this.variant
        const variants = this.variants

        const classes = []

        classes.push(variants[variant] || variants.base || '')

        return classes
      }

      return ['text-white']
    }
  }
}
</script>

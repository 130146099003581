<template>
  <div class="flex items-center p-6 justify-between">
    <div class="flex items-center space-x-4  rtl:space-x-reverse">
      <div v-if="showBack" class="text-secondary">
        <a @click="back">
          <b-icon icon="chevronLeft" class="h-10 w-10 cursor-pointer" />
        </a>
      </div>
      <div class="text-secondary text-3xl font-semibold">
        {{ title }}
      </div>
    </div>

    <div class="text-secondary">
      <a @click="close">
        <b-icon icon="x" class="h-10 w-10 cursor-pointer" />
      </a>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    subTitle: {
      type: String,
      required: false,
      default: ''
    },
    colors: {
      type: Object,
      required: true
    },
    backRoute: {
      type: Object,
      required: false,
      default: null
    },
    showBack: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    back () {
      this.$router.push(this.backRoute)
    },
    close () {
      this.$site.events.$emit('_bookapp.bot.close')
    }
  }
}
</script>

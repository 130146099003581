<template>
  <nuxt-link
    class="flex flex-col items-center"
    :class="[ isActive ? 'text-primary' : 'text-secondary opacity-75' ]"
    :to="path"
  >
    <icon :name="item.icon || icons[item.id]" :solid="isActive" class="mb-1" />
    <span class="text-center text-[0.7rem] line-clamp-1 text-clip px-px">{{ item.name || item.icon }}</span>
  </nuxt-link>
</template>

<script>
import Icon from './Icon'

export default {
  components: {
    Icon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      paths: {
        book: "/book-now",
        timetable: "/timetable",
        lastMinute: "/last-minute",
        giftCard: "/gift",
        pass: "/pass",
        gallery: "/gallery",
        review: "/reviews",
        user: "/customer"
      },
      icons: {
        book: "clock",
        timetable: "calendar-days",
        lastMinute: "bell-alert",
        giftCard: "gift",
        pass: "ticket",
        gallery: "photo",
        review: "star",
        user: "user"
      }
    }
  },
  computed: {
    path () {
      if (this.item?.type === 'custom' && this.item?.slug) {
        return `/page/${this.item.slug}`
      }

      return this.paths[this.item?.id] || '/'
    },
    isActive () {
      return this.$route.path.includes(this.to)
    }
  }
}
</script>

export default function ({ $auth }) {
  $auth.onError((error, name, endpoint) => {
    console.error(name, error)
  })

  $auth.$storage.setLocalStorage = (key, value, isJson) => {}
  $auth.$storage.getLocalStorage = (key, value, isJson) => {}

  $auth.$storage.setUniversal = (key, value, { cookie = null } = {}) => {
    // Unset null, undefined
    if (typeof value === 'undefined' || value === null) {
      return $auth.$storage.removeUniversal(key)
    }

    // Local state
    $auth.$storage.setState(key, value)

    // Cookies
    $auth.$storage.setCookie(key, value, cookie || {})

    // Local Storage
    $auth.$storage.setLocalStorage(key, value)

    return value
  }

  $auth.setToken = (strategy, token) => {
    const _options = $auth.strategies[strategy] ? $auth.strategies[strategy].options : {}
    const _key = $auth.options.token.prefix + strategy

    return $auth.$storage.setUniversal(_key, token, { cookie: _options.cookie || {} })
  }
}

<template>
    <span
      v-html="icon"
      :class="size"
      stroke="currentColor"
    />
  </template>
  
  <script>
  import icons from './icons'
  
  export default {
    props: {
      name: {
        type: String,
        default: null
      },
      size: {
        type: String,
        default: 'h-5 w-5'
      }
    },
    
  
    data () {
      return {
        icons
      }
    },
  
    computed: {
      icon () {
        const name = this.name || 'calendar-days'
        try {
          return require(`@/assets/images/icons/outline/${name}.svg?raw`)
        } catch (e) {
          return require(`@/assets/images/icons/outline/star.svg?raw`)
        }
      }
    }
  }
  </script>
  
<template>
  <v-dropdown
    ref="dropdown"
    :placement="placement"
    :auto-size="autoSize"
    :triggers="triggers"
    :arrow="false"
    :positioning-disabled="positioningDisabled"
    :auto-hide="autoHide"
    no-auto-focus
    container="#__layout"
    theme="dropdown"
  >
    <slot>
      <b-button variant="action" :loading="loading">
        {{ label }}
      </b-button>
    </slot>

    <template #popper>
      <slot name="content" />
    </template>
  </v-dropdown>
</template>

<script>
export default {
  name: 'BDropdown',
  provide () {
    return {
      dropdown: {
        open: this.open,
        close: this.close
      }
    }
  },
  props: {
    placement: {
      type: String,
      default: 'bottom-start'
    },
    autoSize: {
      type: [String, Boolean],
      default: null
    },
    label: {
      type: String,
      default: 'Select'
    },
    loading: {
      type: Boolean,
      default: false
    },
    autoHide: {
      type: Boolean,
      default: true
    },
    triggers: {
      type: Array,
      default: () => ['click']
    },
    allowDisablePositioning: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isMobile: false
    }
  },
  computed: {
    isWidget () {
      return this.$store.getters.layout === 'widget'
    },
    positioningDisabled () {
      return this.allowDisablePositioning && this.isMobile && !this.isWidget
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })

    this.onResize()
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize () {
      this.isMobile = window.innerWidth < 500
    },
    open () {
      this.$refs.dropdown.show()
    },
    close () {
      this.$refs.dropdown.hide()
    }
  }
}
</script>

<style>

.v-popper--theme-dropdown .v-popper__inner {
  background: rgb(var(--bookapp-bg-secondary) / 1) !important;
  border: 1px solid rgb(var(--bookapp-border-primary) / 1) !important;
  border-radius: 8px !important;
}

.v-popper--theme-dropdown .v-popper__wrapper:focus-visible {
  outline: none !important
}

.v-popper--theme-dropdown .v-popper__arrow-inner,
.v-popper--theme-dropdown .v-popper__arrow-outer {
  display: none
}

.v-popper__popper--no-positioning {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.v-popper__popper--no-positioning .v-popper__backdrop {
  display: block;
  background: rgba(0 0 0 / 55%);
}

.v-popper__popper--no-positioning .v-popper__wrapper {
  width: 100%;
  pointer-events: auto;
  transition: transform .15s ease-out;
}

.v-popper__popper--no-positioning.v-popper__popper--hidden .v-popper__wrapper {
  transform: translateY(100%);
}
</style>

<template>
  <b-alert
    v-if="errors && errors.length"
    :title="_title"
    class="mb-4"
    variant="danger"
  >
    <ul class="text-sm list-disc ml-2 pl-2 text-white">
      <li v-for="error in errors" :key="error">
        {{ error }}
      </li>
    </ul>
  </b-alert>
</template>

<script>
export default {
  name: 'BErrors',
  props: {
    errors: {
      type: Array,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  },
  computed: {
    _title () {
      return this.title || this.$t('general.correctErrors')
    }
  }
}
</script>
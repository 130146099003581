import Default from './Default'
import App from './App'
import Bot from './Bot'
import Widget from './Widget'
import Facebook from './Facebook'
import Full from './Full'

export default {
  Default,
  App,
  Bot,
  Widget,
  Facebook,
  Full
}

<template>
  <div>
    <!--<b-form-radio-group :checked="value" :disabled="disabled" :options="[ { text: 'Yes', value: true }, { text: 'No', value: false } ]" @input="change" />-->
    <label class="flex items-center mb-3">
      <input
        v-model="_value"
        type="radio"
        :value="true"
        class="b-radio appearance-none h-5 w-5 border bg-secondary rounded-full checked:bg-action checked:border-action"
      >
      <label class="text-sm font-medium text-secondary ml-2">Yes</label>
    </label>

    <label class="flex items-center">
      <input
        v-model="_value"
        type="radio"
        :value="false"
        class="b-radio appearance-none h-5 w-5 border bg-secondary rounded-full checked:bg-action checked:border-action"
      >
      <label class="text-sm font-medium text-secondary ml-2">No</label>
    </label>
  </div>
</template>

<script>
export default {
  name: 'BYesNo',
  props: {
    value: {
      type: Boolean,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  }
}
</script>

<style>
  .b-radio:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3Csvg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%22-4 -4 8 8%22%3E%3Ccircle r=%222%22 fill=%22%23fff%22/%3E%3C/svg%3E")
  }
</style>

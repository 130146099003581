import Tool, { ToolSetting } from './Tool'

export default class Brush extends Tool {
  config = {
    size: 4,
    colours: [
      'black',
      '#dc2626',
      '#0284c7',
      '#059669'
    ]
  }

  constructor (data, config) {
    super(data, config)

    this.icon = '<svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="-0.75 -0.75 24 24"><defs></defs><title>pencil-2</title><path d="M20.805 1.700625a3.4040624999999998 3.4040624999999998 0 0 0 -4.8346875 0.0328125L2.409375 15.290624999999999 0.703125 21.796875l6.508125 -1.7053125L20.7721875 6.534375a3.40125 3.40125 0 0 0 0.0328125 -4.833749999999999Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="m15.582187499999998 2.1215625 4.801875 4.8" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="m13.62375 4.0790625 4.801875 4.8" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="m2.409375 15.290624999999999 3.3656249999999996 1.43625 1.44 3.36" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path><path d="M16.0246875 6.4790624999999995 5.775 16.7259375" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path></svg>'

    this.state = {
      drawing: false,
      snapshot: null
    }

    if (config) {
      this.config = {
        ...this.config,
        ...config
      }
    }

    this.settings = {
      size: this.config.size || 4,
      colour: new ToolSetting({
        value: this.config.colours[0],
        options: this.config.colours,
        template: `<div class="flex items-center space-x-3">
          <a
            v-for="option in options"
            :key="option"
            :class="[
              \`block h-5 w-5 rounded-full hover:outline outline-offset-1 cursor-pointer\`,
              value === option ? 'outline' : ''
            ]"
            :style="{
              backgroundColor: option,
              outlineColor: option,
              outlineOffset: '2px'
            }"
            @click="set(option)"
          />
        </div>`
      })
    }
  }

  get showSettings () {
    return this.config.colours.length > 1
  }

  pointerDown ({ x, y } = {}) {
    const canvas = this.canvas
    const ctx = canvas.getContext('2d')

    this.state.drawing = true
    this.mouse.x = x
    this.mouse.y = y

    ctx.beginPath()

    ctx.lineWidth = this.settings.size || 4
    ctx.strokeStyle = this.settings.colour.value
    ctx.fillStyle = this.settings.colour.value

    this.state.snapshot = ctx.getImageData(0, 0, canvas.width, canvas.height)

    this.state.drawing = true

    this.context.layers = [
      ...this.context.layers,
      {
        type: 'line',
        color: this.settings.colour.value,
        points: []
      }
    ]
  }

  pointerMove () {
    if (this.state.drawing) {
      const canvas = this.canvas
      const ctx = canvas.getContext('2d')

      ctx.putImageData(this.state.snapshot, 0, 0)

      ctx.globalCompositeOperation = 'source-over'
      ctx.strokeStyle = this.settings.colour.value
      ctx.lineTo(this.cursor.lastX, this.cursor.lastY)
      ctx.stroke()

      const layers = this.context.layers

      layers[layers.length - 1].points.push([
        this.mouse.x,
        this.mouse.y
      ])

      this.context.layers = layers
    }
  }

  pointerUp () {
    this.state.drawing = false
  }

  redraw (layer) {
    const canvas = this.canvas
    const ctx = canvas.getContext('2d')

    ctx.beginPath()

    ctx.lineWidth = this.settings.size || 4
    ctx.strokeStyle = layer.color
    ctx.fillStyle = layer.color

    for (const point of layer.points) {
      const [x, y] = point
      const canvas = this.canvas
      const ctx = canvas.getContext('2d')

      ctx.globalCompositeOperation = 'source-over'
      ctx.strokeStyle = layer.color
      ctx.lineTo(x, y)
      ctx.stroke()
    }
  }

  drawCursor () {
    this.cursor.lastX = this.lerp(this.cursor.lastX, this.mouse.x, 0.5)
    this.cursor.lastY = this.lerp(this.cursor.lastY, this.mouse.y, 0.5)

    const canvas = this.canvas_cursor
    const ctx = canvas.getContext('2d')
    const radius = this.settings.size || 4

    ctx.clearRect(0, 0, canvas.width, canvas.height)
    ctx.beginPath()
    ctx.arc(this.cursor.lastX, this.cursor.lastY, radius / 2, 0, Math.PI * 2, false)
    ctx.fillStyle = this.selectedColor

    ctx.fill()
    ctx.closePath()
  }
}

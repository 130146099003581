<template>
  <div class="relative">
    <slot name="trigger" :click="handleClickToggle">
      <b-button variant="action" :loading="loading" :disabled="disabled || loading" @click.stop="handleClickToggle">
        {{ label }}
      </b-button>
    </slot>

    <div v-if="active" class="fixed inset-0 h-screen w-screen flex z-50">
      <div class="bg-secondary/30 backdrop-blur-sm absolute inset-0" @click="close" />
      <div class="bg-primary rounded border p-3 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-h-[90vh] w-full max-w-[95vw] sm:max-w-sm">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BDialog',
  props: {
    label: {
      type: String,
      default: 'Select'
    },
    loading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      active: false
    }
  },
  methods: {
    open () {
      this.active = true
    },
    close () {
      this.active = false
    },
    toggle () {
      this.active = !this.active
    },
    handleClickToggle () {
      this.toggle()
    },
    handleClickOutside () {
      this.close()
    }
  }
}
</script>

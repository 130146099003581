<template>
  <div v-if="loaded && !cancelled && !consented" class="w-screen fixed z-30 bottom-20 sm:bottom-0 left-0 px-4">
    <div class="mx-auto my-4 max-w-6xl w-full bg-primary shadow-xl rounded p-3 sm:flex items-center justify-between text-secondary">
      <div class="mr-6 text-sm">
        <p class="font-semibold">
          {{ $t('components.cookiePermissions.likeCookies') }}
        </p>
        <p>
          {{ $t('components.cookiePermissions.weUseCookies', {location: $site.location.title}) }}
        </p>
      </div>

      <div class="flex items-center space-x-2  rtl:space-x-reverse mt-6 sm:mt-0">
        <b-button variant="outline" @click="cancel">
          {{ $t('components.cookiePermissions.cancel') }}
        </b-button>

        <b-button variant="action" @click="accept">
          {{ $t('components.cookiePermissions.accept') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    layout: {
      type: String,
      required: false,
      default: 'site'
    }
  },
  data () {
    return {
      loaded: false,
      cancelled: false,
      consented: false
    }
  },
  computed: {
    consentedCookieValid () {
      const cookie = require('cookie')
      const cookies = cookie.parse(document.cookie)
      return (cookies.__bookapp_cookie_consent === 'true')
    }
  },
  mounted () {
    this.loaded = true
    this.consented = this.consentedCookieValid

    if (this.consented) {
      this.accepted()
    }
  },
  methods: {
    accepted () {
      if (this.$gtag) { this.$gtag.optIn() }

      this.$emit('accepted')
    },
    accept () {
      const cookie = require('cookie')
      document.cookie = cookie.serialize('__bookapp_cookie_consent', 'true', { domain: this.$config.domain })

      this.consented = true

      this.accepted()
    },
    cancel () {
      this.cancelled = true

      this.$emit('cancelled')
    }
  }
}
</script>

<!--<style>
.cookie-control-container {
  position:relative;
}

.cookie-control-container .cookie-control {
  position: fixed;
  left: 0;
  width: 100%;
  margin: 0 !important;
  z-index: 999;
  border-radius: 0;
  background: #212327;
  color: #ecf0f1;
  padding:20px;
}

.cookie-control-container.layout-site .cookie-control {
  bottom: 0;
}

.cookie-control-container.layout-bot .cookie-control {
  position: absolute !important;
  bottom: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.cookie-control-container.layout-widget .cookie-control {
  bottom: 0;
}

.cookie-control-close {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>-->

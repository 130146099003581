export const state = () => ({
  pass: null,
  customer: null
})

export const mutations = {
  setDetails(state, { pass }) {
    state.pass = pass
  },
  setCustomer(state, customer) {
    state.customer = customer
  }
}

export const actions = {
  setPassDetails(context, { pass }) {
    context.commit('setDetails', { pass })
  },
  setCustomer(context, customer) {
    context.commit('setCustomer', customer)
  },
  async setFromUrlQuery(context, query) {
    const currentQuery = context.getters.urlQuery

    if (query.pass && currentQuery.pass !== query.pass) {
      const json = Buffer.from(query.pass, 'base64').toString()
      const passJSON = JSON.parse(json)

      let pass = null
      if (passJSON.pass && passJSON.pass.id) {
        if (context.state.pass && context.state.pass.id === passJSON.pass.id) {
          pass = context.state.pass
        } else {
          const passResult = await this.$api.pass.pass(passJSON.pass.id)
          pass = passResult.pass
        }
      }

      context.commit('setDetails', {
        pass
      })
    }

    if (query.customer && currentQuery.customer !== query.customer) {
      const json = Buffer.from(query.customer, 'base64').toString()
      const customer = JSON.parse(json)

      context.commit('setCustomer', customer)
    }
  }
}

export const getters = {
  pass(state) {
    return state.pass
  },
  customer(state) {
    return state.customer
  },
  urlQuery(state) {
    const query = {}

    const pass = {}

    if (state.pass) {
      pass.pass = { id: state.pass.id }
    }

    query.pass = Buffer.from(JSON.stringify(pass)).toString('base64')

    if (state.customer) {
      query.customer = Buffer.from(JSON.stringify(state.customer)).toString('base64')
    }

    return query
  }
}

<template>
  <div>
    <template v-if="list.items">
      <p v-if="list.items.length === 0" class="p-4 text-secondary/60 text-sm text-center">
        {{ list.loading ? `${ $t('general.loading') } ...` : empty }}
      </p>
      <div v-else>
        <slot :items="list.visibleItems" />

        <div class="text-center mt-6">
          <p class="text-xs text-secondary/70 mb-3">{{ $t('pages.customer.index.showingCountOfTotal', {count: list.visibleItems.length, total: list.total}) }}</p>

          <b-button
            v-if="list.hasMore"
            variant="action"
            size="sm"
            :loading="list.loading"
            @click="list.loadMore()"
          >
            {{ $t('general.showMore') }}
          </b-button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BPager',
  props: {
    list: {
      type: Object,
      required: true
    },
    empty: {
      type: String,
      default: 'No items to show...'
    }
  }
}
</script>
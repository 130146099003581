export default ({ store }, inject) => {
  const prefill = {
    valid (field, value) {
      switch (field.type) {
        case 'date': {
          const date = new Date(value)
          if (isNaN(date.getTime())) {
            console.log(`OVATU WIDGET: ${value} is not a valid date for ${field.label}`)
            return null
          }
          return date
        }
        case 'list': {
          if (!field.customField.multipleValues.includes(value)) {
            console.log(`OVATU WIDGET: ${value} is not a valid option for ${field.label}`)
            return null
          }
          return value
        }
        default: {
          return value
        }
      }
    },
    getValue (model, key, field) {
      const data = store.getters.prefill
      const tab = data && data[model] ? data[model] : null

      if (tab && tab[key] !== undefined) {
        return this.valid(field, tab[key])
      }

      return null
    },
    getCustomValue (model, code, field) {
      const data = store.getters.prefill
      const tab = data && data[model] && data[model].custom ? data[model].custom : null

      if (tab && tab[code] !== undefined) {
        return this.valid(field, tab[code])
      }

      return null
    }
  }

  inject('prefill', prefill)
}

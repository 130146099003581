<template>
  <component
    :is="svg"
    :class="baseClass"
    stroke="currentColor"
  />
</template>

<script>
export const icons = {
  placeholder: require('@/assets/images/icons/outline/home.svg?inline'),
  users: require('@/assets/images/icons/outline/user-group.svg?inline'),
  book: require('@/assets/images/icons/outline/book-open.svg?inline'),
  plan: require('@/assets/images/icons/outline/currency-dollar.svg?inline'),
  home: require('@/assets/images/icons/outline/home.svg?inline'),
  user: require('@/assets/images/icons/outline/user.svg?inline'),
  building: require('@/assets/images/icons/outline/building-storefront.svg?inline'),
  heart: require('@/assets/images/icons/outline/heart.svg?inline'),
  question: require('@/assets/images/icons/outline/question-mark-circle.svg?inline'),
  newspaper: require('@/assets/images/icons/outline/newspaper.svg?inline'),
  bell: require('@/assets/images/icons/outline/bell.svg?inline'),
  chevronRight: require('@/assets/images/icons/outline/chevron-right.svg?inline'),
  chevronDoubleRight: require('@/assets/images/icons/outline/chevron-double-right.svg?inline'),
  chevronLeft: require('@/assets/images/icons/outline/chevron-left.svg?inline'),
  chevronDoubleLeft: require('@/assets/images/icons/outline/chevron-double-left.svg?inline'),
  chevronDown: require('@/assets/images/icons/outline/chevron-down.svg?inline'),
  chevronUp: require('@/assets/images/icons/outline/chevron-up.svg?inline'),
  import: require('@/assets/images/icons/outline/arrow-down-on-square.svg?inline'),
  copy: require('@/assets/images/icons/outline/document-duplicate.svg?inline'),
  document: require('@/assets/images/icons/outline/document.svg?inline'),
  documentArrowDown: require('@/assets/images/icons/outline/document-arrow-down.svg?inline'),
  minusCircle: require('@/assets/images/icons/outline/minus-circle.svg?inline'),
  checkCircle: require('@/assets/images/icons/outline/check-circle.svg?inline'),
  exclamationCircle: require('@/assets/images/icons/outline/exclamation-circle.svg?inline'),
  edit: require('@/assets/images/icons/outline/pencil.svg?inline'),
  check: require('@/assets/images/icons/outline/check.svg?inline'),
  search: require('@/assets/images/icons/outline/magnifying-glass.svg?inline'),
  menu: require('@/assets/images/icons/outline/bars-3.svg?inline'),
  close: require('@/assets/images/icons/outline/x-mark.svg?inline'),
  arrowLeft: require('@/assets/images/icons/outline/arrow-left.svg?inline'),
  collapse: require('@/assets/images/icons/outline/chevron-up-down.svg?inline'),
  calendar: require('@/assets/images/icons/outline/calendar.svg?inline'),
  voucher: require('@/assets/images/icons/outline/tag.svg?inline'),
  x: require('@/assets/images/icons/outline/x-mark.svg?inline'),
  timetable: require('@/assets/images/icons/outline/calendar-days.svg?inline'),
  lastMinute: require('@/assets/images/icons/outline/clock.svg?inline'),
  address: require('@/assets/images/icons/outline/map-pin.svg?inline'),
  phone: require('@/assets/images/icons/outline/phone.svg?inline'),
  globe: require('@/assets/images/icons/outline/globe-alt.svg?inline'),
  mapPin: require('@/assets/images/icons/outline/map-pin.svg?inline'),
  email: require('@/assets/images/icons/outline/envelope.svg?inline'),
  clock: require('@/assets/images/icons/outline/clock.svg?inline'),
  appointment: {
    outline: require('@/assets/images/icons/outline/clock.svg?inline'),
    solid: require('@/assets/images/icons/solid/clock.svg?inline')
  },
  giftCard: {
    outline: require('@/assets/images/icons/outline/gift.svg?inline'),
    solid: require('@/assets/images/icons/solid/gift.svg?inline')
  },
  pass: {
    outline: require('@/assets/images/icons/outline/ticket.svg?inline'),
    solid: require('@/assets/images/icons/solid/ticket.svg?inline')
  },
  review: {
    outline: require('@/assets/images/icons/outline/star.svg?inline'),
    solid: require('@/assets/images/icons/solid/star.svg?inline')
  },
  gallery: {
    outline: require('@/assets/images/icons/outline/photo.svg?inline'),
    solid: require('@/assets/images/icons/solid/photo.svg?inline')
  }
}

export default {
  name: 'BIcon',
  props: {
    icon: {
      type: [String, Array],
      default: null
    },
    baseClass: {
      type: String,
      default: 'leading-none 4 w-5'
    },
    solid: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    svg () {
      const icon = icons[this.icon] || icons.placeholder

      if (this.solid && icon?.solid) {
        return icon?.solid
      }

      return icon?.outline || icon
    }
  }
}
</script>

<template>
  <li :class="active ? 'bg-primary' : ''" class="rounded overflow-hidden">
    <a
      class="flex items-center justify-between block p-3 text-secondary opacity-75 font-medium hover:bg-action hover:text-action transition-all text-[var(--bookapp-hero-text-color)] cursor-pointer"
      @click="active = !active"
    >
      <div class="flex items-center space-x-4">
        <icon v-if="item.icon" :name="item.icon" />
        <span class="">{{ item.name || item.icon }}</span>
      </div>

      <b-icon icon="collapse" class="ml-6 h-6 w-6 text-secondary/60 flex-shrink-0" />
    </a>
    
    <div v-if="active" class="border-t">
      <ul>
        <li v-for="child in item.children" :key="`${child.id}-${child.type}`">
          <menu-item :item="child" icon info />
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
import MenuItem from './MenuItem'
import Icon from './Icon'

export default {
  components: {
    MenuItem,
    Icon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      active: false
    }
  }
}
</script>

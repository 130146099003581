<template>
  <div
    class="relative group flex items-center w-full transition duration-100 ease-in-out border rounded focus-within:ring-2 focus-within:ring-blue-500 focus-within:outline-none focus-within:ring-opacity-50"
    :class="classes"
  >
    <label class="flex items-center w-full h-full">
      <slot name="leading" />
      <select
        v-model="_value"
        class="absolute left-0 appearance-none h-full bg-transparent w-full outline-none text-secondary"
        :disabled="disabled"
        :class="classes"
      >
        <option v-for="(option, key) in options" :key="key" :value="option.value" class="bg-primary">{{ option.label || option.text }}</option>
      </select>
    </label>

    <slot name="trailing">
      <b-icon icon="chevronDown" />
    </slot>
  </div>
</template>

<script>
import Input from '@/mixins/input.js'

export default {
  name: 'BSelect',
  mixins: [Input],
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    id: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    _value: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  }
}
</script>

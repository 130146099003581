<template>
  <nuxt-link
    v-if="path && (!isTab ||isEnabled)"
    class="flex items-center space-x-4 rtl:space-x-reverse p-3 hover:bg-action hover:text-action transition-all text-[var(--bookapp-hero-text-color)] cursor-pointer rounded"
    :class="[ isActive ? 'text-primary bg-primary/20' : 'text-secondary opacity-75' ]"
    :to="path"
  >
    <icon :name="item.icon || icons[item.id]" class="flex-shrink-0"/>
    <div>
      <p class="text-sm">{{ name }}</p>
      <p v-if="information" class="text-sm opacity-70">{{ information }}</p>
    </div>
  </nuxt-link>
</template>

<script>
import Icon from './Icon'

export default {
  components: {
    Icon
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      paths: {
        book: "/book-now",
        timetable: "/timetable",
        lastMinute: "/last-minute",
        giftCard: "/gift",
        pass: "/pass",
        gallery: "/gallery",
        review: "/reviews",
        user: "/customer"
      },
      icons: {
        book: "clock",
        timetable: "calendar-days",
        lastMinute: "bell-alert",
        giftCard: "gift",
        pass: "ticket",
        gallery: "photo",
        review: "star",
        user: "user"
      }
    }
  },
  computed: {
    name () {
      return this.item?.name || this.$site.tabs.getName(this.item?.id, this.item?.id)
    },
    path () {
      if (this.item?.type === 'custom') {
        return `/page/${this.item?.slug}`
      }

      return this.paths[this.item?.id]
    },
    information () {
      return this.item.information
    },
    isActive () {
      return this.$route.path.includes(this.path)
    },
    isEnabled () {
      return !!this.$site.tabs[this.item?.id]
    },
    isTab () {
      return this.item?.type === 'tab'
    }
  }
}
</script>
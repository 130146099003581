<template>
  <footer>
    <b-container v-if="!isHome && $site.tabs.contact" class="border-t border-primary mt-16">
      <div class="pt-16 mx-auto pb-6">
        <div class="grid grid-cols-1 gap-8 sm:grid-cols-3">
          <div v-if="$site.tabs.contact.address.showMap">
            <component
              :is="$site.tabs.contact.address.showMap && $site.tabs.contact.address.displayAddress ? 'a' : 'div'"
              v-if="$site.tabs.contact"
              class="block rounded min-h-[250px] bg-cover bg-center"
              :style="{ backgroundImage: 'url(\'' + mapUrl + '\')' }"
              target="_blank"
              :href="$format.maps($site.tabs.contact.address.displayAddress)"
            />
          </div>
          <div>
            <template v-if="$site.tabs.contact">
              <h4 class="text-xl text-primary font-bold mb-2">
                {{ $site.tabs.contact.contact.business }}
              </h4>

              <a v-if="$site.tabs.contact.contact.email" class="block text-secondary text-sm opacity-70" target="_blank" :href="'mailto:' + $site.tabs.contact.contact.email">{{ $site.tabs.contact.contact.email }}</a>
              <a v-if="$site.tabs.contact.contact.phone" class="block text-secondary text-sm opacity-70" :href="'tel:' + stripPhoneNumber($site.tabs.contact.contact.phone)">{{ $site.tabs.contact.contact.phone }}</a>
              <a v-if="$site.tabs.contact.contact.website" class="block text-secondary text-sm opacity-70" target="_blank" :href="$format.link($site.tabs.contact.contact.website)">{{ $site.tabs.contact.contact.website }}</a>
              <a v-if="$site.tabs.contact.address.displayAddress" class="block text-secondary text-sm opacity-70" target="_blank" :href="$format.maps($site.tabs.contact.address.displayAddress)">{{ $site.tabs.contact.address.displayAddress }}</a>
            </template>

            <div class="mt-6 opacity-70">
              <social-links :social-links="$site.settings.socialLinks" />
            </div>
          </div>
          <div v-if="$site.tabs.contact.hours.enabled">
            <template v-if="$site.tabs.contact">
              <BusinessHours :show-icon="false" expanded />
            </template>
          </div>
        </div>
      </div>
    </b-container>

    <b-container class="border-t border-primary mt-4 pt-6 pb-32 sm:pb-6 flex items-center justify-between">
      <a class="text-secondary opacity-70 text-sm" target="_blank" :href="`https://www.ovatu.com?source=bookapp-site&business=${$site.location.slug}`">
        Made awesome by Ovatu
      </a>

      <div>
        <language-switcher />
      </div>
    </b-container>
  </footer>
</template>

<script>
import Utils from '@/utils'
import LanguageSwitcher from '@/components/layouts/elements/LanguageSwitcher.vue'
import SocialLinks from '@/components/layouts/elements/SocialLinks.vue'
import BusinessHours from '@/components/layouts/elements/BusinessHours.vue'

export default {
  components: {
    LanguageSwitcher,
    SocialLinks,
    BusinessHours
  },
  computed: {
    businessName () {
      if (this.$site.tabs.contact && this.$site.tabs.contact.business) {
        return this.$site.tabs.contact.business
      } else {
        return this.$site.location.title
      }
    },
    mapUrl () {
      return this.$site.location.mapUrl({ size: '450x300', zoom: 15 })
    },
    isHome () {
      return this.$route.path === '/'
    }
  },
  methods: {
    stripPhoneNumber (text) {
      return Utils.text.stripPhoneNumber(text)
    }
  }
}
</script>

const controller = {
  process (data) {
    if (this[data.type]) {
      return this[data.type](data)
    }

    return `<p class='text-red-500'>${ data.type }</p>`
  },

  content (content = []) {
    let html = ''

    content.forEach((item) => {
      html += this.process(item)
    })

    return html
  },

  doc ({ content }) {
    let html = '<div class="doc">'

    html += this.content(content)

    html += '</div>'

    return html
  },

  text ({ marks = [], text }) {
    let html = text

    for (const mark of marks) {
      if (this.marks[mark.type]) {
        html = this.marks[mark.type](html, mark)
      }
    }

    return html
  },

  hardBreak () {
    return '<br />'
  },

  marks: {
    bold (content) {
      return `<strong>${content}</strong>`
    },
    italic (content) {
      return `<em>${content}</em>`
    },
    strike (content) {
      return `<s>${content}</s>`
    },
    link (content, mark) {
      if (mark.attrs.element === 'link') {
        if (mark.attrs.type === 'internal') {
          return `<nuxt-link class="text-primary underline" to="${mark.attrs.href}" target="${mark.attrs.target}">${content}</nuxt-link>`
        }

        if (mark.attrs.type === 'external') {
          return `<a class="text-primary underline" href="${mark.attrs.href}" target="${mark.attrs.target}">${content}</a>`
        }
      }

      if (mark.attrs.element === 'button') {
        if (mark.attrs.type === 'internal') {
          return `<b-button variant="action" to="${mark.attrs.href}">${content}</b-button>`
        }

        if (mark.attrs.type === 'external') {
          return `<b-button variant="action" href="${mark.attrs.href}">${content}</b-button>`
        }
      }
    }
  },

  heading ({ attrs, content }) {
    let html = `<h${attrs.level} class="text-${attrs.textAlign}">`
    html += this.content(content)
    html += `</h${attrs.level}>`

    return html
  },

  paragraph ({ attrs, content }) {
    let html = `<p class="text-${attrs.textAlign}">`
    html += this.content(content)
    html += `</p>`

    return html
  },

  image ({ attrs }) {
    let html = `<img src="${attrs.src}" class="rounded my-6 mx-auto" />`

    return html
  },

  columns ({ content }) {
    const cols = content
      .reduce((acc, item) => {
        if (item.type === 'column') {
          return acc + item.attrs.span
        }

        return acc
      }, 0)

    let html = `<div class="grid grid-cols-1 sm:gap-4 sm:grid-cols-${cols}">`

    html += this.content(content)
    html += '</div>'
    return html
  },

  column ({ attrs, content }) {
    let html = `<div class="col-span-${attrs.span} break-words">`
    html += this.content(content)
    html += '</div>'

    return html
  },

  bulletList ({ content }) {
    let html = '<ul class="list-disc list-inside">'
    html += this.content(content)
    html += '</ul>'

    return html
  },

  orderedList ({ content }) {
    let html = '<ol class="list-decimal list-inside">'
    html += this.content(content)
    html += '</ol>'

    return html
  },

  listItem ({ content }) {
    let html = '<li>'
    html += this.content(content)
    html += '</li>'

    return html
  },

  table ({ content }) {
    let html = '<table class="w-full text-left text-sm text-secondary mb-4 table-fixed">'
    html += '<colgroup>'
    html += content[0].content.map((item) => {
      return `<col width="${item.attrs.colwidth}" />`
    }).join('')
    html += '</colgroup>'
    html += '<tbody>' + this.content(content) + '</tbody>'
    html += '</table>'

    return html
  },

  tableRow ({ content }) {
    let html = '<tr>'
    html += this.content(content)
    html += '</tr>'

    return html
  },

  tableHeader ({ attrs, content }) {
    let html = `<th class="text-xs text-primary uppercase border-b p-3 bg-action/10"  colspan="${attrs.colspan}" rowspan="${attrs.rowspan}" colwidth="${attrs.colwidth}">`
    html += this.content(content)
    html += '</th>'

    return html
  },

  tableCell ({ attrs, content }) {
    let html = `<td class="border-b p-3 break-words" colspan="${attrs.colspan}" rowspan="${attrs.rowspan}" colwidth="${attrs.colwidth}">`
    html += this.content(content)
    html += '</td>'

    return html
  },

  hero ({ attrs }) {
    return `<hero-block title="${attrs.title}" info="${attrs.info}" image="${attrs.image}" />`
  },

  services ({ attrs }) {
    return `<services-block title="${attrs.title}" info="${attrs.info}" services="${attrs.services}" images="${attrs.images}" />`
  },

  employees ({ attrs }) {
    return `<employees-block title="${attrs.title}" info="${attrs.info}" employees="${attrs.employees}" images="${attrs.images}" />`
  }
}

export default (context, inject) => {
  const render = {
    object: (data) => {
      return controller.process(data)
    },
  }

  inject('render', render)
}

export default function (req, res, next) {
  const isHTTPs = require('is-https')

  const domain = req.headers.host
  const sitemapUrl = (isHTTPs(req) ? 'https' : 'http') + '://' + domain + '/sitemap.xml'

  const txt =
`User-agent: *
Disallow: /bot

Sitemap: ${sitemapUrl}`

  res.end(txt)
}

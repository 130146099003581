<template>
  <div :class="styles('wrapper')">
    <label v-if="label" class="block text-sm font-semibold leading-6 mb-2" :class="styles('label')">
      <slot name="label">
        {{ label }}
      </slot>

      <b-tooltip v-if="required" text="Required field" class="inline-block">
        <span class="text-sm action-text">
          *
        </span>
      </b-tooltip>
    </label>

    <div v-if="info || $slots.info" class="text-sm mb-3 -mt-2 text-secondary/70">
      <slot name="info">
        {{ info }}
      </slot>
    </div>

    <div :class="styles('body')">
      <slot />
    </div>

    <div v-if="feedback" :class="styles('feedback')" class="text-sm mt-2">
      {{ feedback }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'BInputGroup',
  props: {
    variant: {
      type: String,
      default: 'base'
    },
    label: {
      type: String,
      default: null
    },
    feedback: {
      type: String,
      default: null
    },
    info: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      variants: {
        base: {
          label: 'text-secondary',
          feedback: 'text-gray-400'
        },
        danger: {
          label: 'text-red-500',
          feedback: 'text-red-500'
        },
        success: {
          label: 'text-green-500',
          feedback: 'text-green-500'
        }
      }
    }
  },
  methods: {
    styles (type) {
      const variant = this.variant
      const variants = this.variants

      return variants[variant]?.[type] || variants.base?.[type] || ''
    }
  }
}
</script>

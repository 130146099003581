<template>
  <tippy
    :class="baseClass"
    :interactive="interactive"
    :placement="placement"
    :trigger="trigger"
  >
    <template #trigger>
      <slot />
    </template>

    <slot name="content">
      <div class="text-sm" v-html="text" />
    </slot>
  </tippy>
</template>

<script>
import Vue from 'vue'
import VueTippy, { TippyComponent } from 'vue-tippy'

Vue.use(VueTippy, {
  zIndex: 85000
})

Vue.component('Tippy', TippyComponent)

export default {
  name: 'BTooltip',
  props: {
    text: {
      type: String,
      default: null
    },
    trigger: {
      type: String,
      default: 'mouseover, mouseleave, mouseenter'
    },
    placement: {
      type: String,
      default: 'top'
    },
    interactive: {
      type: Boolean,
      default: false
    },
    baseClass: {
      type: String,
      default: 'b-tooltip'
    }
  }
}
</script>


<!--<template>
  <div class="relative">
    <span @mouseover="handleMouseOver" @mouseleave="handleMouseLeave" class="inline-block px-1 cusor-pointer">
      <slot />
    </span>

    <transition name="fade">
      <div v-if="active" class="absolute left-1/2 -translate-x-1/2 top-0 z-50 mb-1 w-auto min-w-56 origin-top-right rounded bg-primary -translate-y-full shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <slot name="content">
          <p class="text-xs text-secondary p-2 whitespace-nowrap">
            {{ text }}
          </p>
        </slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'BTooltip',
  props: {
    text: {
      type: String,
      default: 'Select'
    }
  },
  data () {
    return {
      active: false
    }
  },
  methods: {
    show () {
      this.active = true
    },
    hide () {
      this.active = false
    },
    handleMouseOver () {
      this.show()
    },
    handleMouseLeave () {
      this.hide()
    }
  }
}
</script>-->

<template>
  <div class="sm:hidden flex items-center justify-between bg-action p-4 text-action z-20 w-full">
    <div>
      <nuxt-link to="/">
        <b-icon icon="home" />
      </nuxt-link>
    </div>

    <div>
      <h3 class="font-semibold text-center">
        {{ businessName }}
      </h3>
    </div>

    <div>
      <button class="bg-transparent" @click="toggleMenu">
        <b-icon icon="menu" />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    businessName () {
      if (this.$site.tabs.contact && this.$site.tabs.contact.business) {
        return this.$site.tabs.contact.business
      } else {
        return this.$site.location.title
      }
    },
    active: {
      get () {
        return this.$store.getters.menu
      },
      set (val) {
        this.$store.commit('setMenu', val)
      }
    }
  },
  methods: {
    toggleMenu () {
      this.active = !this.active
    }
  }
}
</script>

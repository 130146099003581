<template>
  <div class="bg-secondary flex flex-col">
    <div id="main" class="relative flex-grow flex-shrink sm:pt-8">
      <slot />
    </div>

    <client-only>
      <Cookie v-if="$site.settings.showCookieConsent" layout="site" />
    </client-only>
  </div>
</template>

<script>
import Cookie from './elements/Cookie'

export default {
  components: {
    Cookie
  }
}
</script>

import Vue from 'vue'
import components from './lib'

// get options passed from module.js
const options = JSON.parse(`{"namespace":"Ovatu"}`)

// loop through components and register them
for (const name in components) {
  Vue.component(`${options.namespace}${name}`, {
    // extend the original component
    extends: components[name],
    // add a _customCounterOptions prop to gain access to the options in the component
    props: {
      _customCounterOptions: {
        type: Object,
        default: () => ({ ...options })
      }
    }
  })
}
export default ({ app, store, route, redirect }) => {
  const enabled = app.$site.tabs
  const tabs = route.meta[0].tabs
  const onlineBookingEnabled = app.$site.onlineBookingEnabled
  const layout = app.$site.layout

  if (tabs) {
    for (let i = 0; i < tabs.length; i++) {
      const tab = tabs[i]

      if (tab === 'online' && onlineBookingEnabled) {
        return
      }

      if (tab in enabled) {
        return
      }
    }

    if (layout === 'widget' || layout === 'facebook') {
      return redirect('/widget/disabled')
    }

    if (layout === 'bot') {
      return redirect('/bot')
    }

    return redirect('/')
  }
}

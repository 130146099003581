<template>
  <v-menu :triggers="['hover']" :autoHide="false">
    <a class="block py-2 px-3 rounded font-medium hover:bg-action hover:text-action transition-all text-[var(--bookapp-hero-text-color)] cursor-pointer">
      {{ item.name || item.icon }}
    </a>

    <template #popper>
      <div class="p-2 space-y-2 text-sm">
        <navigation-item
          v-for="child in item.children"
          :key="`${child.id}-${child.type}`"
          :item="child"
          icon
          info
          class="text-secondary text-left"
        />
      </div>
    </template>
  </v-menu>
</template>

<script>
import NavigationItem from './NavigationItem'

export default {
  components: {
    NavigationItem
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
